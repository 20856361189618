import APIClass from '../../services/API';
import {
  REQUEST_CATEGORIES,
  RECEIVE_CATEGORIES_SUCCESS,
  RECEIVE_CATEGORIES_ERROR,
  RECEIVE_TOP_PRODUCTS_CATEGORY_ERROR,
  REQUEST_TOP_PRODUCTS_CATEGORY,
  RECEIVE_TOP_PRODUCTS_CATEGORY_SUCCESS,
  REQUEST_CATEGORY_PRODUCTS,
  RECEIVE_CATEGORY_PRODUCTS_SUCCESS,
  RECEIVE_CATEGORY_PRODUCTS_ERROR,
  SET_CATEGORY_FILTER,
  REQUEST_CATEGORY_BRANDS,
  RECEIVE_CATEGORY_BRANDS_SUCCESS,
  RECEIVE_CATEGORY_BRANDS_ERROR,
  SET_CATEGORY_FILTERS,
  RECEIVE_MORE_CATEGORY_PRODUCTS_SUCCESS,
  REQUEST_SITEMAP_ARTICLES,
  RECEIVE_SITEMAP_ARTICLES_ERROR,
  RECEIVE_SITEMAP_ARTICLES_SUCCESS,
  SET_CATEGORY_ARTICLES_FILTERS,
  REQUEST_SITEMAP_DATA,
  RECEIVE_SITEMAP_DATA_SUCCESS,
  RECEIVE_SITEMAP_DATA_ERROR,
} from './constants';
import { prop } from '../../utilities';
import { langSelector } from '../App/selectors';

export const setCategoryFilter = (sort, sortDir) => ({
  type: SET_CATEGORY_FILTER,
  payload: { sort, sortDir },
});

const requestCategoryProducts = () => ({
  type: REQUEST_CATEGORY_PRODUCTS,
});

const receiveCategoryProductsSuccess = products => ({
  type: RECEIVE_CATEGORY_PRODUCTS_SUCCESS,
  payload: {
    products,
  },
});

const receiveCategoryProductsError = error => ({
  type: RECEIVE_CATEGORY_PRODUCTS_ERROR,
  payload: {
    error,
  },
});

const receiveMoreCategoryProductsSuccess = products => ({
  type: RECEIVE_MORE_CATEGORY_PRODUCTS_SUCCESS,
  payload: {
    products,
  },
});

export const setCategoryInitialStateFromUrl = location => {
  return async dispatch => {
    const { query } = location;
    let offset = 0;
    let sort = '';
    let sortDir = '';
    if (query.offset) {
      if (isNaN(query.offset)) {
        offset = 0;
      } else {
        offset = Number(query.offset);
      }
    }
    if (query.sort) {
      sort = prop(query, 'sort', '');
    }
    if (query.sortDir) {
      sortDir = prop(query, 'sortDir', '');
    }
    dispatch(setCategoryFilters({ offset, pathname: location.pathname }));
    dispatch(setCategoryFilter(sort, sortDir));
  };
};

export const getNextCategoryUrl = (category, newOffset = 0, sort, sortDir) => {
  return {
    pathname: category.category.data.url,
    query: {
      offset:
        newOffset.toString() || category.category.products.products.offset,
      sort: sort || sort === '' ? sort : category.category.products.sort,
      sortDir:
        sortDir || sortDir === ''
          ? sortDir
          : category.category.products.sortDir,
    },
    state: {
      reload: false,
    },
  };
};

export const setCategoryFilters = filters => ({
  type: SET_CATEGORY_FILTERS,
  payload: {
    filters,
  },
});

export const loadCategoryProducts = categoryId => {
  return async (dispatch, getState, API) => {
    try {
      dispatch(requestCategoryProducts());

      const lang: string = langSelector(getState());
      const products = await APIClass.searchProducts(
        {
          categoryId,
          offset: prop(
            getState(),
            'category.category.products.products.offset',
            0,
          ),
          limit: 10,
          sort: prop(getState(), 'category.category.products.sort'),
          sortDir: prop(getState(), 'category.category.products.sortDir'),
        },
        { xAcceptLanguage: lang },
      );
      dispatch(receiveCategoryProductsSuccess(products));
    } catch (e) {
      dispatch(receiveCategoryProductsError(e));
    }
  };
};

export const loadMoreCategoryProducts = categoryId => {
  return async (dispatch, getState, API) => {
    try {
      dispatch(requestCategoryProducts());
      const products = await APIClass.searchProducts({
        categoryId,
        offset:
          prop(getState(), 'category.category.products.products.offset', 0) +
          10,
        limit: 10,
        sort: prop(getState(), 'category.category.products.sort'),
        sortDir: prop(getState(), 'category.category.products.sortDir'),
      });
      dispatch(receiveMoreCategoryProductsSuccess(products));
    } catch (e) {
      dispatch(receiveCategoryProductsError(e));
    }
  };
};

const requestTopProductsCategory = () => ({
  type: REQUEST_TOP_PRODUCTS_CATEGORY,
});

const receiveTopProductsCategorySuccess = topProducts => ({
  type: RECEIVE_TOP_PRODUCTS_CATEGORY_SUCCESS,
  payload: {
    topProducts,
  },
});

const receiveTopProductsCategoryError = error => ({
  type: RECEIVE_TOP_PRODUCTS_CATEGORY_ERROR,
  payload: {
    error,
  },
});

export const loadTopProductsCategory = categoryId => {
  return async (dispatch, getState, API) => {
    try {
      dispatch(requestTopProductsCategory());
      const topProducts = await APIClass.searchProducts({
        categoryId,
        isTop: 1,
      });
      dispatch(receiveTopProductsCategorySuccess(topProducts));
    } catch (e) {
      dispatch(receiveTopProductsCategoryError(e));
    }
  };
};

const requestCategory = () => ({
  type: REQUEST_CATEGORIES,
});

const receiveCategorySuccess = category => ({
  type: RECEIVE_CATEGORIES_SUCCESS,
  payload: {
    category,
  },
});

const receiveCategoryError = error => ({
  type: RECEIVE_CATEGORIES_ERROR,
  payload: {
    error,
  },
});

export const loadCategory = categoryId => {
  return async (dispatch, getState, API) => {
    try {
      dispatch(requestCategory());
      const category = await APIClass.loadCategory(categoryId);
      dispatch(receiveCategorySuccess(category));
    } catch (e) {
      dispatch(receiveCategoryError(e));
    }
  };
};

const requestCategoryBrands = () => ({
  type: REQUEST_CATEGORY_BRANDS,
});

const receiveCaegoryBrandsSuccess = category => ({
  type: RECEIVE_CATEGORY_BRANDS_SUCCESS,
  payload: {
    category,
  },
});

const receiveCategoryBrandsError = error => ({
  type: RECEIVE_CATEGORY_BRANDS_ERROR,
  payload: {
    error,
  },
});

export const loadCategoryBrands = categoryId => {
  return async (dispatch, getState, API) => {
    try {
      dispatch(requestCategoryBrands());
      const brands = await APIClass.loadBrands(categoryId);
      dispatch(receiveCaegoryBrandsSuccess(brands));
    } catch (e) {
      dispatch(receiveCategoryBrandsError(e));
    }
  };
};

const requestSitemapArticles = () => ({
  type: REQUEST_SITEMAP_ARTICLES,
});

const receiveSitemapArticlesSuccess = articles => ({
  type: RECEIVE_SITEMAP_ARTICLES_SUCCESS,
  payload: {
    articles,
  },
});

const receiveSitemapArticlesError = error => ({
  type: RECEIVE_SITEMAP_ARTICLES_ERROR,
  payload: {
    error,
  },
});

export const loadSitemapArticles = (sitemapId, offset = 0) => {
  return async (dispatch, getState, API) => {
    try {
      dispatch(requestSitemapArticles());
      dispatch(setCategoryArticlesFilters({ offset }));
      const lang = langSelector(getState());
      const articles = await APIClass.loadArticles(
        {
          sitemapId,
          offset,
          limit: 10,
          sfForm: 'Article',
          onlyActual: '1',
          isPublished: '1',
          tagExcludeId: 1518,
          sort: 'created_date',
          replaceCurrentLangTags: '1',
        },
        { xAcceptLanguage: lang },
      );
      dispatch(
        receiveSitemapArticlesSuccess(articles || { articles: [], total: 0 }),
      );
    } catch (e) {
      dispatch(receiveSitemapArticlesError(e));
    }
  };
};

const requestSitemapData = () => ({
  type: REQUEST_SITEMAP_DATA,
});

const receiveSitemapDataSuccess = data => ({
  type: RECEIVE_SITEMAP_DATA_SUCCESS,
  payload: {
    data,
  },
});

const receiveSitemapDataError = error => ({
  type: RECEIVE_SITEMAP_DATA_ERROR,
  payload: {
    error,
  },
});

export const loadSitemapData = sitemapId => {
  return async (dispatch, getState, API) => {
    try {
      dispatch(requestSitemapData());
      const data = await APIClass.loadSitemap(sitemapId);
      dispatch(receiveSitemapDataSuccess(data));
    } catch (e) {
      dispatch(receiveSitemapDataError(e));
    }
  };
};

export const setCategoryArticlesFilters = filters => ({
  type: SET_CATEGORY_ARTICLES_FILTERS,
  payload: {
    filters,
  },
});
