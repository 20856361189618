import * as React from 'react';
import { connect } from 'react-redux';
import ArticleTags from '../../components/Article/ArticleTags';
import styled from 'styled-components';
import { default as API, ThenArg } from '../../services/API';
import { langSelector, titleSelector } from '../Category/selectors';
import MetaTags from '../../components/MetaTags/MetaTags';
import {
  getProductMetaTags,
  getProductMetaTagsFromWebcontent,
} from '../../utilities/metatags';
import { withRouter, WithRouterProps } from 'react-router';
import ProductTabber from '../../components/Product/ProductTabber';
import { Fragment } from 'react';
import { FlexCol, FlexRow, prop } from 'eshop-defaults/lib';
import ArticleList from '../../components/Article/ArticleList';
import { push } from 'react-router-redux';
import SliderComponent from '../../components/_helpers/Slider/Slider';
import ImageGallery from '../../components/Article/ImageGallery';
import Modal from '../../components/_helpers/Default/Modal';
import ShareWrapper from '../../components/_helpers/Default/ShareWrapper';
import { loadProductDetail, loadProductDetailArticles } from './actions';
import { connectSsr } from 'ssr-service';
import {
  productDataSelector,
  productDetailIsFetchingSelector,
  productDetailArticlesTotalSelector,
  productDetailArticlesOffsetSelector,
  productDetailArticlesIsFetchingSelector,
  productDetailArticlesSelector,
} from './selectors';
import { getImagePath } from '../../utilities/product';
import { rem } from 'polished';
import { Loader } from '../../components/_helpers/Loader/Loader';
import { NoResultsWrapper, NoResults } from '../../components/Ebook/EbookList';
import { __ } from 'react-i18n';
import { ProductMicrodata } from '@bart.sk-ecommerce/react-microdata';
import { stripHtmlTags } from '../../utilities';
import BrandsPage from '../BrandsPage/BrandsPage';

const Wrapper = styled(FlexRow)`
  max-width: 1440px;
  margin: 50px auto;
  width: 100%;

  @media (max-width: 1440px) {
    max-width: 90%;
    margin: 0;
  }

  @media (max-width: 1340px) {
    width: 90%;
    /* flex-flow: column; */
  }

  @media (max-width: 500px) {
    max-width: 100%;
    width: 100%;
    margin: 30px 0;
  }
`;

const ProductWrapper = styled.div`
  width: calc(50% + 50px);
  margin-right: 50px;
  min-width: 680px;

  @media (max-width: 1440px) {
    min-width: initial;
  }

  @media (max-width: 1340px) {
    width: 100%;
  }
  @media (max-width: 900px) {
    min-width: 0;
  }
`;

const ProductInfo = styled.div`
  @media (max-width: 1340px) {
    width: 75vw;
    margin: auto;
  }
  @media (max-width: 900px) {
    width: 90vw;
    margin: 0;
  }
  @media (max-width: 500px) {
    width: 90%;
    margin: auto;
  }
`;

const Gallery = styled.div`
  position: relative;
  margin: 30px auto;
  display: flex;
  width: fit-content;
`;

const More = styled(FlexCol)`
  width: 50%;

  @media (max-width: 1340px) {
    visibility: hidden;
    position: absolute;
  }
`;

const ProductName = styled.h2`
  text-align: left;
  font: 36px/48px Barlow;
  font-weight: 500;
  letter-spacing: 0;
  color: #414141;
  opacity: 1;
  @media (max-width: 500px) {
    font: 20px/28px Barlow;
    font-weight: 500;
  }
`;

const MainPhoto = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 522px;
  height: 417px;
  border: 1px solid #ddd;
  padding: 30px;
  margin-right: 25px;
  cursor: pointer;

  & img {
    max-height: 350px;
  }
`;
const ThumbWrapper = styled.div`
  max-width: 522px;
  display: flex;
  flex-direction: column;
  /* justify-content: space-between; */
`;

const OpenModalButton = styled.button`
  position: absolute;
  border: none;
  background-color: transparent;
  top: 8px;
  left: 8px;
  padding: 0;
  cursor: pointer;
  & img {
    width: 24px;
  }
`;

const Thumb = styled.div`
  color: #414141;
  font: 16px/24px Barlow;
  font-weight: 500;

  width: 127px;
  height: 127px;
  border: 1px solid #ddd;
  padding: 15px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;

  margin-bottom: ${rem(18)};
  & img {
    max-height: 100px;
  }
`;

const ProductItemWrapper = styled.div`
  margin: 0 auto;
  width: 100%;
  padding-left: ${rem(32)};
  padding-right: ${rem(32)};
  @media (max-width: 600px) {
    padding-left: ${rem(20)};
    padding-right: ${rem(20)};
  }
`;

export interface ProductProps {
  product: any; // ThenArg<typeof API.loadProduct>;
  parentProps: {};
  location: any;
  dispatch: any;
  isError: boolean;
  lang: string;
  author: ThenArg<typeof API.loadAuthorDetail>;
  isFetching: boolean;
  offset: number;
  total: number;
  articles: any;
  articlesIsFetching: boolean;
  params: {
    product_id: string;
  };
}

export interface State {
  mainImage: string;
  pid: number;
  width: number;
  openModal: boolean;
  currentPhoto: number;
  photogallery: any;
}

class ProductItem extends React.Component<
  ProductProps & WithRouterProps,
  State
> {
  public static async getInitialProps(
    { params, location, dispatch },
    parentProps,
  ) {
    const { product_id } = params;
    const {
      query: { offset },
    } = location;
    try {
      await dispatch(loadProductDetail(product_id));
      // await dispatch(loadProductDetailArticles(product_id, offset));

      return;
    } catch (exp) {
      return {
        isError: true,
      };
    }
  }
  constructor(props) {
    super(props);
    this.state = {
      mainImage: '',
      pid: -1,
      width: 1440,
      openModal: false,
      currentPhoto: 0,
      photogallery: [],
    };
  }

  public handleOffsetChange = async (newOffset: number) => {
    this.props.dispatch(
      push({
        pathname: this.props.location.pathname,
        search: `?offset=${newOffset}`,
      }),
    );
  };

  public componentWillMount(): void {
    this.OnResize();
  }

  public componentDidMount() {
    this.setState({ width: window.innerWidth });
    window.addEventListener('resize', this.OnResize);
  }
  public componentWillUnmount() {
    window.removeEventListener('resize', this.OnResize);
  }

  public renderPopis(body) {
    if (typeof body === 'string') {
      return (
        <div key={__('Popis')}>
          <span dangerouslySetInnerHTML={{ __html: body }} />
        </div>
      );
    }
    return null;
  }
  public renderSpecs(specs) {
    if (typeof specs === 'string') {
      return (
        <div key={__('Špecifikácia')}>
          <span dangerouslySetInnerHTML={{ __html: specs }} />
        </div>
      );
    }
    return null;
  }
  public renderSimilar(lang, authorArticles, isFetching) {
    if (this.state.width <= 1340) {
      return (
        <Fragment key={__('Súvisiace články')}>
          <div>
            <ArticleList
              lang={lang}
              articles={authorArticles}
              isFetching={isFetching}
            />
          </div>
        </Fragment>
      );
    }
    return null;
  }

  public photosMoreAvailableText(num) {
    num = num - 3;
    if (num < 5) {
      return `${num} fotky`;
    }
    return `${num} fotiek`;
  }

  public renderGallery(imgs) {
    if (imgs.length > 0) {
      return (
        <Gallery>
          <OpenModalButton onClick={this.openModal.bind(this, 0)}>
            <img
              src="/images/blog_assets/baseline-fullscreen-24px.svg"
              alt="open modal icon"
            />
          </OpenModalButton>
          <MainPhoto onClick={this.openModal.bind(this, 0)}>
            <img
              src={getImagePath(imgs[0], {
                width: 450,
                height: 0,
              })}
              alt={imgs[0]}
            />
          </MainPhoto>

          <ThumbWrapper>
            {imgs.slice(1, 3).map((img, i) => {
              if (img) {
                return (
                  <Thumb onClick={this.openModal.bind(this, i + 1)} key={img}>
                    <img
                      src={getImagePath(img, {
                        width: 450,
                        height: 0,
                      })}
                      alt={img}
                    />
                  </Thumb>
                );
              }
              return null;
            })}

            {imgs.length > 4 ? (
              <Thumb onClick={this.openModal.bind(this, 3)}>
                + {this.photosMoreAvailableText(imgs.length)}
              </Thumb>
            ) : imgs.length === 4 ? (
              <Thumb onClick={this.openModal.bind(this, 3)}>
                <img
                  src={getImagePath(imgs[3], {
                    width: 450,
                    height: 0,
                  })}
                  alt={imgs[3]}
                />
              </Thumb>
            ) : null}
          </ThumbWrapper>
        </Gallery>
      );
    }
    return null;
  }

  public openModal = index => {
    const photogallery = prop(this.props.product, 'photogallery', []);
    const fullscreenImages = photogallery.map(doc => {
      const img = getImagePath(doc, {
        width: 800,
        height: 600,
      });
      const thumbImg = getImagePath(doc, {
        width: 87,
        height: 104,
      });
      return {
        original: img,
        thumbnail: thumbImg,
        name: this.props.product.name || '',
      };
    });
    this.setState({
      openModal: true,
      currentPhoto: index,
      photogallery: fullscreenImages,
    });
  };

  public closeModal = () => {
    this.setState({ openModal: false });
  };

  public setCurrentImage = i => {
    this.setState({ currentPhoto: i });
  };

  public render() {
    const {
      product,
      isFetching,
      lang,
      articles,
      articlesIsFetching,
    } = this.props;
    const { photogallery: fullScreenImages, currentPhoto } = this.state;
    const name = prop(product, 'name', '');
    const tags = prop(product, 'tags', []);
    const photogallery = prop(product, 'photogallery', []);
    console.log({ product, photogallery });
    const json = prop(product, 'json_content', {});
    const body = prop(json, 'body', '');
    const annotation = prop(json, 'annotation');
    const shortDesc = prop(json, 'short_description', '');
    const desc =
      typeof annotation === 'object'
        ? shortDesc
        : annotation === ''
        ? shortDesc
        : annotation;

    if (!isFetching && (!product || Object.keys(product).length === 0)) {
      return (
        <NoResultsWrapper>
          <NoResults>{__('Produkt sa nenašiel')}</NoResults>
        </NoResultsWrapper>
      );
    }
    return (
      <ProductItemWrapper className="container container--wide">
        {isFetching ? (
          <Loader />
        ) : (
          <Wrapper>
            <MetaTags
              tags={getProductMetaTagsFromWebcontent(product)}
              canonicalUrl={product.canonical_url}
            />
            <ProductMicrodata
              image={
                photogallery[0]
                  ? getImagePath(photogallery[0].document_path, {
                      width: 450,
                      height: 0,
                    })
                  : ''
              }
              name={name}
              description={stripHtmlTags(desc)}
              sku={product.id}
              brand={name.split(' ') ? name.split(' ')[0] : ''}
            />
            <ProductWrapper>
              <ProductInfo>
                <ProductName>{name}</ProductName>
                <ArticleTags tags={tags} />
                {this.state.openModal && (
                  <Modal size="big" showX={false} closeModal={this.closeModal}>
                    <ImageGallery
                      closeModal={this.closeModal}
                      setCurrentImage={this.setCurrentImage}
                      items={fullScreenImages}
                      currentIndex={currentPhoto}
                    />
                  </Modal>
                )}
                {this.state.width > 900 ? (
                  this.renderGallery(photogallery)
                ) : (
                  <>
                    {false && (
                      <Gallery>
                        <SliderComponent
                          type="product-detail"
                          slides={photogallery.map((doc, i) => ({
                            img: doc,
                            name: i.toString(),
                          }))}
                        />
                      </Gallery>
                    )}
                    <SliderComponent
                      type="product-detail"
                      slides={photogallery.map((doc, i) => ({
                        img: doc,
                        name: i.toString(),
                      }))}
                    />
                  </>
                )}

                <ShareWrapper withTitle={false} desc={''} title={name} />
              </ProductInfo>
              <ProductTabber>
                {this.renderPopis(desc)}
                {this.renderSpecs(body)}
                {this.state.width <= 1340
                  ? this.renderSimilar(lang, articles, articlesIsFetching)
                  : null}
              </ProductTabber>
            </ProductWrapper>
            <More>
              <ArticleList
                lang={lang}
                articles={articles}
                isFetching={articlesIsFetching}
                noMinWidth={true}
                withoutAnnot={true}
              />
            </More>
          </Wrapper>
        )}
      </ProductItemWrapper>
    );
  }

  public OnResize = () => {
    this.setState({ width: window.innerWidth });
  };
}

const mapStateToProps = state => {
  return {
    defaultTitle: titleSelector(state),
    lang: langSelector(state),
    product: productDataSelector(state),
    isFetching: productDetailIsFetchingSelector(state),
    offset: productDetailArticlesOffsetSelector(state),
    total: productDetailArticlesTotalSelector(state),
    articles: productDetailArticlesSelector(state),
    articlesIsFetching: productDetailArticlesIsFetchingSelector(state),
  };
};
export default connect(mapStateToProps)(
  connectSsr({ displayName: 'ProductItem' })(withRouter(ProductItem)),
);
