import * as React from 'react';
import { IndexRoute, Route, Router } from 'react-router';
import { __ } from 'react-i18n';
// containers
import Home from './containers/Home/Home';
import App from './containers/App/App';
import UrlMapper from './containers/UrlMapper/UrlMapper';
import SearchResults from './containers/Search/SearchResults';
import Author from './containers/Author/Author';
import ProductItem from './containers/Product/ProductItem';
import Ebook from './containers/Ebook/Ebook';
import RSS from './components/RSS/RSS';
import Sitemap from './components/Sitemap/Sitemap';
import EbooksPage from './containers/EbooksPage/EbooksPage';
import TagsPage from './containers/TagsPage/TagsPage';
import BrandsPage from './containers/BrandsPage/BrandsPage';
import BrandsListPage from './components/Brands/BrandsListPage';
import FastNewsPage from './containers/FastNewsPage/FastNewsPage';
import ReactGA from 'react-ga4';

const scrollToTop = () => {
  window.scrollTo({
    top: 0,
    left: 0,
    behavior: 'smooth',
  });
};

const onUpdate = () => {
  scrollToTop();

  setTimeout(() => {
    ReactGA.set({ page: window.location.pathname });
    //ReactGA.pageview(window.location.pathname + window.location.search);
    ReactGA.send('pageview');
  }, 2500);
};

const Routes = (props: any) => {
  return (
    <Router onUpdate={onUpdate} history={props.history}>
      <Route path={`/(cz)`} component={App}>
        <Route path={__('routes:vyhladavanie')} component={SearchResults} />
        <Route path={'autor/:author_id/:author_name'} component={Author} />
        <Route
          path={'produkt/:product_id/:product_url'}
          component={ProductItem}
        />
        <Route path={'tag/:tag_id/:tag_name'} component={TagsPage} />
        <Route path={'znacky'} component={BrandsListPage} />
        <Route path={'znacky/:brand_name'} component={BrandsPage} />
        <Route path={'ebook/:ebook_url'} component={Ebook} />
        <Route path={'rss'} component={RSS} />
        <Route path={'sitemap'} component={Sitemap} />
        <Route path={'ebooky'} component={EbooksPage} />
        <Route path={'bleskovky'} component={FastNewsPage} />
        <Route path="**" component={UrlMapper} />
        <Route path="/cz**" component={UrlMapper} />
        <IndexRoute component={Home} />
      </Route>
    </Router>
  );
};

export default Routes;
