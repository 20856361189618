import API from './services/API';
import * as cookies from 'react-cookies';
import { LANG_COOKIE } from './containers/App/constants';
import { prop } from './utilities';

export async function loadTranslations(location) {
  // const fromCookie = cookies.load(LANG_COOKIE) || 'sk';
  // return API.loadLangTranslation(fromCookie);
  return API.loadLangTranslation(
    resolveLangByHostname(prop(location, 'hostname') || 'sk'),
  );
}

export function resolveLangFromCookies(hostname: string): string {
  return cookies.load(LANG_COOKIE) || 'sk';
}

export function resolveLangByHostname(hostname: string): string {
  if (process.env.NODE_ENV === 'development') {
    return 'cz';
  }
  if (hostname && hostname.includes('cz')) {
    return 'cz';
  } else {
    return 'sk';
  }
}

export function resolveCurrencyByHostname(hostname: string): string {
  if (process.env.NODE_ENV === 'development') {
    return 'EUR';
  }

  if (hostname && hostname.includes('cz')) {
    return 'CZK';
  } else {
    return 'EUR';
  }
}
