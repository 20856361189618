import * as React from 'react';
import styled from 'styled-components';
import { rem } from 'polished';
import { InputStyles } from '../FormStyles';

const InputWrapper = styled.input`
  ${InputStyles};
  border-radius: ${rem(4)};
  &:focus {
  }
  &:not(:disabled) {
    &:hover {
    }
  }
  &:disabled {
    cursor: not-allowed;
  }
`;

const InputDefaultWrapper = styled.input`
  ${InputStyles};
  background: ${({ theme }) => theme.color.white};
  border: ${rem(1)} solid ${({ theme }) => theme.color.gray86};
  border-radius: ${rem(4)};
  padding: ${rem(10)};
  ${({ theme }) => theme.media(1024)} {
    font-size: ${rem(14)};
    padding: ${rem(11)};
  }
  &:focus {
  }
  &:not(:disabled) {
    &:hover {
    }
  }
  &:disabled {
    cursor: not-allowed;
  }
  &:read-only {
    color: ${({ theme }) => theme.color.gray80};
  }
`;

export interface InputIO {
  type?: string;
  name: string;
  placeholder?: string;
  className?: string;
  value: string | number;
  readonly?: boolean;
  onFocus?: () => void;
  onBlur?: () => void;
  onChange?: (e) => void;
}

class Input extends React.Component<InputIO> {
  public render() {
    const {
      type,
      name,
      className,
      placeholder,
      readonly,
      onFocus,
      onBlur,
      value,
    } = this.props;

    return (
      <InputWrapper
        {...this.props}
        type={type ? type : 'text'}
        name={name}
        className={className}
        placeholder={placeholder ? placeholder : ''}
        readOnly={readonly}
        onFocus={onFocus}
        onBlur={onBlur}
        onChange={this.handleChange}
        value={value}
      />
    );
  }

  public handleChange = e => {
    if (this.props.onChange) {
      this.props.onChange(e);
    }
  };
}

export class InputDefault extends React.Component<InputIO> {
  public render() {
    const { type, name, className, placeholder, readonly, value } = this.props;

    return (
      <InputDefaultWrapper
        type={type ? type : 'text'}
        name={name}
        className={className}
        placeholder={placeholder ? placeholder : ''}
        readOnly={readonly}
        value={value}
        onChange={this.handleChange}
      />
    );
  }

  public handleChange = e => {
    if (this.props.onChange) {
      this.props.onChange(e);
    }
  };
}

export default Input;
