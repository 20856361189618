import React from 'react';
import { __ } from 'react-i18n/lib';
import styled from 'styled-components';
import { rem } from 'polished';
import { FlexCol, FlexRowCenterVertical } from 'eshop-defaults';
import { Link } from 'react-router';
import FilterSelect from '../_helpers/Default/FilterSelect';
import { Loader } from '../_helpers/Loader/Loader';
import { trackArticleClick } from '../../utilities/article';

const Wrapper = styled(FlexCol)`
  display: ${props => (props.mobile ? 'none' : 'flex')};
  justify-content: center;
  padding: ${rem(24)};
  margin-bottom: ${rem(32)};
  width: ${rem(360)};
  border: ${rem(1)} solid ${({ theme }) => theme.blogColors.border};
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.16);

  ${({ theme }) => theme.mediab.m810`
      display: ${props => (props.mobile ? 'flex' : 'none')};
      padding: 0;
      border: 0;
      box-shadow: none;
      width: 100%;
      margin-bottom: ${rem(48)};
  `}
`;

const TitleWrapper = styled(FlexRowCenterVertical)`
  justify-content: space-between;
  margin-bottom: ${rem(24)};

  ${({ theme }) => theme.mediab.m810`
      margin-bottom: ${rem(16)};
  `}
`;

export const TitleText = styled.h2`
  margin: 0;
  font-family: ${({ theme }) => theme.blogFonts.primaryFont};
  font-size: ${rem(24)};
  color: ${({ theme }) => theme.blogColors.primary};
  font-weight: 700;
  text-transform: uppercase;
  display: flex;
  align-items: center;

  ${({ theme }) => theme.mediab.m810`
      font-size: ${rem(20)};
  `}
`;

export const FastNewsIcon = styled.img`
  width: ${rem(18)};
  height: ${rem(20)};
  margin-right: ${rem(10)};
`;

const SidebarArticleItem = styled(Link)`
  margin: 0;
  font-family: ${({ theme }) => theme.blogFonts.primaryFont};
  font-size: ${rem(16)};
  color: ${({ theme }) => theme.blogColors.primary};
  font-weight: 500;
  text-transform: uppercase;
  text-decoration: none;
  padding: ${rem(24)} 0;
  border-top: ${rem(1)} solid ${({ theme }) => theme.blogColors.border};
  line-height: 20px;

  &:nth-child(2) {
    /* border-top: 0; */
  }

  &:last-child {
    padding-bottom: 0;
  }

  &:hover {
    opacity: 0.8;
  }

  ${({ theme }) => theme.mediab.m810`
      padding: ${rem(16)} 0;
      &:nth-child(2) {
        border-top: 0;
      }
  `}
`;

interface Props {
  title: any;
  value?: string;
  options?: Array<{ name: string; value: string }>;
  onChange?: any;
  isFetching: boolean;
  // articles: ThenArg<typeof API.loadArticles>;
  articles: any;
  mobile?: boolean;
  lang: string;
}

const SidebarArticles: React.SFC<Props> = ({
  title,
  value = '',
  options,
  onChange,
  isFetching,
  articles,
  mobile,
  lang,
}) => {
  return (
    <Wrapper mobile={mobile}>
      <TitleWrapper>
        {title}
        {options && options.length > 0 && onChange && (
          <FilterSelect value={value} onChange={onChange} options={options} />
        )}
      </TitleWrapper>
      {isFetching ? (
        <Loader />
      ) : (
        articles &&
        articles.map((article, i) => {
          if (article) {
            return (
              <SidebarArticleItem
                key={`${article.link}-${article.name}-${i}`}
                to={`/${article.url}`}
                // tslint:disable-next-line:jsx-no-lambda
                onClick={() => trackArticleClick(article.id, lang)}
              >
                {article.name}
              </SidebarArticleItem>
            );
          }
          return null;
        })
      )}
    </Wrapper>
  );
};

export default SidebarArticles;
