import * as React from 'react';
import styled from 'styled-components';
import { rem } from 'polished';
import { connectSsr } from 'ssr-service';
import { connect } from 'react-redux';
import { __ } from 'react-i18n';
import Pagination from '../../components/Pagination/Pagination';
import {
  searchedProductsSelector,
  searchIsFetchingSelector,
  searchParamsSelector,
} from './selectors';

import MetaTags from '../../components/MetaTags/MetaTags';
import {
  contactInfoSelector,
  searchResultsSelector,
  searchResultsOffsetSelector,
} from '../App/selectors';
import { FlexCol, FlexRowCenterVertical } from 'eshop-defaults';
import { ThenArg } from '../../services/Zasielkovna-API';
import API from '../../services/API';
import { loadSearchTermResults } from '../App/actions';
import ArticleList from '../../components/Article/ArticleList';
import ArticlesWithSidebar from '../../components/Home/ArticlesWithSidebar';
import SidebarArticlesWrapper from '../../components/Home/SidebarArticlesWrapper';
import {
  mostReadArticlesSelector,
  recommendedArticlesSelector,
  fastNewsSelector,
  ebooksSelector,
  recommendedArticlesOptionsSelector,
} from '../Home/selectors';
import {
  loadHomeMostRead,
  loadHomeRecommended,
  loadHomeFastNews,
  loadHomeEbooks,
} from '../Home/actions';
import { push } from 'react-router-redux';
import { withRouter, WithRouterProps } from 'react-router';
import { langSelector } from '../Category/selectors';

interface Props {
  offset: number;
  lang: string;
  location: {
    query: {
      q: string;
      offset?: number;
    };
    pathname: string;
  };
  searchedProducts: any;
  searchIsFetching: boolean;
  recommendedOptions: ThenArg<typeof API.loadSitemapSubtree>['sitemap_tree'];
  searchResults: {
    isFetching: boolean;
    results: {
      // articles: ThenArg<typeof API.loadArticles>;
      articles: any;
    };
  };
  searchParams: any;
  compatibleProductsByProductId: any;
  contactInfo: {
    contactEmail: string;
    contactTel: string;
  };
  dispatch: (action: any) => void;
  ebooks: {
    ebooksData: ThenArg<typeof API.loadArticles>;
    isFetching: boolean;
  };
  mostRead: {
    isFetching: boolean;
    mostReadArticles: ThenArg<typeof API.loadArticles>;
    filter?: string;
  };
  recommended: {
    isFetching: boolean;
    recommendedArticles: ThenArg<typeof API.loadArticles>;
    filter?: string;
  };
  fastNews: {
    isFetching: boolean;
    fastNewsArticles: ThenArg<typeof API.loadArticles>;
  };
}
// Styled Components

const Wrapper = styled.div`
  padding: ${rem(24)} ${rem(20)};
`;
// const HeaderWrapper = styled.div`
//   width: 100%;
//   background: ${({ theme }) => theme.color.white};
//   margin: ${rem(20)} 0 ${rem(40)};
//   box-shadow: ${({ theme }) => theme.boxShadow.default};
//   border-radius: ${rem(4)};
// `;

const Header = styled(FlexCol)`
  padding-bottom: ${rem(32)};
  margin-bottom: ${rem(2)};
  border-bottom: ${rem(1)} solid ${({ theme }) => theme.blogColors.border};

  ${({ theme }) => theme.mediab.m580`
     padding: 0;
     border: 0;
  `}
`;

const SearchedTermWrapper = styled(FlexRowCenterVertical)`
  color: ${({ theme }) => theme.blogColors.primary};
  font-size: ${rem(32)};
  font-family: ${({ theme }) => theme.blogFonts.primaryFont};
  font-weight: 500;
  text-transform: uppercase;
  line-height: ${rem(40)};
  flex-flow: row wrap;
  display: block;

  ${({ theme }) => theme.mediab.m580`
     font-size: ${rem(20)};
     line-height: ${rem(20)};
  `}
`;

const HighlitedSearchTerm = styled.span`
  font-weight: 700;
  margin-left: ${rem(5)};
`;

const ResultsTotal = styled.p`
  margin: 0;
  margin-top: ${rem(16)};
  font-size: ${rem(20)};
  color: ${({ theme }) => theme.blogColors.primary};
  font-family: ${({ theme }) => theme.blogFonts.primaryFont};
  font-weight: 400;

  ${({ theme }) => theme.mediab.m580`
     margin-top: ${rem(8)};
     font-size: ${rem(16)};
  `}
`;

class SearchResults extends React.Component<Props & WithRouterProps> {
  public static getSearchQuery = (props: Props): string =>
    props.location.query.q;

  public static async getInitialProps(props) {
    const { dispatch, location } = props;
    const {
      query: { offset },
    } = location;

    const searchTerm = SearchResults.getSearchQuery(props);

    try {
      await Promise.all[
        (await dispatch(loadSearchTermResults(searchTerm, 10, offset)),
        await dispatch(loadHomeMostRead()),
        await dispatch(loadHomeRecommended(null)),
        await dispatch(loadHomeFastNews()),
        await dispatch(loadHomeEbooks()))
      ];

      return {};
    } catch (exp) {
      return {
        isError: true,
      };
    }
  }

  public getMainContent() {
    const {
      offset,
      searchResults: {
        results: { articles },
        isFetching,
      },
      lang,
    } = this.props;

    const searchTerm = SearchResults.getSearchQuery(this.props);
    let foundText = 'Našlo sa ';
    let resultsText = ' výsledkov';
    if (articles) {
      foundText =
        articles.total === 1
          ? 'Našiel sa '
          : articles.total < 5
          ? 'Našli sa '
          : 'Našlo sa ';
      resultsText =
        articles.total === 1
          ? ' výsledok'
          : articles.total < 5
          ? ' výsledky'
          : ' výsledkov';
    }

    return (
      <>
        <Header>
          <SearchedTermWrapper>
            {__('Hľadaný výraz')}{' '}
            <HighlitedSearchTerm>"{searchTerm}"</HighlitedSearchTerm>
          </SearchedTermWrapper>
          <ResultsTotal>
            {__(foundText)} {articles && articles.total}
            {__(resultsText)}
          </ResultsTotal>
        </Header>
        <FlexCol>
          <ArticleList
            lang={lang}
            articles={(articles && articles.articles) || []}
            isFetching={isFetching}
          />
          <Pagination
            query={this.getPaginationQuery()}
            totalItems={(articles && articles.total) || 0}
            limit={10}
            offset={offset}
            onOffsetChange={this.handleOffsetChange}
          />
        </FlexCol>
      </>
    );
  }

  public getSidebarContent() {
    const {
      fastNews,
      mostRead,
      recommended,
      recommendedOptions,
      ebooks,
      lang,
    } = this.props;

    const mostReadFilter = [
      { name: __('TÝŽDEŇ'), value: 'week' },
      { name: __('MESIAC'), value: 'month' },
      { name: __('ROK'), value: 'year' },
    ];

    const recommendedFilter =
      recommendedOptions &&
      recommendedOptions.map(o => ({
        name: o.name || '',
        value: o.id ? o.id.toString() : '',
      }));

    return (
      <SidebarArticlesWrapper
        fastNews={fastNews}
        mostRead={mostRead}
        recommended={recommended}
        ebooks={ebooks}
        mostReadFilter={mostReadFilter}
        recommendedFilter={recommendedFilter}
        dispatch={this.props.dispatch}
        lang={lang}
      />
    );
  }

  public render() {
    const q = SearchResults.getSearchQuery(this.props);

    return (
      <Wrapper>
        <MetaTags tags={{ title: `Vyhľadávanie: ${q}` }} />
        <ArticlesWithSidebar
          mainContentChildren={this.getMainContent()}
          sidebarChildren={this.getSidebarContent()}
        />
      </Wrapper>
    );
  }

  public handleOffsetChange = async (newOffset: number) => {
    const q = this.props.location.query.q;
    this.props.dispatch(
      push({
        pathname: this.props.location.pathname,
        search: `?q=${q}&offset=${newOffset}`,
      }),
    );
  };

  public getPaginationQuery = () => {
    return `${this.props.location.pathname}?q=${this.props.location.query.q}`;
  };
}

const mapStateToProps = state => ({
  searchIsFetching: searchIsFetchingSelector(state),
  searchedProducts: searchedProductsSelector(state),
  searchParams: searchParamsSelector(state),
  contactInfo: contactInfoSelector(state),
  searchResults: searchResultsSelector(state),
  offset: searchResultsOffsetSelector(state),
  mostRead: mostReadArticlesSelector(state),
  recommended: recommendedArticlesSelector(state),
  recommendedOptions: recommendedArticlesOptionsSelector(state),
  fastNews: fastNewsSelector(state),
  ebooks: ebooksSelector(state),
  lang: langSelector(state),
});

export default connect(mapStateToProps)(
  connectSsr({ displayName: 'SearchResults' })(withRouter(SearchResults)),
);
