import * as React from 'react';
import styled from 'styled-components';
import { rem } from 'polished';
import { connectSsr } from 'ssr-service';
import { connect } from 'react-redux';
import { __ } from 'react-i18n';
import Pagination from '../../components/Pagination/Pagination';

import MetaTags from '../../components/MetaTags/MetaTags';

import { FlexCol, FlexRowCenterVertical } from 'eshop-defaults';
import { ThenArg } from '../../services/Zasielkovna-API';
import API from '../../services/API';
import ArticlesWithSidebar from '../../components/Home/ArticlesWithSidebar';
import SidebarArticlesWrapper from '../../components/Home/SidebarArticlesWrapper';
import { push } from 'react-router-redux';
import { withRouter, WithRouterProps } from 'react-router';
import { loadEbooks } from './actions';
import {
  fastNewsSelector,
  recommendedArticlesSelector,
  mostReadArticlesSelector,
  recommendedArticlesOptionsSelector,
} from '../Home/selectors';
import { langSelector } from '../Category/selectors';
import {
  ebooksDataSelector,
  ebooksIsFetchingSelector,
  ebooksOffsetSelector,
  ebooksTotalSelector,
} from './selectors';
import {
  loadHomeMostRead,
  loadHomeRecommended,
  loadHomeFastNews,
} from '../Home/actions';
import EbookList from '../../components/Ebook/EbookList';

interface Props {
  offset: number;
  lang: string;
  location: {
    query: {
      offset?: number;
    };
    pathname: string;
  };
  dispatch: (action: any) => void;
  mostRead: {
    isFetching: boolean;
    mostReadArticles: ThenArg<typeof API.loadArticles>;
    filter?: string;
  };
  recommendedOptions: ThenArg<typeof API.loadSitemapSubtree>['sitemap_tree'];
  recommended: {
    isFetching: boolean;
    recommendedArticles: ThenArg<typeof API.loadArticles>;
    filter?: string;
  };
  fastNews: {
    isFetching: boolean;
    fastNewsArticles: ThenArg<typeof API.loadArticles>;
  };
  mainEbooks: any;
  isFetching: boolean;
  mainEbooksTotal: number;
}
// Styled Components

const Wrapper = styled.div`
  padding: ${rem(24)} ${rem(20)};
`;

const Header = styled(FlexCol)`
  width: 100%;
  padding-bottom: ${rem(32)};
  margin-bottom: ${rem(2)};
  border-bottom: ${rem(1)} solid ${({ theme }) => theme.blogColors.border};
  line-height: ${rem(32)};

  ${({ theme }) => theme.mediab.m580`
     padding: 0;
     border: 0;
  `}
`;

const SearchedTermWrapper = styled(FlexRowCenterVertical)`
  color: ${({ theme }) => theme.blogColors.primary};
  font-size: ${rem(32)};
  font-family: ${({ theme }) => theme.blogFonts.primaryFont};
  font-weight: 500;
  text-transform: uppercase;

  ${({ theme }) => theme.mediab.m580`
     font-size: ${rem(20)};
  `}
`;

class EbooksPage extends React.Component<Props & WithRouterProps> {
  public static async getInitialProps(props) {
    const { dispatch, location } = props;
    const {
      query: { offset },
    } = location;

    // await dispatch(loadEbooks(10, offset));
    // await dispatch(loadHomeMostRead());
    // await dispatch(loadHomeRecommended(null));
    // await dispatch(loadHomeFastNews());

    // return {};

    try {
      await Promise.all[
        (await dispatch(loadEbooks(10, offset)),
        await dispatch(loadHomeMostRead()),
        await dispatch(loadHomeRecommended(null)),
        await dispatch(loadHomeFastNews()))
      ];

      return;
    } catch (exp) {
      return {
        isError: true,
      };
    }
  }

  public getMainContent() {
    const {
      offset,
      mainEbooks,
      lang,
      isFetching,
      mainEbooksTotal,
    } = this.props;

    return (
      <>
        <Header>
          <SearchedTermWrapper>{__('Zoznam ebookov')}</SearchedTermWrapper>
        </Header>
        <FlexCol>
          <EbookList lang={lang} ebooks={mainEbooks} isFetching={isFetching} />
          <Pagination
            query={this.getPaginationQuery()}
            totalItems={mainEbooksTotal}
            limit={10}
            offset={offset}
            onOffsetChange={this.handleOffsetChange}
          />
        </FlexCol>
      </>
    );
  }

  public getSidebarContent() {
    const {
      fastNews,
      mostRead,
      recommended,
      recommendedOptions,
      lang,
    } = this.props;

    const mostReadFilter = [
      { name: __('TÝŽDEŇ'), value: 'week' },
      { name: __('MESIAC'), value: 'month' },
      { name: __('ROK'), value: 'year' },
    ];

    const recommendedFilter =
      recommendedOptions &&
      recommendedOptions.map(o => ({
        name: o.name || '',
        value: o.id ? o.id.toString() : '',
      }));

    return (
      <SidebarArticlesWrapper
        fastNews={fastNews}
        mostRead={mostRead}
        recommended={recommended}
        // showing no ebooks in sidebar on ebooks page
        ebooks={[]}
        mostReadFilter={mostReadFilter}
        recommendedFilter={recommendedFilter}
        dispatch={this.props.dispatch}
        lang={lang}
      />
    );
  }

  public render() {
    return (
      <Wrapper>
        <MetaTags tags={{ title: `Zoznam ebookov` }} />
        <ArticlesWithSidebar
          mainContentChildren={this.getMainContent()}
          sidebarChildren={this.getSidebarContent()}
        />
      </Wrapper>
    );
  }

  public handleOffsetChange = async (newOffset: number) => {
    this.props.dispatch(
      push({
        pathname: this.props.location.pathname,
        search: `?offset=${newOffset}`,
      }),
    );
  };

  public getPaginationQuery = () => {
    return `${this.props.location.pathname}?`;
  };
}

const mapStateToProps = state => ({
  offset: ebooksOffsetSelector(state),
  mostRead: mostReadArticlesSelector(state),
  recommended: recommendedArticlesSelector(state),
  recommendedOptions: recommendedArticlesOptionsSelector(state),
  fastNews: fastNewsSelector(state),
  mainEbooks: ebooksDataSelector(state),
  mainEbooksTotal: ebooksTotalSelector(state),
  isFetching: ebooksIsFetchingSelector(state),
  lang: langSelector(state),
});

export default connect(mapStateToProps)(
  connectSsr({ displayName: 'EbooksPage' })(withRouter(EbooksPage)),
);
