import * as React from 'react';
import { connect } from 'react-redux';
import { default as API, ThenArg } from '../../services/API';
import { prop } from '../../utilities';
import { langSelector, titleSelector } from '../Category/selectors';
import { userSelector, contactInfoSelector } from '../App/selectors';
import { showDimmer, openLoginModal } from '../App/actions';
import isEqual from 'lodash/isEqual';

export interface ProductProps {
  product: any; // ThenArg<typeof API.loadProduct>;
  connections: ThenArg<typeof API.searchProducts>;
  compatibles: ThenArg<typeof API.searchProducts>;
  dispatch: any;
  isCartProcessing: any;
  isError: boolean;
  lang: string;
  user: any;
  defaultTitle: string;
  setActiveVariant: any;
  setCurrentProductId: any;
  customer: any;
  preferablyPackaging: any[];
  contactInfo: {
    contactEmail: string;
    contactTel: string;
  };
  compatibleProductsByProductId: any;
}

export interface State {
  mainImage: string;
  pid: number;
  width: number;
  activeIndex: number;
  crumbs: Array<{
    name: string;
    url: string;
  }>;
  isInWishlist: boolean;
}

class Product extends React.Component<ProductProps, State> {
  constructor(props) {
    super(props);
    this.state = {
      mainImage: '',
      pid: -1,
      width: 0,
      activeIndex: 0,
      crumbs: [{ name: 'Úvod', url: '/' }],
      isInWishlist: false,
    };
  }

  // public metaHandler = lang => {
  //   const { product } = this.props;
  //   const productPublish = propByKey(product, 'publish', []).find(
  //     pp => pp.lang_id === lang,
  //   );
  //
  //   const metaTags = propByKey(productPublish, 'content.json_content.meta', {
  //     title: this.props.defaultTitle,
  //   });
  //
  //   if (!metaTags.title.length) {
  //     metaTags.title = `${this.props.defaultTitle} - ${product.name}`;
  //   }
  //
  //   return <MetaTags tags={metaTags} />;
  // };

  public addItemToCart = async (productId, goodId, count) => {
    console.log('add');
  };

  public addToWishlist = () => {
    const { dispatch, customer } = this.props;

    if (customer) {
      this.setState(prevState => ({
        isInWishlist: !prevState.isInWishlist,
      }));
    } else {
      dispatch(showDimmer());
      dispatch(openLoginModal());
    }
  };

  public componentDidMount() {
    this.OnResize();

    window.addEventListener('resize', this.OnResize);
    const { product } = this.props;
    if (product) {
      this.setState({
        isInWishlist: product.is_in_wishlist,
      });
    }
  }

  public componentWillUnmount() {
    window.removeEventListener('resize', this.OnResize);
  }

  public componentDidUpdate(prevProps: ProductProps) {
    const { product } = this.props;
    const { crumbs } = this.state;
    if (!isEqual(prevProps.product, product) && product.publish[0]) {
      this.setState({
        isInWishlist: product.is_in_wishlist,
        crumbs: [
          ...crumbs,
          ...prop(product, 'productCategories', []).map(category => ({
            name: category.category_name,
            url: category.url,
          })),
          {
            name: product.publish[0].product_name,
            url: product.publish[0].product_url,
          },
        ],
      });
    }
  }

  public render() {
    return null;
  }

  public OnResize = () => {
    // todo
  };
}

const mapStateToProps = state => {
  return {
    customer: userSelector(state),
    lang: langSelector(state),
    defaultTitle: titleSelector(state),
    contactInfo: contactInfoSelector(state),
  };
};

export default connect(mapStateToProps)(Product);
