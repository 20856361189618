import * as cookies from 'react-cookies';
import {
  CURRENCY_COOKIE,
  LANG_COOKIE,
  SET_CURRENCY,
  SET_DEFAULT_TITLE,
  SET_LANGUAGE,
  SET_TOP_MENU_ITEMS,
  SET_FOOTER_DATA,
  SET_CONTACT_INFO,
  SET_SOCIAL_URLS,
  SET_META_TAGS,
  REQUEST_REGISTER_USER,
  RECEIVE_REGISTER_USER_SUCCESS,
  RECEIVE_REGISTER_USER_FAILURE,
  OPEN_DIMMER,
  CLOSE_DIMMER,
  REQUEST_CREATED_ORDER,
  RECEIVE_CREATED_ORDER_SUCCESS,
  RECEIVE_CREATED_ORDER_ERROR,
  OPEN_LOGIN_MODAL,
  CLOSE_LOGIN_MODAL,
  SET_INVOICE_DATA,
  SET_CONTROL_INSTITUTE_DATA,
  SET_POINTS_AMOUNT_RATIO,
  SET_FREE_DELIVERY_INFO,
  SET_TOP_MENU_BRANDS,
  REQUEST_SEARCH_TERM,
  RECEIVE_SEARCH_TERM_SUCCESS,
  RECEIVE_PARTIAL_SEARCH_TERM_SUCCESS,
  REQUEST_PARTIAL_SEARCH_TERM,
  SET_SEARCH_RESULTS_FILTERS,
  ERROR_TOP_MENU_ITEMS,
  REQUEST_TOP_MENU_ITEMS,
  RECEIVE_SITEMAP_SUCCESS,
} from './constants';
import { generalInitialState } from './general-reducer';
import { cookiesExpiresDate, prop } from '../../utilities';
import { langSelector } from './selectors';
import APIClass from '../../services/API';

export const setLanguage = lang => {
  // const fromCookie = cookies.load(LANG_COOKIE);
  // if (fromCookie) {
  //   lang = fromCookie;
  // } else if (!lang) {
  //   lang = generalInitialState.lang;
  // }

  // cookies.save(LANG_COOKIE, lang, { path: '/', expires: cookiesExpiresDate() });

  return {
    type: SET_LANGUAGE,
    payload: {
      lang,
    },
  };
};

export const changeLanguage = newLang => {
  cookies.save(LANG_COOKIE, newLang, {
    path: '/',
    expires: cookiesExpiresDate(),
  });
  return {
    type: SET_LANGUAGE,
    payload: {
      lang: newLang,
    },
  };
};

export const setCurrency = currency => {
  if (!currency) {
    currency = generalInitialState.currency;
  }
  cookies.save(CURRENCY_COOKIE, currency, {
    path: '/',
    expires: cookiesExpiresDate(),
  });
  return {
    type: SET_CURRENCY,
    payload: {
      currency,
    },
  };
};

export const setDefaultMetaTitle = defaultTitle => {
  return {
    type: SET_DEFAULT_TITLE,
    payload: {
      defaultTitle,
    },
  };
};

export const loadDefaultMetaTitle = () => {
  return async (dispatch, getState, API) => {
    try {
      const currentState = getState();
      const [{ value: defaultTitle }] = await API.getSettingByName(
        'meta_title',
        {
          xAcceptLanguage: currentState.general.lang,
        },
      );

      dispatch(setDefaultMetaTitle(defaultTitle));
    } catch (e) {
      console.log(e);
    }
  };
};

export const requestTopMenuItems = () => {
  return {
    type: REQUEST_TOP_MENU_ITEMS,
  };
};

export const errorTopMenuItems = () => {
  return {
    type: ERROR_TOP_MENU_ITEMS,
  };
};

export const setTopMenuItems = menuItems => {
  return {
    type: SET_TOP_MENU_ITEMS,
    payload: {
      menuItems,
    },
  };
};

export const loadTopMenuItems = () => {
  return async (dispatch, getState, API) => {
    try {
      dispatch(requestTopMenuItems());
      const currentState = getState();

      const sitemaps = await API.loadSitemapSubtree(
        'HEADER',
        {},
        {
          xAcceptLanguage: currentState.general.lang,
        },
      );
      const menuItems = sitemaps.sitemap_tree;

      dispatch(setTopMenuItems(menuItems));
    } catch (e) {
      console.log(e);
      dispatch(errorTopMenuItems());
    }
  };
};

export const setTopMenuBrands = brands => {
  return {
    type: SET_TOP_MENU_BRANDS,
    payload: {
      brands,
    },
  };
};

export const loadTopMenuBrands = () => {
  return async (dispatch, getState, API) => {
    try {
      const currentState = getState();
      const brands = await APIClass.loadBrands(
        { sort: 'brand_name', sortDir: 'asc' },
        {
          xAcceptLanguage: currentState.general.lang,
        },
      );
      const topMenuBrands = brands.brands;

      dispatch(setTopMenuBrands(topMenuBrands));
    } catch (e) {
      console.log(e);
    }
  };
};

export const setFooterData = footerData => {
  return {
    type: SET_FOOTER_DATA,
    payload: {
      footerData,
    },
  };
};

export const loadFooterData = () => {
  return async (dispatch, getState, API) => {
    try {
      const currentState = getState();
      if (!currentState.general.footerData) {
        const footerData = await API.loadSitemapSubtree(
          'PATICKA',
          {},
          {
            xAcceptLanguage: currentState.general.lang,
          },
        );

        dispatch(setFooterData(footerData));
      }
    } catch (e) {
      console.log(e);
    }
  };
};

export const setContactInfo = contactInfo => {
  return {
    type: SET_CONTACT_INFO,
    payload: {
      contactInfo,
    },
  };
};

export const loadContactInfo = () => {
  return async (dispatch, getState, API) => {
    try {
      const currentState = getState();
      let contactInfo = await API.getSettingByName('email,tel, ', {
        xAcceptLanguage: currentState.general.lang,
      });

      const [{ value: contactEmail }, { value: contactTel }] = contactInfo;

      contactInfo = {
        contactEmail,
        contactTel,
      };

      dispatch(setContactInfo(contactInfo));
    } catch (e) {
      console.log(e);
    }
  };
};

export const setSocialUrls = socialUrls => {
  return {
    type: SET_SOCIAL_URLS,
    payload: {
      socialUrls,
    },
  };
};

export const loadSocialUrls = () => {
  return async (dispatch, getState, API) => {
    try {
      const currentState = getState();
      let socialUrls = await API.getSettingByName(
        'socials_facebook,socials_instagram,socials_youtube,socials_blog,heureka_url',
        {
          xAcceptLanguage: currentState.general.lang,
        },
      );

      const [
        { value: fbUrl },
        { value: igUrl },
        { value: partyBlog },
        { value: heurekaUrl },
      ] = socialUrls;

      socialUrls = {
        fbUrl,
        igUrl,
        partyBlog,
        heurekaUrl,
      };

      dispatch(setSocialUrls(socialUrls));
    } catch (e) {
      console.log(e);
    }
  };
};

export const setMetaTags = metaTags => {
  return {
    type: SET_META_TAGS,
    payload: {
      metaTags,
    },
  };
};

export const loadMetaTags = () => {
  return async (dispatch, getState, API) => {
    try {
      const currentState = getState();
      let metaTags = await API.getSettingByName(
        'meta_description,meta_author,meta_keywords',
        {
          xAccpetLanguage: currentState.general.lang,
        },
      );

      const [
        { value: metaDescription },
        { value: metaKeywords },
        { value: metaAuthor },
      ] = metaTags;

      metaTags = {
        metaDescription,
        metaKeywords,
        metaAuthor,
      };

      dispatch(setMetaTags(metaTags));
    } catch (e) {
      console.log(e);
    }
  };
};

export const showDimmer = (lockScrollBar: boolean = true) => ({
  type: OPEN_DIMMER,
  payload: {
    lockScrollBar,
  },
});

export const hideDimmer = () => ({
  type: CLOSE_DIMMER,
});

const requestCreatedOrder = () => ({
  type: REQUEST_CREATED_ORDER,
});

const receivecreatedOrderSuccess = createdOrder => ({
  type: RECEIVE_CREATED_ORDER_SUCCESS,
  payload: {
    createdOrder,
  },
});

const receiveCreatedOrderError = error => ({
  type: RECEIVE_CREATED_ORDER_ERROR,
  payload: {
    error,
  },
});

export const loadCreatedOrder = (createdOrderId: string) => {
  return async (dispatch, getState, API) => {
    try {
      dispatch(requestCreatedOrder());
      const order = await API.getOrder(createdOrderId);
      dispatch(receivecreatedOrderSuccess(order));
    } catch (e) {
      dispatch(receiveCreatedOrderError(e));
    }
  };
};

export const openLoginModal = () => ({
  type: OPEN_LOGIN_MODAL,
});

export const hideLoginModal = () => ({
  type: CLOSE_LOGIN_MODAL,
});

const requestRegisterUser = () => ({
  type: REQUEST_REGISTER_USER,
});

const registerUserSuccess = () => ({
  type: RECEIVE_REGISTER_USER_SUCCESS,
});

const registerUserError = error => ({
  type: RECEIVE_REGISTER_USER_FAILURE,
  payload: {
    error,
  },
});

export const registerUser = (
  email: string,
  password: string,
  passwordAgain: string,
  termsAccept: boolean,
  newsletterAccept: boolean = false,
) => {
  return async (dispatch, getState, API) => {
    try {
      dispatch(requestRegisterUser());
      const lang: string = langSelector(getState());
      await API.registerUser(
        {},
        { email, lang, password, password_again: passwordAgain },
      );
      dispatch(registerUserSuccess());
    } catch (e) {
      dispatch(registerUserError(e.details.description));
    }
  };
};

export const setInvoiceData = invoiceData => ({
  type: SET_INVOICE_DATA,
  payload: {
    invoiceData,
  },
});

export const setControlInstituteData = controlInstituteData => ({
  type: SET_CONTROL_INSTITUTE_DATA,
  payload: {
    controlInstituteData,
  },
});

export const setPointsAmountRatio = pointsAmountRatio => ({
  type: SET_POINTS_AMOUNT_RATIO,
  payload: {
    pointsAmountRatio,
  },
});

export const setFreeDeliveryInfo = freeDeliveryInfo => ({
  type: SET_FREE_DELIVERY_INFO,
  payload: {
    freeDeliveryInfo,
  },
});

export const loadDefaultSettings = () => {
  return async (dispatch, getState, API) => {
    try {
      const lang = getState().general.lang;
      // const currency = getState().general.currency;

      // const freeDeliveryInfo = await API.getGeneralFreeDeliveryInfo(
      //   {},
      //   { xAcceptLanguage: lang, xCurrency: currency },
      // );

      // dispatch(setFreeDeliveryInfo(freeDeliveryInfo));

      const data = await API.getSettingByName(
        'meta_title,infoEmail,infoPhone,meta_description,meta_keywords,meta_author,meta_og:title,meta_og:description,meta_og:type,meta_og:image,socials_instagram,socials_facebook,socials_youtube',
        {},
        {
          xAcceptLanguage: lang,
        },
      );

      const socialUrls: any = {};
      const metaTags: any = {};
      const contactInfo: any = {};
      data.map(d => {
        if (d.name === 'meta_title') {
          dispatch(setDefaultMetaTitle(d.value));
        } else if (d.name === 'meta_keywords') {
          metaTags.metaKeywords = d.value;
        } else if (d.name === 'meta_description') {
          metaTags.metaDescription = d.value;
        } else if (d.name === 'meta_author') {
          metaTags.metaAuthor = d.value;
        } else if (d.name === 'meta_og:image') {
          metaTags['og:image'] = d.value;
        } else if (d.name === 'meta_og:title') {
          metaTags['og:title'] = d.value;
        } else if (d.name === 'meta_og:description') {
          metaTags['og:description'] = d.value;
        } else if (d.name === 'meta_og:type') {
          metaTags['og:type'] = d.value;
        } else if (d.name === 'socials_youtube') {
          socialUrls.youtubeUrl = d.value;
        } else if (d.name === 'socials_instagram') {
          socialUrls.instagramUrl = d.value;
        } else if (d.name === 'socials_facebook') {
          socialUrls.fbUrl = d.value;
        } else if (d.name === 'email') {
          contactInfo.email = d.value;
        }
      });

      if (metaTags) {
        dispatch(setMetaTags(metaTags));
      }

      if (socialUrls) {
        dispatch(setSocialUrls(socialUrls));
      }

      if (contactInfo) {
        dispatch(setContactInfo(contactInfo));
      }
    } catch (e) {
      console.log(e);
    }
  };
};

const requestSearchTerm = (searchTerm: string) => ({
  type: REQUEST_SEARCH_TERM,
  payload: {
    searchTerm,
  },
});

const receiveSearchTermResultsSuccess = (searchTerm, articles) => ({
  type: RECEIVE_SEARCH_TERM_SUCCESS,
  payload: {
    articles,
    searchTerm,
  },
});

export const loadSearchTermResults = (
  searchTerm: string,
  limit: number = 10,
  offset: number = 0,
  sort: string = '',
  sortDir: string = '',
) => {
  return async (dispatch, getState, API) => {
    try {
      dispatch(requestSearchTerm(searchTerm));
      dispatch(setSearchResultsFilters({ offset }));

      const lang = langSelector(getState());

      const articlesResult = await APIClass.loadArticles(
        {
          // sitemapUniqueId: 'IMPORT',
          limit,
          offset,
          query: searchTerm,
          onlyActual: '1',
          isPublished: '1',
          tagExcludeId: 1518,
          replaceCurrentLangTags: '1',
        },
        { xAcceptLanguage: lang },
      );

      dispatch(receiveSearchTermResultsSuccess(searchTerm, articlesResult));
    } catch (e) {
      console.error(e);
    }
  };
};

const receiveSitemapSuccess = content => ({
  type: RECEIVE_SITEMAP_SUCCESS,
  payload: {
    content,
  },
});

export const loadSitemap = lang => {
  return async (dispatch, getState, API) => {
    try {
      // const content = await APIClass.getHtmlSitemap(lang);
      const content = await fetch(`/sitemap-${lang}.html`);
      const htmlData = await content.text();
      console.log({ content, json: htmlData });
      await dispatch(receiveSitemapSuccess(htmlData));

      // await fetch(`/sitemap-${lang}.html`)
      //   .then(res => res.text())
      //   .then(res => dispatch(receiveSitemapSuccess(res)))
      //   .catch(err => console.error(err));
    } catch (e) {
      console.error(e);
    }
  };
};

export const setSearchResultsFilters = filters => ({
  type: SET_SEARCH_RESULTS_FILTERS,
  payload: {
    filters,
  },
});

const requestPartialSearchTerm = (searchTerm: string) => ({
  type: REQUEST_PARTIAL_SEARCH_TERM,
  payload: {
    searchTerm,
  },
});

const receivePartialSearchTermResultsSuccess = (searchTerm, articles) => ({
  type: RECEIVE_PARTIAL_SEARCH_TERM_SUCCESS,
  payload: {
    articles,
    searchTerm,
  },
});

export const loadPartialSearchTermResults = (
  searchTerm: string,
  limit: number = 3,
  offset: number = 0,
  sort: string = '',
  sortDir: string = '',
) => {
  return async (dispatch, getState, API) => {
    try {
      dispatch(requestPartialSearchTerm(searchTerm));
      const lang: string = langSelector(getState());
      const articlesResult = await API.loadArticles(
        {
          query: searchTerm,
          limit,
          offset: 0,
          onlyActual: '1',
          isPublished: '1',
          tagExcludeId: 1518,
          sort: 'created_date',
          replaceCurrentLangTags: 1,
        },
        { xAcceptLanguage: lang },
      );

      const articles = prop(articlesResult, 'articles', []);
      dispatch(receivePartialSearchTermResultsSuccess(searchTerm, articles));
    } catch (e) {
      console.error(e);
    }
  };
};
