import { connect } from 'react-redux';
import * as React from 'react';
import { rem } from 'polished';
import styled from 'styled-components';
import MetaTags from '../../components/MetaTags/MetaTags';
import ToTopButton from '../../components/_helpers/Default/ToTopButton';
import { ThenArg } from '../../services/API';
import API from '../../services/API';
import { langSelector, titleSelector } from '../Category/selectors';
import ShareWrapper from '../../components/_helpers/Default/ShareWrapper';
import SliderComponent from '../../components/_helpers/Slider/Slider';
import { FlexRow, prop } from 'eshop-defaults/lib';
import EbookAuthorTab from '../../components/Ebook/EbookAuthorTab';
import EbookCover from '../../components/Ebook/EbookCover';
import EbookDescription from '../../components/Ebook/EbookDescription';
import { loadEbook } from './actions';
import { connectSsr } from 'ssr-service';
import { ebookDataSelector, ebookIsFetchingSelector } from './selectors';
import { __ } from 'react-i18n';
import {
  NoResultsWrapper,
  NoResults,
} from '../../components/Article/ArticleList';
import { getEbookMetaTags } from '../../utilities/metatags';
import { ArticleMicrodata } from '@bart.sk-ecommerce/react-microdata';
import { formatDate } from '../../utilities';

export const MainWrapper = styled.div`
  padding: ${rem(50)} 0 0;
  margin: auto;
  display: flex;
  flex-direction: column;
  position: relative;

  ${({ theme }) => theme.mediab.m580`
     padding-top: ${rem(16)};
  `}
`;

const SliderWrapper = styled.div`
  width: 1000px;
  margin: auto;
  @media only screen and (max-width: 1130px) {
    width: 800px;
  }
  @media only screen and (max-width: 900px) {
    width: 600px;
  }
  @media only screen and (max-width: 650px) {
    width: 90vw;
  }
  @media only screen and (max-width: 380px) {
    width: 80vw;
  }
`;

const Also = styled.h3`
  text-align: left;
  padding-left: 20px;
  font: 24px/32px Barlow;
  font-weight: 500;
  letter-spacing: 0;
  color: #414141;
  opacity: 1;
  margin-top: ${rem(36)};
  @media only screen and (max-width: 1130px) {
    padding-left: 30px;
  }

  @media only screen and (max-width: 900px) {
    padding-left: 10px;
  }
`;

const ContentWrapper = styled(FlexRow)`
  margin: auto;
  display: flex;
  flex-direction: row;

  @media only screen and (max-width: 800px) {
    flex-direction: column;
    padding-top: 60px;
  }
  @media only screen and (max-width: 480px) {
    flex-direction: column;
    padding-top: 120px;
  }
  @media only screen and (max-width: 320px) {
    flex-direction: column;
    padding-top: 90px;
  }
`;

const CoverWrapper = styled.div`
  display: flex;
  flex-direction: row;
  @media only screen and (max-width: 1200px) {
    flex-direction: column;
  }
  @media only screen and (max-width: 800px) {
    width: fit-content;
    flex-direction: row;
    margin: auto;
    padding-top: 60px;
  }
  @media only screen and (max-width: 480px) {
    flex-direction: column;
  }
`;

interface State {
  crumbs: any[];
  width: number;
  height: number;
  visibleSlides: number;
}

export interface EbookProps {
  parentProps: {};
  dispatch: any;
  isError: boolean;
  lang: string;
  params: {
    ebook_url: string;
  };
  isFetching: boolean;
  ebook: ThenArg<typeof API.loadEbookDetail>;
}

class Ebook extends React.Component<EbookProps, State> {
  public static async getInitialProps({ params, dispatch }) {
    const { ebook_url } = params;

    try {
      await dispatch(loadEbook(ebook_url));

      return;
    } catch (exp) {
      return {
        isError: true,
      };
    }
  }

  public render() {
    const { ebook, isFetching } = this.props;

    if (!isFetching && !ebook.name) {
      return (
        <NoResultsWrapper>
          <NoResults>{__('Ebook sa nenašiel')}</NoResults>
        </NoResultsWrapper>
      );
    }

    const downloadLink = prop(ebook, 'downloadLink', '');
    const imageLink = prop(ebook, 'image', '');
    const downloadSize = prop(ebook, 'downloadSize', '2.3');
    const name = prop(ebook, 'name', '');
    const tags = prop(ebook, 'tags', '');
    // const meta = prop(ebook, 'json_content.meta', {});
    const description = prop(ebook, 'json_content.anotation', '');
    const body = prop(ebook, 'json_content.body', '');

    const authorName = prop(ebook, 'author.name', '');
    const authorImage = prop(ebook, 'author.image', '');
    const createdAt = prop(ebook, 'created_date', '');
    const connectedEbooks = prop(ebook, 'connected_ebooks', []);
    const connectedEbooksSlides = connectedEbooks.map(book => ({
      img: book.image,
      url: book.url,
      name: book.name,
      id: book.id,
    }));

    const showAuthor =
      (authorImage && authorImage !== '') ||
      (authorName && authorName !== '') ||
      (createdAt && createdAt !== '');
    return (
      <React.Fragment>
        <MetaTags tags={getEbookMetaTags(ebook)} />
        <ArticleMicrodata
          headline={name}
          image={imageLink || ''}
          datePublished={formatDate(createdAt, 'DD.MM.YYYY')}
          description={description}
          authorName={authorName}
        />
        <div className="container container--wide">
          <MainWrapper>
            <ContentWrapper>
              <CoverWrapper>
                {showAuthor && (
                  <EbookAuthorTab
                    createdAt={formatDate(createdAt, 'DD.MM.YYYY')}
                    authorImage={authorImage}
                    authorName={authorName}
                  />
                )}
                <EbookCover
                  downloadSize={downloadSize}
                  downloadLink={downloadLink}
                  imageLink={imageLink}
                />
              </CoverWrapper>
              <EbookDescription
                tags={tags}
                desc={description}
                name={name}
                body={body}
              />
            </ContentWrapper>

            {connectedEbooksSlides.length > 0 && (
              <SliderWrapper>
                <Also>{__('Prečítajte si tiež')}</Also>
                <SliderComponent type="ebook" slides={connectedEbooksSlides} />
              </SliderWrapper>
            )}
            <ShareWrapper title={name} desc={''} />
            <ToTopButton />
          </MainWrapper>
        </div>
      </React.Fragment>
    );
  }
}

const mapStateToProps = state => {
  return {
    defaultTitle: titleSelector(state),
    lang: langSelector(state),
    ebook: ebookDataSelector(state),
    isFetching: ebookIsFetchingSelector(state),
  };
};

export default connect(mapStateToProps)(
  connectSsr({ displayName: 'Ebook' })(Ebook),
);
