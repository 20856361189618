import * as actionTypes from './constants';

const initialState = {
  productDetail: {},
  isFetching: false,
  articles: {
    isFetching: false,
    articles: [],
    total: 0,
    offset: 0,
  },
};

export const productReducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.REQUEST_PRODUCT_DETAIL: {
      return {
        ...state,
        isFetching: true,
      };
    }

    case actionTypes.RECEIVE_PRODUCT_DETAIL_SUCCESS: {
      return {
        ...state,
        isFetching: false,
        productDetail: action.payload.productDetail,
      };
    }

    case actionTypes.RECEIVE_PRODUCT_DETAIL_ERROR:
      return {
        ...state,
        isFetching: false,
        error: action.payload.error,
      };

    case actionTypes.REQUEST_PRODUCT_DETAIL_ARTICLES: {
      return {
        ...state,
        articles: {
          ...state.articles,
          isFetching: true,
        },
      };
    }

    case actionTypes.RECEIVE_PRODUCT_DETAIL_ARTICLES_SUCCESS: {
      return {
        ...state,
        articles: {
          ...state.articles,
          isFetching: false,
          articles: action.payload.articles,
          total: action.payload.total,
        },
      };
    }

    case actionTypes.RECEIVE_PRODUCT_DETAIL_ARTICLES_ERROR:
      return {
        ...state,
        articles: {
          ...state.articles,
          isFetching: false,
          error: action.payload.error,
        },
      };

    default:
      return state;
  }
};
