import React, { useEffect } from 'react';
import SidebarArticles, { TitleText } from '../Article/SidebarArticles';
import { __ } from 'react-i18n';
import EbookSidebar, { FastNewsIcon } from './EbookSidebar';
import { prop } from 'eshop-defaults';
import {
  loadHomeMostRead,
  loadHomeRecommended,
} from '../../containers/Home/actions';

const SidebarArticlesWrapper = ({
  mostRead,
  recommended,
  fastNews,
  ebooks,
  mostReadFilter,
  recommendedFilter,
  dispatch,
  lang,
}) => {
  const handleFilterChange = (type, e) => {
    switch (type) {
      case 'mostRead':
        const mostReadFilterValue = prop(e, 'target.dataset.value');
        dispatch(loadHomeMostRead(mostReadFilterValue));
        break;
      case 'recommended':
        const recommendedValue = prop(e, 'target.dataset.value');
        dispatch(loadHomeRecommended(recommendedValue));
        break;

      default:
        break;
    }
  };

  const loadVideo = () => {
    const iframe = document.getElementsByClassName('latestVideoEmbed')[0];
    const reqURL =
      'https://api.rss2json.com/v1/api.json?rss_url=' +
      encodeURIComponent(
        'https://www.youtube.com/feeds/videos.xml?channel_id=',
      );

    if (iframe) {
      fetch(reqURL + 'UCxJwPK3N435C0rEStyF3Fgw')
        .then(res => res.json())
        .then((data: any) => {
          const videoNumber = 0;
          const link = data.items[videoNumber].link;
          const title = data.items[videoNumber].title;
          const id = link.substr(link.indexOf('=') + 1);
          iframe.setAttribute(
            'src',
            'https://youtube.com/embed/' + id + '?controls=0&autoplay=0',
          );
          if (
            iframe &&
            iframe.parentElement &&
            iframe.parentElement.querySelector('#video-title')
          ) {
            (iframe.parentElement.querySelector(
              '#video-title',
            ) as any).innerText = title;
          }
        });
    }
  };

  useEffect(() => {
    loadVideo();
  }, []);

  return (
    <>
      {prop(mostRead, 'mostReadArticles.articles', []) &&
        prop(mostRead, 'mostReadArticles.articles', []).length > 0 && (
          <SidebarArticles
            lang={lang}
            title={<TitleText>{__('NAJČÍTANEJŠIE')}</TitleText>}
            isFetching={prop(mostRead, 'isFetching', false)}
            articles={prop(mostRead, 'mostReadArticles.articles', [])}
            value={mostRead.filter || mostReadFilter[0].value}
            // tslint:disable-next-line:jsx-no-lambda
            onChange={e => handleFilterChange('mostRead', e)}
            options={mostReadFilter}
          />
        )}
      <iframe
        className="latestVideoEmbed"
        width="100%"
        height="200"
        frameBorder="0"
        allowFullScreen={true}
        style={{ marginBottom: '32px' }}
      />
      <iframe
        src="https://open.spotify.com/embed/show/4mw2KXMVsyKQz1iMer2hTW"
        width="100%"
        height="152"
        frameBorder="0"
        allowTransparency={true}
        allow="encrypted-media"
        title="spotifyEmbed"
        style={{ marginBottom: '32px' }}
      />
      {prop(recommended, 'recommendedArticles.articles', []) &&
        prop(recommended, 'recommendedArticles.articles', []).length > 0 && (
          <SidebarArticles
            lang={lang}
            title={<TitleText>{__('ODPORÚČAME')}</TitleText>}
            isFetching={prop(recommended, 'isFetching', false)}
            articles={prop(recommended, 'recommendedArticles.articles', [])}
            value={
              recommended.filter ||
              (recommendedFilter[0] && recommendedFilter[0].value)
            }
            // tslint:disable-next-line:jsx-no-lambda
            onChange={e => handleFilterChange('recommended', e)}
            options={recommendedFilter}
          />
        )}
      {prop(fastNews, 'fastNewsArticles.articles', []) &&
        prop(fastNews, 'fastNewsArticles.articles', []).length > 0 && (
          <SidebarArticles
            lang={lang}
            title={
              <TitleText>
                <FastNewsIcon src="/images/blog_assets/flash.svg" />
                {__('Bleskovky')}
              </TitleText>
            }
            isFetching={prop(fastNews, '.isFetching', false)}
            articles={prop(fastNews, 'fastNewsArticles.articles', [])}
          />
        )}
      <EbookSidebar
        title={<TitleText>{__('E-booky')}</TitleText>}
        isFetching={ebooks.isFetching}
        ebooks={ebooks.ebooksData}
      />
    </>
  );
};

export default SidebarArticlesWrapper;
