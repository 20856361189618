import React, { useState } from 'react';
import styled from 'styled-components';
import { FlexRowCenterVertical, FlexCol, FlexRowCenter } from 'eshop-defaults';
import { rem } from 'polished';
import { Link } from 'react-router';
import OutsideClick from '../_helpers/Default/OutsideClick';
import ArticleTags from '../Article/ArticleTags';
import { __ } from 'react-i18n';
import NavMenuScrollingDropdown from './NavMenuScrollingDropdown';

const TagsWrapper = styled.div`
  display: none;
  width: 100%;
  left: 0;
  position: absolute;
  margin-top: ${({ marginTop }) => rem(marginTop)};
  background-color: ${({ theme }) => theme.blogColors.primary};
  z-index: 500;
  padding: ${rem(52)} 0;
  padding-left: 32px;
  border-top: ${rem(1)} solid ${({ theme }) => theme.blogColors.darkGray};
`;

const Wrapper = styled(FlexRowCenterVertical)`
  border-top: ${rem(1)} solid ${({ theme }) => theme.blogColors.darkGray};
  padding: 0 ${rem(32)};
  justify-content: space-between;
  position: sticky;
  z-index: 400;
  background-color: ${({ theme }) => theme.blogColors.primary};

  ${({ theme }) => theme.mediab.l1150`
   display: none;
  `}
`;

const SearchIconWrapper = styled(FlexRowCenter)`
  padding: ${rem(24)};
  padding-right: 0;
  background-color: ${({ theme }) => theme.blogColors.primary};
  cursor: pointer;

  &:hover {
    opacity: 0.8;
  }
`;

const SearchIcon = styled.img`
  width: ${rem(24)};
  height: ${rem(24)};
  max-width: initial;
`;

const Nav = styled(FlexRowCenterVertical)`
  width: 100%;
`;

const NavWrapper = styled(FlexRowCenterVertical)`
  width: 100%;
  flex-flow: row wrap;

  &:not(:hover) div a {
    color: white;
  }
`;

const HomeIconWrapper = styled(Link)`
  display: flex;
  justify-content: center;
  align-items: center;
  text-decoration: none;
  padding: ${rem(24)};
  background-color: ${({ theme }) => theme.blogColors.secondary};
  cursor: pointer;
  z-index: 600;

  &:hover {
    opacity: 0.8;
  }
`;

const HomeIcon = styled.img`
  width: ${rem(24)};
  height: ${rem(24)};
  max-width: initial;
`;

const BackIcon = styled.img`
  width: ${rem(24)};
  height: ${rem(24)};
  margin-right: ${rem(8)};
  margin-left: ${rem(24)};
`;

const NavItem = styled.div`
  /* padding: ${rem(24)}; */
  font-size: ${rem(20)};
  font-weight: 500;
  color: gray;
  cursor: pointer;
  text-decoration: none;
  line-height: 24px;

  &:hover a {
    color: white;
  }

  &:hover div {
    display: flex;
  }

  &:hover ~ & a {
    color: gray;
  }

  &:hover ~ .tags {
    display: block;
  }

  &.noHover {
    &:hover div {
      display: none;
    }
  }

  @media only screen and (max-width: 1300px) {
    margin-bottom: ${rem(16)};
  }
`;

const ScrollingNavItem = styled.div`
  /* padding: ${rem(24)}; */
  font-size: ${rem(20)};
  font-weight: 500;
  color: gray;
  cursor: pointer;
  text-decoration: none;

  &:hover a {
    color: white;
  }

  &:hover ~ .scrollingDropdown {
    display: flex;
  }

  &.noHover ~ .nav-menu-scrolling-dropdown {
      display: none;
  }
`;

const StyledLink = styled(Link)`
  text-decoration: none;
  color: gray;
  padding: ${rem(24)};

  color: ${({ isactive, theme }) =>
    isactive ? `${theme.blogColors.secondary} !important` : 'gray'};
`;

const StyledA = styled.a`
  text-decoration: none;
  color: gray;
  padding: ${rem(24)};

  color: ${({ isactive, theme }) =>
    isactive ? `${theme.blogColors.secondary} !important` : 'gray'};
`;

// const SubNavItems = styled.div`
//   display: none;
// `;

const ItemsList = styled(FlexCol)`
  display: none;
  margin-top: ${rem(32)};
  /* width: 100%; */
  position: absolute;
  /* left: 0px; */
  z-index: 400;
  padding: ${rem(24)} ${rem(32)};
  margin: ${rem(22)} 0;
  padding-left: 9999px;
  margin-left: -10000px;
  padding-right: 9999px;
  margin-right: -10000px;
  margin-top: 0;

  background-color: ${({ theme }) => theme.blogColors.primary};
`;

const SubNavItemsList = styled(ItemsList)`
  display: block;
`;

const SubNavItem = styled(FlexRowCenterVertical)`
  color: white;
  margin-left: ${rem(24)};
  margin-bottom: ${rem(16)};
  max-width: ${rem(400)};
  justify-content: space-between;
  line-height: 24px;

  &:hover a {
    color: ${({ theme }) => theme.blogColors.secondary};
  }
`;

const SubNavItemLink = styled(Link)`
  color: white;
  font-size: ${rem(18)};
  font-weight: 400;
  text-decoration: none;
`;

const RightArrowIcon = styled.img`
  width: ${rem(16)};
  height: ${rem(16)};
  margin-left: ${rem(96)};
`;

const SubNavTagsWrapper = styled(TagsWrapper)`
  display: block;
  margin-top: ${({ marginTop }) => rem(marginTop)};
`;

function NavMenu({
  navItems,
  brands,
  toggleResponsiveSearchForm,
  isSearchVisible,
  pathname,
  lang,
}) {
  const [navSubItem, setnavSubItem] = useState<any>({
    name: '',
    children: [],
  });
  const [noHoverItem, setNoHoverItem] = useState<any>(-1);

  const showItemsInSubNav = (name, children) => {
    setnavSubItem({ name, children, prevState: navSubItem });
  };

  const hideItemsInSubNav = () => {
    setnavSubItem(navSubItem.prevState);
  };

  const hideToBasicState = () => {
    setnavSubItem({
      name: '',
      children: [],
    });
  };

  const makeItemNoHover = i => {
    setNoHoverItem(i);
  };

  const makeItemNoHoverForTime = i => {
    setNoHoverItem(i);
    setTimeout(() => {
      setNoHoverItem(-1);
    }, 500);
  };

  const handleOnMouseLeave = () => {
    setNoHoverItem(-1);
  };

  return (
    <Wrapper>
      <Nav>
        <HomeIconWrapper to="/">
          <HomeIcon src="/images/blog_assets/home.svg" alt="home-icon" />
        </HomeIconWrapper>
        <NavWrapper>
          {navSubItem.name !== '' && (
            <OutsideClick handleFunction={hideItemsInSubNav}>
              <NavItem key={navSubItem.name}>
                <BackIcon
                  onClick={hideItemsInSubNav}
                  src="/images/blog_assets/arrow_back_white.svg"
                  alt=""
                />
                {navSubItem.children && navSubItem.children.length > 0 && (
                  <SubNavTagsWrapper
                    marginTop={
                      navSubItem.children.length < 3
                        ? 70 + navSubItem.children.length * 25
                        : 100 + navSubItem.children.length * 25
                    }
                  >
                    <ArticleTags
                      topArticle={true}
                      isNav={true}
                      tags={[
                        { name: 'JBL', id: 1020 },
                        { name: 'Bluetooth', id: 1015 },
                        { name: 'Reproduktor', id: 1161 },
                      ]}
                      afterTagClick={hideToBasicState}
                    />
                  </SubNavTagsWrapper>
                )}
                <StyledLink
                  onClick={hideToBasicState}
                  to={`/${navSubItem.url}`}
                >
                  {navSubItem.name}
                </StyledLink>
                {navSubItem.children && navSubItem.children.length > 0 && (
                  <SubNavItemsList>
                    {navSubItem.children.map(childrenItem => (
                      <SubNavItem key={childrenItem.name}>
                        <SubNavItemLink
                          onClick={hideToBasicState}
                          to={`/${childrenItem.url}`}
                        >
                          {childrenItem.name}
                        </SubNavItemLink>
                        {childrenItem.children &&
                          childrenItem.children.length > 0 && (
                            <RightArrowIcon
                              // tslint:disable-next-line:jsx-no-lambda
                              onClick={() =>
                                showItemsInSubNav(
                                  childrenItem.name,
                                  childrenItem.children,
                                )
                              }
                              src="/images/blog_assets/right-arrow-white.svg"
                              alt=""
                            />
                          )}
                      </SubNavItem>
                    ))}
                  </SubNavItemsList>
                )}
              </NavItem>
            </OutsideClick>
          )}
          {/* {navSubItem.name === '' && (
            <NavItem
              className={noHoverItem === 0 ? 'noHover' : ''}
              onMouseLeave={handleOnMouseLeave}
            >
              <StyledLink
                // tslint:disable-next-line:jsx-no-lambda
                onClick={() => makeItemNoHover(0)}
                isactive={
                  pathname === `bleskovky` || pathname === `/bleskovky`
                    ? true
                    : undefined
                }
                to={`/bleskovky`}
              >
                {__('Bleskovky')}
              </StyledLink>
            </NavItem>
          )} */}
          {navSubItem.name === '' &&
            navItems &&
            navItems.map((item, i) => (
              <NavItem
                key={item.name}
                className={noHoverItem === i ? 'noHover' : ''}
                onMouseLeave={handleOnMouseLeave}
              >
                {item.link ? (
                  <StyledA
                    href={item.link}
                    rel="noopener noreferrer"
                    target="_blank"
                  >
                    {item.name}
                  </StyledA>
                ) : (
                  <StyledLink
                    // tslint:disable-next-line:jsx-no-lambda
                    onClick={() => makeItemNoHover(i)}
                    isactive={
                      pathname === `${item.url}` || pathname === `/${item.url}`
                        ? true
                        : undefined
                    }
                    to={`/${item.url}`}
                  >
                    {item.name}
                  </StyledLink>
                )}
                {item.children && item.children.length > 0 && (
                  <>
                    <ItemsList>
                      {item.children.map(childrenItem => (
                        <SubNavItem
                          key={`${childrenItem.name}-${childrenItem.url}`}
                        >
                          <SubNavItemLink
                            // tslint:disable-next-line:jsx-no-lambda
                            onClick={() => makeItemNoHover(i)}
                            to={`/${childrenItem.url}`}
                          >
                            {childrenItem.name}
                          </SubNavItemLink>
                          {childrenItem.children &&
                            childrenItem.children.length > 0 && (
                              <RightArrowIcon
                                // tslint:disable-next-line:jsx-no-lambda
                                onClick={() =>
                                  showItemsInSubNav(
                                    childrenItem.name,
                                    childrenItem.children,
                                  )
                                }
                                src="/images/blog_assets/right-arrow-white.svg"
                                alt=""
                              />
                            )}
                        </SubNavItem>
                      ))}
                    </ItemsList>
                    <TagsWrapper
                      className="tags"
                      marginTop={
                        item.children.length < 3
                          ? 70 + item.children.length * 25
                          : 100 + item.children.length * 25
                      }
                    >
                      <ArticleTags
                        topArticle={true}
                        isNav={true}
                        tags={[
                          { name: 'JBL', id: 1020 },
                          { name: 'Bluetooth', id: 1015 },
                          { name: 'Reproduktor', id: 1161 },
                        ]}
                        // tslint:disable-next-line:jsx-no-lambda
                        afterTagClick={() => makeItemNoHover(i)}
                      />
                    </TagsWrapper>
                  </>
                )}
              </NavItem>
            ))}
          {navSubItem.name === '' && (
            <NavItem
              className={noHoverItem === 0 ? 'noHover' : ''}
              onMouseLeave={handleOnMouseLeave}
            >
              <StyledLink
                // tslint:disable-next-line:jsx-no-lambda
                onClick={() => makeItemNoHover(0)}
                isactive={
                  pathname === `ebooky` || pathname === `/ebooky`
                    ? true
                    : undefined
                }
                to={`/ebooky`}
              >
                {__('Ebooky')}
              </StyledLink>
            </NavItem>
          )}
          {/* {brands && brands.length > 0 && navSubItem.name === '' && (
            <>
              <ScrollingNavItem
                key={'znacky'}
                className={noHoverItem === navItems.length ? 'noHover' : ''}
                onMouseLeave={handleOnMouseLeave}
              >
                <StyledLink
                  // tslint:disable-next-line:jsx-no-lambda
                  onClick={() => makeItemNoHover(navItems.length)}
                  isactive={
                    pathname === 'znacky' || pathname === '/znacky'
                      ? true
                      : undefined
                  }
                  to={'/znacky'}
                >
                  {__('Značky')}
                </StyledLink>
              </ScrollingNavItem>
              <NavMenuScrollingDropdown
                className="nav-menu-scrolling-dropdown"
                items={brands}
                lang={lang}
                makeItemNoHover={makeItemNoHoverForTime}
                brandItemNumber={navItems.length}
              />
            </>
          )} */}
        </NavWrapper>
      </Nav>
      <SearchIconWrapper onMouseDown={toggleResponsiveSearchForm}>
        <SearchIcon
          src={
            isSearchVisible
              ? '/images/blog_assets/close_white.svg'
              : '/images/blog_assets/search_white.svg'
          }
          alt=""
        />
      </SearchIconWrapper>
      {/* {isSearchVisible && (
        <SearchForm
          isFetching={false}
          searchTerm={'a'}
          searchResults={[]}
          // tslint:disable-next-line:jsx-no-lambda
          redirectToSearchResultPage={() => {
            console.log('redirect');
          }}
          // tslint:disable-next-line:jsx-no-lambda
          onSearchTermChange={() => {
            console.log('term change');
          }}
        />
      )} */}
    </Wrapper>
  );
}

export default NavMenu;
