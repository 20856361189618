import * as React from 'react';
import { __ } from 'react-i18n/lib';
import GalleryGrid from './GalleryGrid';
import ReadTooArticle from './ReadTooArticle';
import ParallaxImage from './ParallaxImage';
import { replaceMatchesInBody } from '../../utilities/article';
import styled from '../../theme/styled-components';
import ImageGallery from './ImageGallery';
import { getImagePath } from '../../utilities/product';
import Modal from '../../components/_helpers/Default/Modal';
import { rem } from 'polished';
import LightBoxImage from './LightBoxImage';

// imports function for spliting html to parts by tag
// tslint:disable-next-line:no-var-requires
require('split-html');

const CmsContent = styled.div`
  line-height: 21px;
  font-family: ${({ theme }) => theme.blogFonts.primaryFont};
  font-size: ${rem(18)};
  color: ${({ theme }) => theme.blogColors.primary};

  line-height: 26px;

  & img {
    margin: ${rem(24)} 0;
  }

  & strong {
    line-height: 32px;
    /* margin: ${rem(16)} 0; */
    display: inline-block;
  }

  & li strong {
    margin: 0;
  }

  & li {
    margin-bottom: ${rem(8)};
  }

  & p {
    line-height: 26px;
    font-family: ${({ theme }) => theme.blogFonts.primaryFont};
    font-size: ${rem(18)};
    color: ${({ theme }) => theme.blogColors.primary};
  }
`;

interface Props {
  inputBody: string;
  type?: string;
  articleTitle: string;
}

declare global {
  interface Window {
    splitHtml: any;
  }
}

const RenderedBody: React.SFC<Props> = ({ inputBody, type, articleTitle }) => {
  const [isOpen, setIsOpen] = React.useState(false);
  const [photoIndex, setPhotoIndex] = React.useState(0);
  const [galleryPhotos, setGalleryPhotos] = React.useState([]);

  const removeEmptyTags = body => {
    return body
      .replace(/<parallax> <\/parallax>/g, '')
      .replace(/<photogallery> <\/photogallery>/g, '')
      .replace(/<readtoo> <\/readtoo>/g, '');
  };

  const createBodyArray = body => {
    body = removeEmptyTags(body);

    let newBodyArray = [];

    if (window && window.splitHtml && body[0] === '<') {
      // img regex - \<img ([\s\S]*?data\-parallax[\s\S]*?) \/>
      let newBody = body;
      // const allParallaxMatchesRegex = /\[PARALLAX([\s\S]*?)\]/g;
      const allParallaxMatchesRegex = /\<parallax>([\s\S]*?)<\/parallax>/g;
      newBody = replaceMatchesInBody(
        newBody,
        allParallaxMatchesRegex,
        'parallax',
        [
          { matchValueId: 'data-src', newValueId: 'src' },
          { matchValueId: 'data-alt', newValueId: 'alt' },
          { matchValueId: 'data-fullwidth', newValueId: 'fullwidth' },
        ],
      );
      // const allReadtooMatchesRegex = /\[READTOO([\s\S]*?)\]/g;
      const allReadtooMatchesRegex = /\<readtoo>([\s\S]*?)<\/readtoo>/g;
      newBody = replaceMatchesInBody(
        newBody,
        allReadtooMatchesRegex,
        'readtoo',
        [{ matchValueId: 'data-id', newValueId: 'id' }],
      );

      const allPhotogalleryMatchesRegex = /\<photogallery>([\s\S]*?)<\/photogallery>/g;
      newBody = replaceMatchesInBody(
        newBody,
        allPhotogalleryMatchesRegex,
        'photogallery',
        [
          { matchValueId: 'data-images', newValueId: 'images' },
          { matchValueId: 'data-columns', newValueId: 'columns' },
        ],
      );

      const lightBoxRegex = /<a[^>]*class="[^"]*is-lightbox[^"]*"[^>]*>([\s\S]*?)<\/a>/g;
      newBody = replaceMatchesInBody(
        newBody,
        lightBoxRegex,
        'lightbox class="lightbox"',
        [{ matchValueId: 'href', newValueId: 'image-src' }],
      );

      const htmlArray = (window as any).splitHtml(newBody, 'parallax');
      const newReadtooHtmlArray: any = [];
      if (newBody.includes('<readtoo')) {
        htmlArray.map((html, i) => {
          if (html.includes('<readtoo')) {
            const newArray = (window as any).splitHtml(html, 'readtoo');
            newArray.map(a => newReadtooHtmlArray.push(a));
          } else {
            newReadtooHtmlArray.push(html);
          }
        });
      } else {
        htmlArray.map((html, i) => {
          newReadtooHtmlArray.push(html);
        });
      }

      newBodyArray = newReadtooHtmlArray;

      const newPhotogalleryHtmlArray: any = [];
      if (newBody.includes('<photogallery') && newBodyArray) {
        newBodyArray.map((html: string, i) => {
          if (html.includes('<photogallery')) {
            const newArray = (window as any).splitHtml(html, 'photogallery');
            newArray.map(a => newPhotogalleryHtmlArray.push(a));
          } else {
            newPhotogalleryHtmlArray.push(html);
          }
        });
      } else {
        newBodyArray.map((html, i) => {
          newPhotogalleryHtmlArray.push(html);
        });
      }
      newBodyArray = newPhotogalleryHtmlArray;

      const newLightBoxArray: any = [];
      if (newBody.includes('<lightbox class="lightbox"') && newBodyArray) {
        newBodyArray.map((html: string, i) => {
          if (html.includes('<lightbox class="lightbox"')) {
            const newArray = (window as any).splitHtml(html, 'lightbox');
            newArray.map(a => newLightBoxArray.push(a));
          } else {
            newLightBoxArray.push(html);
          }
        });
      } else {
        newBodyArray.map((html, i) => {
          newLightBoxArray.push(html);
        });
      }

      newBodyArray = newLightBoxArray;
    }

    return newBodyArray;
  };

  const renderContent = body => {
    body = body.replace(new RegExp(' alt ', 'g'), ` alt="${articleTitle}" `);
    const newBodyArray = createBodyArray(body);

    return (
      <>
        {newBodyArray && newBodyArray.length > 0 ? (
          newBodyArray.map((part: any, i) => {
            if (part.includes('<parallax')) {
              const srcSplit = part.split('src="');
              let source = '';
              if (part && srcSplit.length > 1) {
                source = srcSplit[1].split('"')[0];
              }

              const fullwidthSplit = part.split('fullwidth="');
              let fullWidth = false;

              if (
                part &&
                srcSplit.length > 1 &&
                type !== 'ebook' &&
                fullwidthSplit.length > 1
              ) {
                fullWidth =
                  fullwidthSplit[1].split('"')[0] === 'true' ? true : false;
              }
              return <ParallaxImage fullwidth={fullWidth} src={source} />;
            }
            if (part.includes('<readtoo')) {
              const srcSplit = part.split('id="');
              let id = '';
              if (part && srcSplit.length > 1) {
                id = srcSplit[1].split('"')[0];
              }
              return <ReadTooArticle id={id} />;
            }

            if (part.includes('<photogallery')) {
              const gridSplit = part.split('columns="');
              let columns = 0;
              if (gridSplit.length > 1) {
                columns = gridSplit[1].split('"')[0];
              }

              const imagesSplit = part.split('images="');
              let imagesIds;
              if (imagesSplit.length > 1) {
                imagesIds = imagesSplit[1].split('"')[0].split(',');
              }
              return (
                <GalleryGrid
                  articleTitle={articleTitle}
                  type={type}
                  openModal={openModal}
                  columns={columns}
                  imagesIds={imagesIds}
                />
              );
            }

            if (part.includes('<lightbox')) {
              const regex = /image-src="([^"]+)"/;
              const match = part.match(regex);

              if (match) {
                const imageSrc = match[1];
                return <LightBoxImage src={imageSrc} openModal={openModal} />;
              }
            }

            return (
              <CmsContent key={i} dangerouslySetInnerHTML={{ __html: part }} />
            );
          })
        ) : (
          <CmsContent dangerouslySetInnerHTML={{ __html: body }} />
        )}
      </>
    );
  };

  const openModal = (array, index) => {
    const fullscreenImages = array.map(doc => {
      const regex = /query=([^&]+)/;
      const match = doc.document_path.match(regex);
      if (match) {
        const query = match[1];
        doc.document_path = query;
      }

      if (doc.document_path.startsWith('http')) {
        return {
          original: doc.document_path,
          originalWebP: doc.document_path,
          thumbnail: doc.document_path,
          thumbnailWebP: doc.document_path,
          name:
            (doc.document_description && doc.document_description !== '') ||
            doc.document_name ||
            '',
        };
      } else {
        const img = getImagePath(doc.document_path, {
          width: 800,
          height: 600,
        });
        const imgWebP = getImagePath(
          doc.document_path,
          {
            width: 800,
            height: 600,
          },
          false,
          true,
        );

        const thumbImg = getImagePath(doc.document_path, {
          width: 87,
          height: 104,
        });

        const thumbImgWebP = getImagePath(
          doc.document_path,
          {
            width: 87,
            height: 104,
          },
          false,
          true,
        );

        return {
          original: img,
          originalWebP: imgWebP,
          thumbnail: thumbImg,
          thumbnailWebP: thumbImgWebP,
          name:
            (doc.document_description && doc.document_description !== '') ||
            doc.document_name ||
            '',
        };
      }
    });

    setIsOpen(true);
    setPhotoIndex(index);
    setGalleryPhotos(fullscreenImages);
  };

  const closeModal = () => {
    setIsOpen(false);
  };

  const setCurrentImage = i => {
    setPhotoIndex(i);
  };

  return (
    <>
      {' '}
      {isOpen && (
        <Modal size="big" showX={false} closeModal={closeModal}>
          <ImageGallery
            closeModal={closeModal}
            setCurrentImage={setCurrentImage}
            items={galleryPhotos}
            currentIndex={photoIndex}
          />
        </Modal>
      )}
      {renderContent(inputBody)}
    </>
  );
};

export default RenderedBody;
