import { rem, rgba } from 'polished';
import { transition, transitions } from './libs/transition';
import { media } from './libs/media';
import { css } from 'styled-components';

const blogColors = {
  primary: '#414141',
  secondary: '#FFD607',

  darkGray: '#606060',
  gray: '#AAAAAA',
  lightGray: '#000000',
  tagBg: '#F2F2F2',
  white: '#FFFFFF',
  black: '#000000',
  border: '#DDDDDD',
  link: '#337BCC',
};

const blogFonts = {
  primaryFont: `Barlow, sans-serif;`,
  secondaryFont: `omnes-pro, sans-serif`,
};

const blogShadows = {
  default: `0 ${rem(1)} ${rem(2)} ${rgba(blogColors.black, 0.16)}`,
};

const colors = {
  black: '#000000',
  white: '#FFFFFF',
  gray: '#CCC',
  lightGray: '#EEE',
  textPrimary: '#3e3e3e',
  primary: '#FF4464',
  secondary: '#FFC957',
  secondaryTransparent: 'rgba(255,201,87, 0.1)',
  base: '#F2EEE8',
  loaderPrimary: '#3E3E3E',
  loaderSecondary: '#d4cdc3',
  inactiveFontColor: '#aaaaaa',
  fontHighlightColor: '#EE227B',
  primaryFontColor: '#3E3E3E',
  secondaryFontColor: '#3E3E3E',
  menuCartTextColor: '#555555',
  borderColor: '#e3e3e3',
  dotsColor: '#e3e3e3',
  activeDotColor: '#FFC957',
  sliderRailColor: '#eaeaea',
  successColor: '#9CC381',
  dangerColor: '#FF4464',
  red: '#FF0000',
  fuscousGray: '#514E49',
  separatorColor: '#eee',
  alto: '#dddddd',
};

const colorsNew = {
  white: '#FFFFFF',
  alabaster: '#F9F9F9',
  gallery: '#EFEFEF', // '#EEEEEE'
  alto: '#DDDDDD',
  silver: '#CCCCCC',
  silverChalice: '#AAAAAA',
  dustyGray: '#999999',
  boulder: '#777777',
  doveGray: '#737373',
  scorpion: '#5E5E5E',
  tundora: '#484848',
  mineShaft: '#282828',
  black: '#000000',
  red: '#FF0000',
  alizarinCrimson: '#E82933',
  bananaMania: '#FCF0BE',
  salomie: '#FFE88D',
  saffron: '#F6D33F',
  supernova: '#F4C500',
  yellow: '#FFFF00',
  jungleGreen: '#26B25E',
  mountainMeadow: '#18B35A',
  java: '#1DC5BA',
  cyan: '#00FFFF',
  mariner: '#337BCC',
  magenta: '#FF00FF',
  galleryOpacity: rgba(0, 0, 0, 0.8),
  altoOpacity: rgba(0, 0, 0, 0.16),
  grayOpacity: rgba(0, 0, 0, 0.5),
  panacheOpacity: rgba(38, 178, 94, 10),
  marinerOpacity: rgba(51, 123, 204, 0.1),
  jungleGreenOpacity: rgba(38, 178, 94, 0.1),
  whiteOpacity5: rgba(255, 255, 255, 0.05),
  whiteOpacity10: rgba(255, 255, 255, 0.1),
  whiteOpacity35: rgba(255, 255, 255, 0.35),
};

const font = {
  primary: `'SegoeUI', sans-serif`,
  secondary: `Dosis, sans-serif`,
};

const color = {
  darkerLink: ' #005bc1',
  white: colorsNew.white,
  whiteOpacity10: colorsNew.whiteOpacity10,
  gray97: colorsNew.alabaster,
  gray86: colorsNew.alto,
  gray80: colorsNew.silver,
  gray66: colorsNew.silverChalice,
  gray36: colorsNew.scorpion,
  black: colorsNew.black,
  background: colorsNew.gallery,
  backgroundDark: colorsNew.tundora,
  text: colorsNew.mineShaft,
  textSecondary: colorsNew.dustyGray,
  link: colorsNew.mariner,
  linkOpacity: colorsNew.marinerOpacity,
  brand: colorsNew.alizarinCrimson,
  success: colorsNew.jungleGreen,
  error: colorsNew.alizarinCrimson,
  warning: colorsNew.supernova,
  tag: colorsNew.java,
  alert: {
    warning: {
      background: colorsNew.bananaMania,
      border: colorsNew.saffron,
    },
  },
  gift: {
    background: colorsNew.jungleGreenOpacity,
    icon: colorsNew.jungleGreen,
  },
  printer: {
    background: colorsNew.marinerOpacity,
    color: colorsNew.mariner,
    backgroundSuccess: colorsNew.jungleGreenOpacity,
    colorSuccess: colorsNew.jungleGreen,
  },
  toner: {
    c: colorsNew.cyan,
    m: colorsNew.magenta,
    y: colorsNew.yellow,
  },
  login: {
    facebook: '#3B5998',
    google: '#DD4B39',
  },
  danger: colors.red,
};

const boxShadow = {
  default: `0 ${rem(1)} ${rem(3)} ${rgba(color.black, 0.08)}`,
  success: `0 0 ${rem(8)} ${rgba(color.success, 1)}`,
};

const header = {
  border: `${rem(1)} solid ${rgba(colorsNew.silver, 0.25)}`,
  colors: {
    icon: colorsNew.doveGray,
    iconLoggedIn: colorsNew.mountainMeadow,
    shippingIcon: colorsNew.whiteOpacity35,
  },
};

const footer = {
  border: header.border,
  colors: {
    icon: colorsNew.dustyGray,
    iconBg: colorsNew.whiteOpacity5,
  },
};

const formWithIcon = {
  color: {
    icon: colorsNew.silver,
    placeholder: colorsNew.dustyGray,
  },
};

const grid = {
  padding: 40,
  container: {
    base: 960,
    wide: 1440,
  },
  col: {
    col1: (1 / 12) * 100 + '%',
    col2: (2 / 12) * 100 + '%',
    col3: (3 / 12) * 100 + '%',
    col4: (4 / 12) * 100 + '%',
    col5: (5 / 12) * 100 + '%',
    col6: (6 / 12) * 100 + '%',
    col7: (7 / 12) * 100 + '%',
    col8: (8 / 12) * 100 + '%',
    col9: (9 / 12) * 100 + '%',
    col10: (10 / 12) * 100 + '%',
    col11: (11 / 12) * 100 + '%',
    col12: (12 / 12) * 100 + '%',
  },
};

const loader = {
  color: {
    primary: color.brand,
  },
};

const filter = {
  color: {
    icon: colorsNew.boulder,
  },
};

const breakpoints = {
  l1620: 1620,
  l1150: 1150,
  l1050: 1050,
  l925: 925,
  m810: 810,
  m760: 760,
  m580: 580,
  s450: 450,
};

const mediab = {
  l1620: (arg, ...args) => css`
    @media (max-width: ${breakpoints.l1620}px) {
      ${css(arg, ...args)}
    }
  `,
  l1150: (arg, ...args) => css`
    @media (max-width: ${breakpoints.l1150}px) {
      ${css(arg, ...args)}
    }
  `,
  l1050: (arg, ...args) => css`
    @media (max-width: ${breakpoints.l1050}px) {
      ${css(arg, ...args)}
    }
  `,
  l925: (arg, ...args) => css`
    @media (max-width: ${breakpoints.l925}px) {
      ${css(arg, ...args)}
    }
  `,
  m810: (arg, ...args) => css`
    @media (max-width: ${breakpoints.m810}px) {
      ${css(arg, ...args)}
    }
  `,
  m760: (arg, ...args) => css`
    @media (max-width: ${breakpoints.m760}px) {
      ${css(arg, ...args)}
    }
  `,
  m580: (arg, ...args) => css`
    @media (max-width: ${breakpoints.m580}px) {
      ${css(arg, ...args)}
    }
  `,
  s450: (arg, ...args) => css`
    @media (max-width: ${breakpoints.s450}px) {
      ${css(arg, ...args)}
    }
  `,
};

const infoBar = {
  activeCircleColor: '#514E49',
  inActiveCircleColor: '#F2EEE8',
};

const pagination = {
  backgroundActive: colors.fuscousGray,
};

const tags = {
  freeDelivery: '#9CC381',
  discount: '#FFC957',
  new: '#9CC381',
  sale: '#FF4464',
  saleOut: '#FFC957',
};

const button = {
  background: colors.primary,
  borderRadius: 4,
};

const mobileNavItem = {
  fontSize: '21px',
  lineHeight: '1.4',
  padding: '15px 0',
  border: '1px dashed colors.borderColor',
  arrow: {
    width: '8px',
    height: '5px',
  },
};

const box = {
  mainBorder: `${rem(1)} solid ${colors.separatorColor}`,
  headerBorder: `${rem(1)} solid ${colors.separatorColor}`,
  iconBorder: `${rem(1)} solid ${colors.separatorColor}`,
  padding: rem(20),
  boxTitle: colors.primaryFontColor,
  iconContentWidth: rem(60),
};

const myAccount = {
  leftSidebar: {
    background: rgba(colors.secondary, 0.15),
    padding: rem(20),
    width: rem(250),
    headerSpacing: `0 0 ${rem(20)} 0`,
  },
  tab: {
    title: colors.primaryFontColor,
    textLeft: colors.primaryFontColor,
    textRight: colors.primaryFontColor,
  },
  orderItem: {
    imageHeight: rem(45),
    imageWidth: rem(45),
    titleColor: colors.primaryFontColor,
    padding: `${rem(10)} 0`,
  },
};

const footerDropdownTitle = {
  titleSizeMobile: '21px',
  titleColor: colors.textPrimary,
  lineHeight: '1.5',
  arrow: {
    width: '8px',
    height: '8px',
  },
};

const product = {
  onStockColor: 'rgba(156, 195, 129, 1)',
  notInStockColor: 'rgba(225, 201,87, 1)',
  activeThumbnailBorder: `${rem(1)} solid ${colors.primary}`,
};

const fonts = {
  primaryFont: `Source Sans Pro, sans-serif`,
  secondaryFont: `omnes-pro, sans-serif`,
};

const formElements = {
  border: {
    width: 1,
    color: colors.borderColor,
    radius: 4,
    colorOnFocus: colors.secondary,
  },
  font: fonts.primaryFont,
  gridItem: {
    margin: '0 -7.5px 60px -7.5px',
    padding: '0 7.5px',
    paddingMediaS: '0',
  },
  table: {
    rowHoverColor: rgba(colors.secondary, 0.15),
    border: `${rem(2)} solid ${rgba(colors.secondary, 0.5)}`,
    headerBorder: `${rem(2)} solid ${rgba(colors.secondary, 0.5)}`,
  },
};

const cart = {
  cartStep: {
    default: {
      textColor: '#dddddd',
      numberColor: '#dddddd',
      paddingBottom: '0',
      circleFontFamily: fonts.primaryFont,
      circleFontSize: rem(18),
      circleFontWeight: '600',
    },
    selected: {
      textColor: colors.textPrimary,
      numberColor: colors.secondary,
    },
    done: {
      textColor: colors.textPrimary,
      numberColor: '#81c277',
    },
  },
  button: {
    width: 275,
    height: 60,
    background: button.background,
    borderRadius: button.borderRadius,
  },
  totalPrice: {
    color: colors.primary,
    priceMarginLeft: 10,
    fontSize: 32,
    fontWeight: 600,
    top: rem(3),
    wrapperValign: 'flex-end',
  },
  cartMenuItem: {
    primary: '#ffc957',
    background: '#fef8ed',
  },
  cartItem: {
    imageContainer: {
      width: 40,
      height: 40,
      border: {
        width: 1,
        color: colors.borderColor,
        radius: 0,
      },
    },
    stockAvailabilityColor: colors.textPrimary,
  },
  itemCounter: {
    height: 50,
    border: {
      width: 1,
      style: 'solid',
      color: colors.borderColor,
    },
    counter: {
      width: 60,
    },
    buttons: {
      width: 40,
      leftBorderRadius: 4,
      rightborderRadius: 4,
    },
  },
  discountCard: {
    toggleButtonColor: colors.textPrimary,
    useButtonColor: colors.primary,
  },
  cartSummary: {
    borderTop: `${rem(1)} dashed ${colors.borderColor}`,
    borderBottom: `${rem(1)} dashed ${colors.borderColor}`,
  },
};

export const theme = {
  blogColors,
  blogFonts,
  blogShadows,
  color: {
    ...color,
  },
  boxShadow: {
    ...boxShadow,
  },
  font: {
    ...font,
  },
  grid: {
    ...grid,
  },
  loader: {
    ...loader,
  },
  header: {
    ...header,
  },
  footer: {
    ...footer,
  },
  formWithIcon: {
    ...formWithIcon,
  },
  filter: {
    ...filter,
  },
  primary: '#FFFFFF',
  secondary: '#F2FAFA',
  successColor: '#9CC381',
  warningColor: colors.secondary,
  dangerColor: '#D24D57',
  btnPrimary: colors.primary,
  btnSecondary: colors.base,
  btnSecondaryTextColor: colors.black,
  separatorColor: colors.borderColor,
  borderRadius: rem(4),
  borderSolid: `${rem(1)} solid ${colors.borderColor}`,
  borderDashed: `${rem(1)} dashed ${colors.borderColor}`,
  box,
  black: '#000000',
  fontSizeXXS: rem(12),
  fontSizeXS: rem(14),
  fontSizeS: rem(16),
  fontSizeM: rem(18),
  fontSizeL: rem(24),
  fontSizeXL: rem(32),
  fonts: {
    primaryFont: `Source Sans Pro, sans-serif`,
    secondaryFont: `omnes-pro, sans-serif`,
  },
  index: {
    default: 0,
    categoryOverlay: 100,
    priorityContent: 200,
    priorityContentShadow: 199,
    overlay: 300,
    menu: 400,
    tooltip: 450,
    modalShadow: 500,
    modal: 600,
    max: 999999,
  },
  myAccount,
  button,
  pagination,
  mobileNavItem,
  footerDropdownTitle,
  cart,
  infoBar,
  colors,
  tags,
  product,
  mainContainerSize: '1167px',
  formElements,
  media,
  mediab,
  transition,
  transitions,
};

export interface ThemeInterface {
  any;
}
