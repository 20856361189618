import * as React from 'react';
import { connectSsr } from 'ssr-service';
import { connect } from 'react-redux';
import styled from 'styled-components';
import { __ } from 'react-i18n/lib';
import { rem } from 'polished';
import { Link, withRouter, WithRouterProps } from 'react-router';
import { FlexRowCenter, FlexCol } from 'eshop-defaults';
import NotFoundPageArticles from '../../components/Article/NotFoundPageArticles';
import { loadHomeArticles } from '../Home/actions';
import { homeArticlesSelector } from '../Home/selectors';
import { prop } from '../../utilities';
import API, { ThenArg } from '../../services/API';
import MetaTags from '../../components/MetaTags/MetaTags';
import { langSelector } from '../Category/selectors';
import { Loader } from '../../components/_helpers/Loader/Loader';

const Wrapper = styled.div`
  background-color: ${({ theme }) => theme.blogColors.primary};
  display: flex;
  justify-content: center;
  flex: 1 1 auto;
  ${({ theme }) => theme.mediab.m580`
     border-bottom: ${rem(1)} solid rgba(204,204,204,0.25);
  `};
`;

const WrapperContainer = styled.div`
  width: 100%;
  max-width: ${rem(1440)};
  padding: 0 ${rem(32)};

  ${({ theme }) => theme.mediab.m580`
    padding: 0 ;
  `};
`;

const TopWrapper = styled(FlexCol)`
  padding: ${rem(200)} 0;
  border-bottom: ${rem(1)} solid rgba(204, 204, 204, 0.25);
  margin-bottom: ${rem(48)};

  ${({ theme }) => theme.mediab.m580`
    padding: ${rem(70)} ${rem(6)};
    border: 0;
  `};
`;

const Text = styled.div`
  font-family: ${({ theme }) => theme.blogFonts.primaryFont};
  font-size: ${rem(72)};
  color: white;
  text-align: center;
  font-weight: 700;
  margin-bottom: ${rem(24)};
`;

const NotFoundWrapper = styled(FlexRowCenter)`
  text-align: center;
  margin-top: ${rem(16)};
  font-size: ${rem(36)};
  color: white;
  margin-bottom: ${rem(32)};

  ${({ theme }) => theme.mediab.m580`
    font-size: ${rem(20)};
  `}
`;

const Icon = styled.img`
  width: ${rem(60)};
  margin-left: ${rem(10)};

  ${({ theme }) => theme.mediab.m580`
     width: ${rem(30)};
  `}
`;

const HomeLink = styled(Link)`
  background-color: transparent;
  color: white;
  font-weight: 500;
  padding: ${rem(16)};
  border: ${rem(1)} solid #606060;
  width: initial;
  margin: 0 auto;
  text-decoration: none;
  max-width: ${rem(215)};
  width: 100%;
  display: flex;
  justify-content: center;
`;

const Title = styled.h2`
  font-size: ${rem(20)};
  color: white;
  font-weight: 500;
  margin: 0;
  margin-bottom: ${rem(32)};
`;

const DesktopWrapper = styled.div`
  ${({ theme }) => theme.mediab.m810`
    display: none;
  `}
`;

interface Props {
  lang: string;
  articles: {
    isFetching: boolean;
    articlesData: ThenArg<typeof API.loadArticles>;
  };
}

class NotFound extends React.Component<Props & WithRouterProps> {
  public static async getInitialProps(parentProps) {
    const { dispatch } = parentProps;
    try {
      await dispatch(loadHomeArticles());

      return;
    } catch (exp) {
      return {
        isError: true,
      };
    }
  }

  public render() {
    const { articles, lang, location } = this.props;
    const isFetchingArticles = prop(articles, 'isFetching', false);
    const homeArticles = prop(articles, 'articlesData.articles', []).slice(
      0,
      3,
    );

    if (lang === 'cz' && location.pathname === '/cz') {
      return <Loader small={false} />;
    }

    return (
      <Wrapper>
        <MetaTags tags={{ title: __('Stránka nenájdená') }} />
        <WrapperContainer>
          <TopWrapper>
            <Text>404</Text>
            <NotFoundWrapper>
              {__('Hľadaná stránka neexistuje')}
              <Icon alt="" src="/images/blog_assets/crying.png" />
            </NotFoundWrapper>
            <HomeLink to="/">{__('Prejsť na hlavnú stránku')}</HomeLink>
          </TopWrapper>
          <DesktopWrapper>
            <Title>{__('Alebo si pozrite najnovšie články...')}</Title>
            <NotFoundPageArticles
              isFetching={isFetchingArticles}
              articles={homeArticles}
            />
          </DesktopWrapper>
        </WrapperContainer>
      </Wrapper>
    );
  }
}

const mapStateToProps = state => {
  return {
    articles: homeArticlesSelector(state),
    lang: langSelector(state),
  };
};

export default connect(mapStateToProps)(
  connectSsr({ displayName: 'NotFound' })(withRouter(NotFound)),
);
