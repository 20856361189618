import APIClass from '../../services/API';
import {
  REQUEST_EBOOK,
  RECEIVE_EBOOK_SUCCESS,
  RECEIVE_EBOOK_ERROR,
} from './constants';
import { prop } from '../../utilities';

const requestEbook = () => ({
  type: REQUEST_EBOOK,
});

const receiveEbookSuccess = ebook => ({
  type: RECEIVE_EBOOK_SUCCESS,
  payload: {
    ebook,
  },
});

const receiveEbookError = error => ({
  type: RECEIVE_EBOOK_ERROR,
  payload: {
    error,
  },
});

export const loadEbook = ebookUrl => {
  return async (dispatch, getState, API) => {
    try {
      dispatch(requestEbook());
      const ebookUrlMap = await APIClass.getUrlMap({ url: ebookUrl });
      const ebookId = prop(ebookUrlMap, 'data.content_id', 0);
      const ebook = await APIClass.loadEbookDetail(ebookId);
      dispatch(receiveEbookSuccess(ebook));
    } catch (e) {
      dispatch(receiveEbookError(e));
    }
  };
};
