import * as React from 'react';
import { __ } from 'react-i18n/lib';
import styled from 'styled-components';
import { rem } from 'polished';
import { FlexRowCenterVertical, FlexCol } from 'eshop-defaults';
import ArticleTags from './ArticleTags';
import {
  MobileWrapper,
  DesktopWrapper,
} from '../_helpers/Default/ResponsiveWrappers';
import { Link } from 'react-router';
import { getAuthorUrl, formatDate } from '../../utilities';
// import { Picture } from '../_helpers/Image/Picture';
import { trackArticleClick } from '../../utilities/article';
import { getImagePath } from '../../utilities/product';

const Text = styled.p`
  margin: 0;
  font-family: ${({ theme }) => theme.blogFonts.primaryFont};
  font-size: ${rem(16)};
  color: ${({ theme }) => theme.blogColors.primary};
  font-weight: 400;
`;

const Wrapper = styled.div`
  text-decoration: none;
  display: flex;
  flex-flow: row;
  margin: 0 auto;
  padding: ${rem(8)} 0;
  border-bottom: ${rem(1)} solid ${({ theme }) => theme.blogColors.border};
  width: 100%;

  &:hover {
    opacity: 0.9;
  }

  ${({ theme }) => theme.mediab.l1050`
    flex-flow: column;
  `}

  ${({ theme }) => theme.mediab.m580`
    flex-flow: column;
    margin-bottom: ${rem(32)};
    border: 0;
    padding: 0;
  `}
`;

// const Image = styled.img`
//   /* width: ${rem(280)};
//   height: ${rem(280)}; */
//   max-width: initial;

//   ${({ theme }) => theme.mediab.m580`
//     width: 100%;
//     height: auto;
//   `}
// `;

const Rectangle = styled.div`
  width: 24px;
  height: 24px;

  flex-grow: 0;
  flex-shrink: 0;

  transform: rotate(45deg);
  background-color: white;
  margin-top: ${rem(64)};
  margin-left: ${rem(-8)};
  margin-right: ${rem(32)};

  ${({ theme }) => theme.mediab.l1050`
    display:none;
  `}
`;

const RightSideWrapper = styled(FlexCol)`
  flex: 1;
`;

export const ArticleTitle = styled(Text)`
  font-size: ${rem(24)};
  font-weight: 700;
  margin-top: ${rem(23)};
  margin-bottom: ${rem(16)};
  line-height: 32px;
  text-transform: uppercase;

  ${({ theme }) => theme.mediab.m580`
    line-height: 28px;
    margin-top: ${rem(16)};
    margin-bottom: ${rem(8)};
    max-width: 95%;
  `}
`;

const ArticleDesc = styled.div`
  text-decoration: none;
  margin-bottom: ${rem(24)};
  font-family: ${({ theme }) => theme.blogFonts.primaryFont};
  font-size: ${rem(16)};
  font-weight: 400;
  color: ${({ theme }) => theme.blogColors.primary};
  overflow: hidden;
  position: relative;
  line-height: 1.2em;
  max-height: 6em;
  text-align: justify;
  margin-right: -1em;
  padding-right: 1em;
  line-height: 24px;

  & p {
    margin: 0;
    line-height: 24px;
  }

  &::before {
    content: '...';
    position: absolute;
    right: 0;
    bottom: 0;
  }

  &::after {
    content: '';
    position: absolute;
    right: 0;
    width: 1em;
    height: 1em;
    margin-top: 0.2em;
    background: white;
  }

  ${({ theme }) => theme.mediab.m580`
    display: none;
  `}
`;

export const ArticleAuthorDateWrapper = styled(FlexRowCenterVertical)`
  margin-bottom: ${rem(16)};
`;

export const ArticleAuthorDateText = styled(Link)`
  font-size: ${rem(14)};
  color: ${({ theme }) => theme.blogColors.gray};
`;

export const ArticleAuthorDateTextLink = styled(Link)`
  margin: 0;
  font-family: ${({ theme }) => theme.blogFonts.primaryFont};
  font-weight: 400;
  font-size: ${rem(14)};
  color: ${({ theme }) => theme.blogColors.gray};
  text-decoration: none;
`;

export const DotDelimeter = styled.span`
  margin: 0 ${rem(16)};
  height: ${rem(3)};
  width: ${rem(3)};
  background-color: ${({ theme }) => theme.blogColors.gray};
  border-radius: 50%;
  display: inline-block;

  ${({ theme }) => theme.mediab.m580`
    margin: 0 ${rem(8)};
  `}
`;

const ArticleLink = styled(Link)`
  text-decoration: none;
`;

const CategoryPicture = styled.div`
  background-image: ${({ src }) => `url('${src}')`};
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  width: 300px;
  padding-bottom: 100%;

  @media all and (max-width: 960px) {
    width: 100%;
    padding-bottom: 56.25%;
    margin-bottom: 8px;
  }
`;

const StyledLink = styled(Link)`
  /* flex: 1 auto; */
`;

interface Props {
  id: string;
  name: string;
  date?: Date;
  url: string;
  annot?: string;
  image: string;
  tags: Array<{ name: string; id: string }>;
  author: any;
  lang: string;
  isEbook?: boolean;
  withoutAnnot?: boolean;
}

const ArticleListItem: React.SFC<Props> = ({
  id,
  name,
  date,
  url,
  annot,
  image,
  tags,
  author,
  lang,
  isEbook = false,
  withoutAnnot = false,
}) => {
  const { name: authorName = '', url: authorUrl = '', author_id = '' } =
    author || {};
  const createAnnot = () => {
    return { __html: annot || '' };
  };

  const trackArticle = () => {
    trackArticleClick(id, lang);
  };

  const correctLink = isEbook
    ? `/ebook/${url.replace(/\//g, '%2F')}`
    : `/${url}`;

  return (
    <Wrapper>
      <StyledLink to={correctLink} onClick={trackArticle}>
        <CategoryPicture
          src={getImagePath(image, { height: 300, width: 300 }, false)}
        />
        {/*<Picture*/}
        {/*alt={`Titulný obrázok ku článku ${name}`}*/}
        {/*image={}*/}
        {/*width={withoutAnnot ? 200 : 300}*/}
        {/*height={0}*/}
        {/*/>*/}
      </StyledLink>
      <Rectangle />
      <RightSideWrapper>
        <DesktopWrapper>
          <ArticleTags tags={tags} />
        </DesktopWrapper>
        <ArticleLink onClick={trackArticle} to={correctLink}>
          <ArticleTitle>{name}</ArticleTitle>
          {!withoutAnnot && (
            <ArticleDesc dangerouslySetInnerHTML={createAnnot()} />
          )}
        </ArticleLink>
        <ArticleAuthorDateWrapper>
          <ArticleAuthorDateTextLink to={getAuthorUrl(author_id, authorUrl)}>
            {authorName}
          </ArticleAuthorDateTextLink>
          {authorName !== '' && <DotDelimeter />}
          {date && (
            <ArticleAuthorDateText>
              {formatDate(date, 'DD.MM.YYYY')}
            </ArticleAuthorDateText>
          )}
        </ArticleAuthorDateWrapper>
      </RightSideWrapper>
      <MobileWrapper>
        <ArticleTags mobile={true} tags={tags} />
      </MobileWrapper>
    </Wrapper>
  );
};

export default ArticleListItem;
