import { CookiesNotif, MetaTags } from 'eshop-defaults';
import * as React from 'react';
import styled from 'styled-components';
import * as cookie from 'react-cookies';
import { Helmet } from 'react-helmet';
import { connect } from 'react-redux';
import { connectSsr } from 'ssr-service';
import {
  loadFooterData,
  loadTopMenuItems,
  setCurrency,
  loadDefaultSettings,
  setLanguage,
  loadTopMenuBrands,
} from './actions';
import { CURRENCY_COOKIE } from './constants';
import Header from '../../components/Header/Header';
import { dimmerVisibilitySelector } from './selectors';
import Dimmer from '../../components/Dimmer/Dimmer';
import { loadCategoriesTree } from '../Header/actions';
import { default as API, ThenArg } from '../../services/API';
import Footer from '../Footer/Footer';
import { GlobalStyles } from '../../theme/globalStyles';
import { Loader } from '../../components/_helpers/Loader/Loader';
import { LogoMicrodata } from '@bart.sk-ecommerce/react-microdata';
import {
  checksRedirect,
  isSSR,
} from '@bart.sk-ecommerce/react-eshop-redirects/lib/action';
import {
  resolveCurrencyByHostname,
  resolveLangByHostname,
} from '../../configureTrans';

interface Props {
  langSet: boolean;
  lang: string;
  props: any;
  footerData: ThenArg<typeof API.loadSitemapTree>;
  socialUrls: {
    fbUrl: string;
    igUrl: string;
    ytUrl: string;
  };
  metaTags: {
    description: string;
    keywords: string;
    og_article_author: string;
  };
  dispatch: any;
  menuItems: Array<{
    active: boolean;
    name: string;
    url: string;
    attribs: any;
  }>;
  isError: boolean;
  dimmerVisible: boolean;
}

export const FillSpaceWrapper = styled.div`
  display: flex;
  flex-flow: column;
  flex: 1 1 auto;
`;

class App extends React.PureComponent<Props> {
  public static async getInitialProps(props) {
    const { dispatch } = props;
    try {
      await dispatch(checksRedirect(props.location));

      // dispatch(setLanguage('sk'));
      // dispatch(setCurrency(cookie.load(CURRENCY_COOKIE)));

      await Promise.all[
        (dispatch(setLanguage(resolveLangByHostname(props.hostname))),
        dispatch(setCurrency(resolveCurrencyByHostname(props.hostname))))
      ];

      await Promise.all[
        (await dispatch(loadDefaultSettings()),
        await dispatch(loadFooterData()),
        await dispatch(loadTopMenuItems()),
        await dispatch(loadTopMenuBrands()),
        await dispatch(loadCategoriesTree()))
      ];

      // if (!props.footerData) {
      //   await dispatch(loadFooterData());
      // }

      // if (!props.menuItems) {
      //   await dispatch(loadTopMenuItems());
      // }

      // await dispatch(loadTopMenuBrands());
      // await dispatch(loadCategoriesTree());

      return {
        props,
      };
    } catch (exp) {
      console.log(exp);
      return {
        isError: true,
      };
    }
  }

  public render() {
    const { children, dimmerVisible, socialUrls, langSet, lang } = this.props;

    return (
      <React.Fragment>
        <GlobalStyles />
        <Helmet />
        {this.props.metaTags && <MetaTags tags={this.props.metaTags} />}
        {langSet ? (
          <>
            <LogoMicrodata
              url={
                lang === 'cz' ? 'https://www.vzdy.cz' : 'https://www.vzdy.sk'
              }
              logo={
                lang === 'cz'
                  ? 'https://www.vzdy.cz/images/blog_assets/vzdy_cz.svg'
                  : 'https://www.vzdy.sk/images/blog_assets/Group1.svg'
              }
            />
            <Header socialUrls={socialUrls} />
            <FillSpaceWrapper>{children}</FillSpaceWrapper>
            <Footer socialUrls={socialUrls} />
          </>
        ) : (
          <FillSpaceWrapper>
            <Loader loading={true} />
          </FillSpaceWrapper>
        )}

        {dimmerVisible && <Dimmer height={null} />}
        {false && !isSSR() && <CookiesNotif />}
      </React.Fragment>
    );
  }
}

const mapStateToProps = state => {
  return {
    footerData: state.general.footerData,
    socialUrls: state.general.socialUrls,
    metaTags: state.general.metaTags,
    lang: state.general.lang,
    langSet: state.general.langSet,
    dimmerVisible: dimmerVisibilitySelector(state),
  };
};

export default connect(mapStateToProps)(
  connectSsr({ displayName: 'App' })(App),
);
