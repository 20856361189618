import * as React from 'react';
import { __ } from 'react-i18n/lib';
import styled from 'styled-components';
import { rem } from 'polished';
import { FlexCol } from 'eshop-defaults';
import ArticleListItem from './ArticleListItem';
// import API from '../../services/API';
// import { ThenArg } from '../../services/Zasielkovna-API';
import { Loader } from '../_helpers/Loader/Loader';
import { prop } from '../../utilities';

const ArticlesList = styled(FlexCol)`
  justify-content: center;
  margin-bottom: ${rem(48)};
  margin-top: ${rem(48)};

  ${({ theme }) => theme.mediab.m810`
     margin-bottom: 0;
  `};

  ${({ theme }) => theme.mediab.m580`
     margin-top: 0;
  `};
`;

const TitleWrapper = styled.h2`
  margin: 0 0 ${rem(32)};
  display: none;
  align-items: center;
  flex-flow: row;
  text-transform: uppercase;

  font-family: ${({ theme }) => theme.blogFonts.primaryFont};
  font-size: ${rem(20)};
  color: ${({ theme }) => theme.blogColors.primary};
  font-weight: 700;
  text-transform: uppercase;

  ${({ theme, author }) =>
    theme.mediab.m810 &&
    author === false &&
    `
     display: flex;
  `}
`;

export const NoResultsWrapper = styled.div`
  width: 100%;
  flex: 1;
  text-align: center;
  min-width: ${({ noMinWidth }) => (noMinWidth ? 'initial' : rem(1032))};

  @media only screen and (max-width: 1530px) {
    min-width: ${({ noMinWidth }) => (noMinWidth ? 'initial' : rem(900))};
  }

  @media only screen and (max-width: 1370px) {
    min-width: ${({ noMinWidth }) => (noMinWidth ? 'initial' : rem(800))};
  }

  @media only screen and (max-width: 1280px) {
    min-width: ${({ noMinWidth }) => (noMinWidth ? 'initial' : rem(650))};
  }

  @media only screen and (max-width: 1130px) {
    min-width: ${({ noMinWidth }) => (noMinWidth ? 'initial' : rem(550))};
  }

  @media only screen and (max-width: 1030px) {
    min-width: ${({ noMinWidth }) => (noMinWidth ? 'initial' : rem(450))};
  }

  @media only screen and (max-width: 900px) {
    min-width: ${({ noMinWidth }) => (noMinWidth ? 'initial' : rem(350))};
  }
`;

export const NoResults = styled.h3`
  font-family: ${({ theme }) => theme.blogFonts.primaryFont};
  font-size: ${rem(20)};
  color: ${({ theme }) => theme.blogColors.primary};
  font-weight: 700;
  text-transform: uppercase;
`;

const Icon = styled.img`
  width: ${rem(22)};
  height: ${rem(22)};
  margin-right: ${rem(10)};
`;

const StyledLoader = styled(Loader)`
  margin-top: ${rem(64)};
  width: ${rem(100)};
  height: ${rem(100)};
`;

interface Props {
  // articles?: ThenArg<typeof API.loadArticles>['articles'];
  articles?: any;
  isFetching?: boolean;
  notFoundPage?: boolean;
  withoutTitle?: boolean;
  lang: string;
  noMinWidth?: boolean;
  withoutAnnot?: boolean;
}

const ArticleList: React.SFC<Props> = ({
  articles,
  isFetching,
  withoutTitle,
  lang,
  noMinWidth = false,
  withoutAnnot = false,
}) => {
  const renderArticles = () => {
    return (
      articles &&
      articles.map(article => {
        if (article.sf_form === 'Product') {
          return (
            <ArticleListItem
              key={article.id}
              id={article.id}
              name={`Produkt ${prop(article, 'name', '')}`}
              date={undefined}
              url={`produkt/${prop(article, 'id', '')}/${prop(
                article,
                'url',
                '',
              )}`}
              annot={prop(article, 'json_content.annotation', '')}
              image={prop(article, 'image', '')}
              tags={prop(article, 'tags', [])}
              author={prop(article, 'author', null)}
              lang={lang}
              withoutAnnot={withoutAnnot}
            />
          );
        }

        return (
          <ArticleListItem
            key={article.id}
            id={article.id}
            name={prop(article, 'name', '')}
            date={
              prop(article, 'created_date') ||
              prop(article, 'created_at') ||
              prop(article, 'last_update', '')
            }
            url={prop(article, 'url', '')}
            annot={prop(article, 'json_content.annotation', '')}
            image={prop(article, 'image', '')}
            tags={prop(article, 'tags', [])}
            author={prop(article, 'author', null)}
            lang={lang}
            withoutAnnot={withoutAnnot}
          />
        );
      })
    );
  };

  return (
    <ArticlesList>
      {isFetching ? (
        articles && articles.length > 0 ? (
          <>
            {renderArticles()}
            <StyledLoader small={false} />
          </>
        ) : (
          <StyledLoader small={false} />
        )
      ) : articles && articles.length > 0 ? (
        <>
          {!withoutTitle && (
            <TitleWrapper>
              <Icon src="/images/blog_assets/article.svg" alt="article-icon" />
              {__('Články')}
            </TitleWrapper>
          )}
          {renderArticles()}
        </>
      ) : (
        <NoResultsWrapper noMinWidth={noMinWidth}>
          <NoResults>{__('Nenašli sa žiadne články')}</NoResults>
        </NoResultsWrapper>
      )}
    </ArticlesList>
  );
};

export default ArticleList;
