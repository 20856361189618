import * as React from 'react';
import { __ } from 'react-i18n/lib';
import styled from 'styled-components';
import ArticleTags from '../Article/ArticleTags';
import { rem } from 'polished';
import RenderedBody from '../Article/RenderedBody';

const Box = styled.div`
  max-width: ${rem(700)};

  @media only screen and (max-width: 800px) {
    max-width: 35%;
  }
`;

const Title = styled.h3`
  text-align: left;
  font: 36px/48px Barlow;
  font-weight: 500;
  letter-spacing: 0;
  color: #414141;
  padding: 0;
  margin: 0;
  margin-bottom: ${rem(28)};
  line-height: 48px;

  @media only screen and (max-width: 800px) {
    padding: ${rem(24)} 0 ${rem(20)};
    margin: 0;
    line-height: 28px;
  }
  @media only screen and (max-width: 480px) {
    font: Medium 28px/32px Barlow;
  }
  @media only screen and (max-width: 320px) {
    font: 20px/28px Barlow;
  }
`;

const Description = styled.div`
  font-size: ${rem(16)};
  line-height: ${rem(24)};
  font-weight: 500;
  letter-spacing: 0;
  color: #414141;
  margin: 0;
  padding: ${rem(50)} 0 0;
  max-width: ${rem(500)};
  line-height: 24px;

  & p {
    line-height: 24px;
  }

  @media only screen and (max-width: 800px) {
    text-align: center;
  }
  @media only screen and (max-width: 480px) {
    padding: 32px 0 0;
    font-size: 20px;
    text-align: left;
  }
`;

// const Body = styled.div`
//   font: 16px/20px Barlow;
//   letter-spacing: 0;
//   color: #414141;
//   padding: 8px 0;
//   max-width: ${rem(400)};
// `;

const More = styled.p`
  margin: 0;
  font-size: ${rem(24)};
  line-height: ${rem(32)};
  font-weight: 500;
  color: #414141;
  margin-top: ${rem(48)};
  margin-bottom: ${rem(24)};
  line-height: 32px;

  @media only screen and (max-width: 480px) {
    line-height: 28px;
  }
`;

// const List = styled.li`
//   font: 16px/20px Barlow;
//   letter-spacing: 0;
//   color: #414141;
//   padding: 8px 0;
//   max-width: ${rem(400)};
// `;

// const Ul = styled.ul`
//   padding-left: ${rem(15)};
//   list-style-type: square;
// `;

const Wrapper = styled.div`
  @media only screen and (max-width: 800px) {
    position: absolute;
    top: 0;
  }
`;

const EbookDescription = ({ desc, name, body, tags }) => {
  const createDesc = () => {
    if (typeof desc === 'object') {
      return { __html: '' };
    }
    return { __html: desc || '' };
  };

  // const createBody = () => {
  //   if (typeof body === 'object') {
  //     return { __html: '' };
  //   }
  //   return { __html: body || '' };
  // };

  const hasBody = typeof body !== 'object' && body !== '';

  return (
    <Box>
      <Wrapper>
        <Title>{name}</Title>
        <ArticleTags tags={tags} />
      </Wrapper>
      <Description dangerouslySetInnerHTML={createDesc()} />
      {hasBody && (
        <>
          <More>{__('V knihe sa dočítate...')}</More>
          <RenderedBody articleTitle={name} inputBody={body} type="ebook" />
        </>
      )}
    </Box>
  );
};

export default EbookDescription;
