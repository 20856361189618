import { createSelector } from 'reselect';
import { prop } from 'eshop-defaults';

export const articlesWithTagDomainSelector = state =>
  state.articlesWithTag || {};

export const articlesWithTagSelector = createSelector(
  articlesWithTagDomainSelector,
  substate => prop(substate, 'articles', []),
);

export const articlesWithTagIsFetchingSelector = createSelector(
  articlesWithTagDomainSelector,
  substate => prop(substate, 'isFetching', false),
);

export const articlesWithTagErrorSelector = createSelector(
  articlesWithTagDomainSelector,
  substate => prop(substate, 'error'),
);

export const articlesWithTagOffsetSelector = createSelector(
  articlesWithTagDomainSelector,
  substate => prop(substate, 'offset', 0),
);

export const articlesWithTagTotalSelector = createSelector(
  articlesWithTagDomainSelector,
  substate => prop(substate, 'total', 0),
);
