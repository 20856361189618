import * as React from 'react';
import { __ } from 'react-i18n/lib';
import styled from 'styled-components';
import { rem } from 'polished';
import { FlexCol } from 'eshop-defaults';
// import API from '../../services/API';
// import { ThenArg } from '../../services/Zasielkovna-API';
import { Loader } from '../_helpers/Loader/Loader';
import { prop } from '../../utilities';
import ArticleListItem from '../Article/ArticleListItem';

const ArticlesList = styled(FlexCol)`
  justify-content: center;
  margin-bottom: ${rem(48)};
  margin-top: ${rem(48)};

  ${({ theme }) => theme.mediab.m810`
     margin-bottom: 0;
  `};
`;

export const NoResultsWrapper = styled.div`
  width: 100%;
  flex: 1;
  text-align: center;
`;

export const NoResults = styled.h3`
  font-family: ${({ theme }) => theme.blogFonts.primaryFont};
  font-size: ${rem(20)};
  color: ${({ theme }) => theme.blogColors.primary};
  font-weight: 700;
  text-transform: uppercase;
`;

const StyledLoader = styled(Loader)`
  margin-top: ${rem(64)};
  width: ${rem(100)};
  height: ${rem(100)};
`;

interface Props {
  // articles?: ThenArg<typeof API.loadArticles>['articles'];
  ebooks?: any;
  isFetching?: boolean;
  lang: string;
}

const EbookList: React.SFC<Props> = ({ ebooks, isFetching, lang }) => {
  return (
    <ArticlesList>
      {isFetching ? (
        <StyledLoader small={false} />
      ) : ebooks && ebooks.length > 0 ? (
        <>
          {ebooks &&
            ebooks.map(ebook => {
              return (
                <ArticleListItem
                  key={ebook.id}
                  id={ebook.id}
                  name={prop(ebook, 'name', '')}
                  date={prop(ebook, 'last_update', '')}
                  url={prop(ebook, 'url', '')}
                  annot={prop(ebook, 'json_content.annotation', '')}
                  image={prop(ebook, 'image', '')}
                  tags={prop(ebook, 'tags', [])}
                  author={prop(ebook, 'author', null)}
                  lang={lang}
                  isEbook={true}
                />
              );
            })}
        </>
      ) : (
        <NoResultsWrapper>
          <NoResults>{__('Nenašli sa žiadne ebooky')}</NoResults>
        </NoResultsWrapper>
      )}
    </ArticlesList>
  );
};

export default EbookList;
