import React, { useState } from 'react';
import styled from 'styled-components';
import { FlexColCenterVertical, FlexCol } from 'eshop-defaults';
import { rem } from 'polished';
import Input from '../_helpers/forms/Input/Input';
import { __ } from 'react-i18n';
import { Loader } from '../_helpers/Loader/Loader';
import NotFoundPageArticles from '../Article/NotFoundPageArticles';
import { SecondaryButton } from '../_helpers/Default/Buttons';
import {
  DesktopWrapper,
  MobileWrapper,
} from '../_helpers/Default/ResponsiveWrappers';
import OutsideClick from '../_helpers/Default/OutsideClick';

const Wrapper = styled(FlexColCenterVertical)`
  margin-top: ${rem(36)};

  position: absolute;
  background-color: ${({ theme }) => theme.blogColors.primary};
  width: 100%;
  left: 0;
  margin: 0;
  z-index: 400;
  margin-top: ${rem(80)};
  padding: ${rem(32)};

  @media only screen and (max-width: 1150px) {
    padding: ${rem(16)};
    margin: 0;
  }
`;

const SearchFormWrapper = styled.form`
  display: flex;
  flex-flow: row;
  align-items: center;
  padding-bottom: ${rem(10)};
  border-bottom: ${rem(1)} solid ${({ theme }) => theme.blogColors.darkGray};
  margin-bottom: ${rem(32)};
`;

const SearchButton = styled.button`
  padding: 0;
  margin-right: ${rem(8)};
  border: 0;
  background: transparent;
`;

const SearchIcon = styled.img`
  width: ${rem(24)};
  height: ${rem(24)};
`;

const SearchInput = styled(Input)`
  color: white;
  padding: ${rem(16)} ${rem(8)};
  width: 90%;

  &:focus {
    outline: none;
    /* border: ${({ theme }) => theme.blogColors.secondary}; */
  }

  &::placeholder {
    color: #9f9f9f;
    font-size: ${rem(16)};
  }
`;

const ResultsWrapper = styled(FlexCol)`
  margin-bottom: ${rem(16)};
`;

const Title = styled.h2`
  margin: 0;
  margin-bottom: ${rem(28)};
  font-weight: 500;
  font-size: ${rem(20)};
`;

const Results = styled(FlexCol)``;

const NoResults = styled.p`
  margin: 0;
  color: #9f9f9f;
`;

const ShowMoreResultsButton = styled(SecondaryButton)`
  width: ${rem(140)};
  background: transparent;
  color: white;
  border: 2px solid #606060;
  width: 100%;
    max-width: ${rem(250)};
    margin: 0;

  /* ${({ theme }) => theme.mediab.l1150`
    width: 100%;
    max-width: ${rem(250)};
    margin: 0;
  `} */

  ${({ theme }) => theme.mediab.m580`
    width: 100%;
    max-width: initial;
    margin: 0 auto;
  `}
`;

function SearchForm({
  results,
  redirectToResultPage,
  loadPartialResults,
  toggleResponsiveSearchForm,
}) {
  const [searchTerm, setSearchTerm] = useState('');

  const inputChange = e => {
    setSearchTerm(e.target.value);
    if (e.target.value.length > 1) {
      loadPartialResults(e);
    }
  };

  const { isFetching, articles } = results;

  return (
    <OutsideClick handleFunction={toggleResponsiveSearchForm}>
      <Wrapper>
        <SearchFormWrapper>
          <SearchButton
            // tslint:disable-next-line:jsx-no-lambda
            onClick={e => redirectToResultPage(e, searchTerm)}
            type="submit"
          >
            <SearchIcon src="/images/blog_assets/search_white.svg" />
          </SearchButton>
          <SearchInput
            /* tslint:disable-next-line:jsx-no-lambda */
            onChange={inputChange}
            placeholder={__('Vyhľadávanie...')}
            value={searchTerm}
            autoFocus={true}
          />
        </SearchFormWrapper>
        <ResultsWrapper>
          <Title>{__('Výsledky')}</Title>
          <Results>
            {isFetching ? (
              <Loader />
            ) : articles && articles.length > 0 ? (
              <FlexCol>
                <DesktopWrapper>
                  <NotFoundPageArticles
                    onClick={toggleResponsiveSearchForm}
                    isFetching={false}
                    articles={articles}
                    containsProducts={true}
                  />
                  <ShowMoreResultsButton
                    // tslint:disable-next-line:jsx-no-lambda
                    onClick={e => redirectToResultPage(e, searchTerm)}
                  >
                    {__('Zobraziť všetky výsledky')}
                  </ShowMoreResultsButton>
                </DesktopWrapper>
                <MobileWrapper>
                  <NotFoundPageArticles
                    onClick={toggleResponsiveSearchForm}
                    searchResultsPage={true}
                    isFetching={false}
                    articles={articles}
                    containsProducts={true}
                  />
                  <ShowMoreResultsButton
                    // tslint:disable-next-line:jsx-no-lambda
                    onClick={e => redirectToResultPage(e, searchTerm)}
                  >
                    {__('Zobraziť všetky výsledky')}
                  </ShowMoreResultsButton>
                </MobileWrapper>
              </FlexCol>
            ) : (
              <NoResults>{__('Nenašli sa žiadne výsledky')}</NoResults>
            )}
          </Results>
        </ResultsWrapper>
      </Wrapper>
    </OutsideClick>
  );
}

export default SearchForm;
