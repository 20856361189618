import { connectSsr } from 'ssr-service';
import { connect } from 'react-redux';
import * as React from 'react';
import { __ } from 'react-i18n/lib';
import { rem } from 'polished';
import styled from 'styled-components';
import API, { ThenArg } from '../../services/API';
import MetaTags from '../../components/MetaTags/MetaTags';
import { SecondaryButton } from '../../components/_helpers/Default/Buttons';
import ArticleList from '../../components/Article/ArticleList';
import { prop } from 'eshop-defaults';
import {
  loadHomeMostRead,
  loadHomeRecommended,
  loadHomeFastNews,
  loadHomeEbooks,
  loadHomeTopArticle,
  loadHomeBanner,
  loadHomeArticles,
  setHomeArticlesFilters,
  loadMoreHomeArticles,
} from './actions';
import { langSelector, titleSelector } from '../Category/selectors';
import {
  mostReadArticlesSelector,
  recommendedArticlesSelector,
  fastNewsSelector,
  ebooksSelector,
  topArticleSelector,
  adBannerSelector,
  homeArticlesSelector,
  recommendedArticlesOptionsSelector,
} from './selectors';
import SidebarArticles, {
  TitleText,
  FastNewsIcon,
} from '../../components/Article/SidebarArticles';
import EbookSidebar from '../../components/Home/EbookSidebar';
import TopArticleBanner, {
  MobileTopArticle,
} from '../../components/Article/TopArticleBanner';
import NewsletterForm from '../../components/Home/NewsletterForm';
import {
  MobileWrapper,
  DesktopWrapper,
} from '../../components/_helpers/Default/ResponsiveWrappers';
import { getImagePath } from '../../utilities/product';
import ArticlesWithSidebar from '../../components/Home/ArticlesWithSidebar';
import { withRouter, Link, WithRouterProps } from 'react-router';
import { isValidURL } from '../../utilities';

const ShowMoreButton = styled(SecondaryButton)`
  width: ${rem(140)};
  margin-bottom: ${rem(48)};

  ${({ theme }) => theme.mediab.m810`
    width: 100%;
    max-width: initial;
    margin-bottom: ${rem(48)};
  `}
`;

export const MainWrapper = styled.div`
  padding: ${rem(30)} 0 0;
  width: 100%;

  ${({ theme }) => theme.mediab.m810`
     padding-top: ${rem(16)};
  `}
`;

const HomeAdBanner = styled.img`
  max-width: ${rem(1032)};
  width: 100%;
  height: auto;

  ${({ theme }) => theme.mediab.m810`
    display: none;
  `}
`;

export const FillSpaceWrapper = styled.div`
  display: flex;
  flex-flow: column;
  flex: 1 1 auto;
`;

interface Props {
  banners: any;
  parentProps: {};
  dispatch: any;
  isError: boolean;
  loaded: any;
  defaultTitle: string;
  lang: string;
  modal: any;
  ebooks: {
    ebooksData: ThenArg<typeof API.loadArticles>;
    isFetching: boolean;
  };
  mostRead: {
    isFetching: boolean;
    mostReadArticles: ThenArg<typeof API.loadArticles>;
    filter?: string;
  };
  recommended: {
    isFetching: boolean;
    recommendedArticles: ThenArg<typeof API.loadArticles>;
    filter?: string;
  };
  recommendedOptions: ThenArg<typeof API.loadSitemapSubtree>['sitemap_tree'];
  fastNews: {
    isFetching: boolean;
    fastNewsArticles: ThenArg<typeof API.loadArticles>;
  };
  topArticle: {
    isFetching: boolean;
    topArticleData: ThenArg<typeof API.loadArticles>;
  };
  articles: {
    isFetching: boolean;
    articlesData: ThenArg<typeof API.loadArticles>;
  };
  adBanner: any;
  router: any;
  location: any;
}

interface State {
  bannerImg: number;
}

class Home extends React.Component<Props & WithRouterProps, State> {
  public static async getInitialProps(parentProps) {
    const { dispatch } = parentProps;
    try {
      await Promise.all[
        (await dispatch(loadHomeArticles()),
        await dispatch(loadHomeBanner()),
        await dispatch(loadHomeMostRead()),
        await dispatch(loadHomeRecommended(null)),
        await dispatch(loadHomeFastNews()),
        await dispatch(loadHomeEbooks()),
        await dispatch(loadHomeTopArticle()))
      ];

      return;
    } catch (exp) {
      return {
        isError: true,
      };
    }
  }

  private bannerChange: any;

  constructor(props) {
    super(props);

    this.state = {
      bannerImg: 0,
    };
  }

  public componentDidMount() {
    this.loadVideo();
    this.bannerChange = setInterval(() => this.changeBannerImage(), 5000);
  }

  public componentWillUnmount() {
    clearInterval(this.bannerChange);
  }

  public changeBannerImage() {
    const { banners } = this.props;
    if (banners) {
      this.setState(prevState => ({
        bannerImg:
          prevState.bannerImg >= banners.length - 1
            ? 0
            : prevState.bannerImg + 1,
      }));
    }
  }

  public handleFilterChange = (type, e) => {
    switch (type) {
      case 'mostRead':
        const mostReadFilterValue = prop(e, 'target.dataset.value');
        this.props.dispatch(loadHomeMostRead(mostReadFilterValue));
        break;
      case 'recommended':
        const recommendedValue = prop(e, 'target.dataset.value');
        this.props.dispatch(loadHomeRecommended(recommendedValue));
        break;

      default:
        break;
    }
  };

  public showMoreClick = async () => {
    const position = window.pageYOffset;
    const { dispatch, articles } = this.props;
    await dispatch(loadMoreHomeArticles());
    await dispatch(
      setHomeArticlesFilters({ offset: prop(articles, 'offset', 0) + 10 }),
    );

    window.scrollTo({
      left: 0,
      top: position,
      behavior: 'smooth',
    });
  };

  public getMainContent = () => {
    const {
      fastNews,
      ebooks,
      topArticle,
      adBanner,
      articles,
      lang,
    } = this.props;
    const isFetchingArticles = prop(articles, 'isFetching', false);
    const mainArticles = prop(articles, 'articlesData.articles', []);
    const mainArticlesTotal = prop(articles, 'articlesData.total', 0);
    const topArticleData = prop(topArticle, 'topArticleData.articles', [])[0];
    const adBannerBUrl = prop(adBanner, 'b_url', '');
    const adBannerUrl =
      adBanner && isValidURL(adBannerBUrl) ? new URL(adBannerBUrl) : '';
    const adBannerPathname = adBannerUrl ? adBannerUrl.href : '';
    return (
      <>
        <TopArticleBanner
          topArticle={topArticleData}
          isFetching={topArticle.isFetching}
        />
        {adBanner && (
          <a
            rel="noopener noreferrer"
            target="_blank"
            href={`${adBannerPathname}`}
          >
            <picture>
              <source
                srcSet={getImagePath(
                  adBanner.b_path,
                  {
                    width: 1032,
                    height: 290,
                  },
                  false,
                  true,
                )}
                type="image/webp"
              />
              <source
                srcSet={getImagePath(
                  adBanner.b_path,
                  {
                    width: 1032,
                    height: 290,
                  },
                  false,
                  false,
                )}
                type="image/jpeg"
              />

              <HomeAdBanner
                src={getImagePath(adBanner.b_path, {
                  width: 1032,
                  height: 290,
                })}
                alt={adBanner.b_name}
              />
            </picture>
          </a>
        )}
        <SidebarArticles
          lang={lang}
          mobile={true}
          title={
            <TitleText>
              <FastNewsIcon src="/images/blog_assets/flash.svg" />
              {__('Bleskovky')}
            </TitleText>
          }
          isFetching={fastNews.isFetching}
          articles={fastNews.fastNewsArticles.articles}
        />
        <ArticleList
          lang={lang}
          articles={mainArticles}
          isFetching={isFetchingArticles}
        />
        {mainArticlesTotal > 10 && !isFetchingArticles && (
          <ShowMoreButton onClick={this.showMoreClick}>
            {__('Zobraziť viac')}
          </ShowMoreButton>
        )}
        <DesktopWrapper>
          <NewsletterForm />
        </DesktopWrapper>
        <EbookSidebar
          mobile={true}
          title={<TitleText>{__('E-booky')}</TitleText>}
          isFetching={ebooks.isFetching}
          ebooks={ebooks.ebooksData}
        />
      </>
    );
  };

  public loadVideo = () => {
    const iframe = document.getElementsByClassName('latestVideoEmbed')[0];
    const reqURL =
      'https://api.rss2json.com/v1/api.json?rss_url=' +
      encodeURIComponent(
        'https://www.youtube.com/feeds/videos.xml?channel_id=',
      );

    if (iframe) {
      fetch(reqURL + 'UCxJwPK3N435C0rEStyF3Fgw')
        .then(res => res.json())
        .then((data: any) => {
          const videoNumber = 0;
          const link = data.items[videoNumber].link;
          const title = data.items[videoNumber].title;
          const id = link.substr(link.indexOf('=') + 1);
          iframe.setAttribute(
            'src',
            'https://youtube.com/embed/' + id + '?controls=0&autoplay=0',
          );
          if (
            iframe &&
            iframe.parentElement &&
            iframe.parentElement.querySelector('#video-title')
          ) {
            (iframe.parentElement.querySelector(
              '#video-title',
            ) as any).innerText = title;
          }
        });
    }
  };

  public getSidebarContent = () => {
    const {
      fastNews,
      mostRead,
      recommended,
      recommendedOptions,
      ebooks,
      lang,
    } = this.props;

    const mostReadFilter = [
      { name: __('TÝŽDEŇ'), value: 'week' },
      { name: __('MESIAC'), value: 'month' },
      { name: __('ROK'), value: 'year' },
    ];
    const recommendedOptionsRemapped =
      recommendedOptions &&
      recommendedOptions.map(o => ({
        name: o.name || '',
        value: o.id ? o.id.toString() : '',
      }));

    return (
      <>
        {mostRead.mostReadArticles.articles &&
          mostRead.mostReadArticles.articles.length > 0 && (
            <SidebarArticles
              lang={lang}
              title={<TitleText>{__('NAJČÍTANEJŠIE')}</TitleText>}
              isFetching={mostRead.isFetching}
              articles={mostRead.mostReadArticles.articles}
              value={mostRead.filter || mostReadFilter[0].value}
              onChange={this.handleFilterChange.bind(this, 'mostRead')}
              options={mostReadFilter}
            />
          )}
        <iframe
          className="latestVideoEmbed"
          width="100%"
          height="200"
          frameBorder="0"
          allowFullScreen={true}
          style={{ marginBottom: '32px' }}
        />
        <iframe
          src="https://open.spotify.com/embed/show/4mw2KXMVsyKQz1iMer2hTW"
          width="100%"
          height="152"
          frameBorder="0"
          allowTransparency={true}
          allow="encrypted-media"
          title="spotifyEmbed"
          style={{ marginBottom: '32px' }}
        />
        {recommended.recommendedArticles.articles &&
          recommended.recommendedArticles.articles.length > 0 && (
            <SidebarArticles
              lang={lang}
              title={<TitleText>{__('ODPORÚČAME')}</TitleText>}
              isFetching={recommended.isFetching}
              articles={recommended.recommendedArticles.articles}
              value={
                recommended.filter ||
                (recommendedOptionsRemapped[0] &&
                  recommendedOptionsRemapped[0].value) ||
                ''
              }
              onChange={this.handleFilterChange.bind(this, 'recommended')}
              options={recommendedOptionsRemapped}
            />
          )}
        <SidebarArticles
          lang={lang}
          title={
            <TitleText>
              <FastNewsIcon src="/images/blog_assets/flash.svg" />
              {__('Bleskovky')}
            </TitleText>
          }
          isFetching={fastNews.isFetching}
          articles={fastNews.fastNewsArticles.articles}
        />
        <EbookSidebar
          title={<TitleText>{__('E-booky')}</TitleText>}
          isFetching={ebooks.isFetching}
          ebooks={ebooks.ebooksData}
        />
      </>
    );
  };

  public render() {
    const { router, lang, location, topArticle } = this.props;
    const topArticleData = prop(topArticle, 'topArticleData.articles', []);
    const topArticleMobileImage = prop(topArticleData[0], 'image');
    const topArticleMobileName = prop(topArticleData[0], 'name', '');

    if (lang === 'cz' && location.pathname === '/') {
      router.push('/cz');
    }

    return (
      <React.Fragment>
        <MetaTags
          tags={{}}
          hrefLangs={[
            { lang_id: 'sk', url: '' },
            { lang_id: 'cz', url: '' },
          ]}
        />
        {topArticleMobileName ? (
          <MobileTopArticle
            alt={topArticleMobileName}
            src={
              topArticleMobileImage
                ? getImagePath(topArticleMobileImage, { width: 580, height: 0 })
                : 'https://via.placeholder.com/1032x580.png'
            }
          />
        ) : null}
        <ArticlesWithSidebar
          mainContentChildren={this.getMainContent()}
          sidebarChildren={this.getSidebarContent()}
        />
        <MobileWrapper>
          <NewsletterForm />
        </MobileWrapper>
      </React.Fragment>
    );
  }
}

const mapStateToProps = state => {
  return {
    defaultTitle: titleSelector(state),
    lang: langSelector(state),
    mostRead: mostReadArticlesSelector(state),
    recommended: recommendedArticlesSelector(state),
    recommendedOptions: recommendedArticlesOptionsSelector(state),
    fastNews: fastNewsSelector(state),
    ebooks: ebooksSelector(state),
    topArticle: topArticleSelector(state),
    adBanner: adBannerSelector(state),
    articles: homeArticlesSelector(state),
  };
};

export default connect(mapStateToProps)(
  connectSsr({ displayName: 'Home' })(withRouter(Home)),
);
