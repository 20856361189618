import { createSelector } from 'reselect';
import { prop } from 'eshop-defaults';

export const homeDomainSelector = state => state.homepage || {};

export const homeArticlesSelector = createSelector(
  homeDomainSelector,
  substate =>
    prop(substate, 'articles', { articlesData: [], isFetching: true }),
);

export const articleIsFetchingSelector = createSelector(
  homeDomainSelector,
  substate => prop(substate, 'isFetching', false),
);

export const articleErrorSelector = createSelector(
  homeDomainSelector,
  substate => prop(substate, 'error'),
);

export const mostReadArticlesSelector = createSelector(
  homeDomainSelector,
  substate =>
    prop(substate, 'mostRead', { mostReadArticles: [], isFetching: false }),
);

export const recommendedArticlesSelector = createSelector(
  homeDomainSelector,
  substate =>
    prop(substate, 'recommended', {
      recommendedArticles: [],
      isFetching: false,
    }),
);

export const recommendedArticlesOptionsSelector = createSelector(
  homeDomainSelector,
  substate => prop(substate, 'recommended.recommendedArticlesOptions', []),
);

export const fastNewsSelector = createSelector(
  homeDomainSelector,
  substate =>
    prop(substate, 'fastNews', { fastNewsArticles: [], isFetching: false }),
);

export const ebooksSelector = createSelector(
  homeDomainSelector,
  substate => prop(substate, 'ebooks', { ebooks: [], isFetching: false }),
);

export const topArticleSelector = createSelector(
  homeDomainSelector,
  substate =>
    prop(substate, 'topArticle', {
      topArticleData: {
        articles: [],
        isFetching: false,
      },
    }),
);

export const adBannerSelector = createSelector(
  homeDomainSelector,
  substate => prop(substate, 'adBanner'),
);
