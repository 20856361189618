import {
  REQUEST_FAST_NEWS,
  RECEIVE_FAST_NEWS_SUCCESS,
  RECEIVE_FAST_NEWS_ERROR,
  SET_FAST_NEWS_FILTERS,
} from './constants';
import { langSelector } from '../Category/selectors';

const requestFastNews = () => ({
  type: REQUEST_FAST_NEWS,
});

const receiveFastNewsSuccess = (articles, total) => ({
  type: RECEIVE_FAST_NEWS_SUCCESS,
  payload: {
    articles,
    total,
  },
});

const receiveFastNewsError = error => ({
  type: RECEIVE_FAST_NEWS_ERROR,
  payload: {
    error,
  },
});

export const setFastNewsFilters = filters => ({
  type: SET_FAST_NEWS_FILTERS,
  payload: {
    filters,
  },
});

export const loadFastNews = (limit: number = 10, offset: number = 0) => {
  return async (dispatch, getState, API) => {
    try {
      dispatch(requestFastNews());
      dispatch(setFastNewsFilters({ offset }));

      const lang = langSelector(getState());
      const fastNews = await API.loadArticles(
        {
          sitemapUniqueId: 'BLESKOVKY',
          limit,
          offset,
          sfForm: 'Article',
          onlyActual: '1',
          isPublished: '1',
          sort: 'created_date',
          replaceCurrentLangTags: 1,
        },
        { xAcceptLanguage: lang },
      );

      dispatch(receiveFastNewsSuccess(fastNews.articles, fastNews.total));
    } catch (e) {
      dispatch(receiveFastNewsError(e));
    }
  };
};
