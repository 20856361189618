import * as React from 'react';
import { __ } from 'react-i18n/lib';
import styled from 'styled-components';
import { rem } from 'polished';
import { FlexCol } from 'eshop-defaults';
import ArticleTags from './ArticleTags';
import {
  ArticleTitle,
  ArticleAuthorDateWrapper,
  ArticleAuthorDateText,
  DotDelimeter,
  ArticleAuthorDateTextLink,
} from './ArticleListItem';
import { Loader } from '../_helpers/Loader/Loader';
import { Link } from 'react-router';
import { getAuthorUrl, formatDate } from '../../utilities';
import { getImagePath } from '../../utilities/product';

const DesktopWrapper = styled(FlexCol)`
  justify-content: flex-end;
  padding: ${rem(32)};
  margin-bottom: ${rem(48)};
  max-width: ${rem(1032)};
  width: 100%;
  height: ${rem(580)};
  background-repeat: no-repeat;
  background-size: cover;
  background-image: ${props =>
    ` linear-gradient(rgba(0, 0, 0, 0.0), rgba(0, 0, 0, 1)), url(${props.src})`};

  ${({ theme }) => theme.mediab.m580`
     display: none;
  `};
`;

const MobileWrapper = styled(FlexCol)`
  display: none;
  margin-bottom: ${rem(52)};

  ${({ theme }) => theme.mediab.m580`
     display: flex;
  `};
`;

export const MobileTopArticle = styled.img`
  width: 100%;
  height: 100%;
  display: none;

  ${({ theme }) => theme.mediab.m580`
     display: block;
  `};
`;

const StyledArticleTitle = styled(ArticleTitle)`
  color: white;
  font-size: ${rem(36)};
  font-weight: 500;
  width: 95%;
  text-transform: uppercase;
  line-height: 48px;

  ${({ theme }) => theme.mediab.m580`
    line-height: 28px;  
    font-size: ${rem(20)};
    font-weight: 700;
    color: black;
    margin-top: 0;
  `}
`;

const StyledArticleAuthorDateWrapper = styled(ArticleAuthorDateWrapper)`
  margin-bottom: 0;

  ${({ theme }) => theme.mediab.m580`
      margin-bottom: ${rem(16)};
  `}
`;

const StyledArticleAuthorDateText = styled(ArticleAuthorDateText)`
  color: white;
  font-size: ${rem(16)};
  font-weight: 400;

  ${({ theme }) => theme.mediab.m580`
     color: ${theme.blogColors.gray};
  `}
`;

const StyledArticleAuthorDateTextLink = styled(ArticleAuthorDateTextLink)`
  color: white;
  font-size: ${rem(16)};
  font-weight: 400;

  ${({ theme }) => theme.mediab.m580`
     color: ${theme.blogColors.gray};
  `}
`;

const StyledDotDelimeter = styled(DotDelimeter)`
  background-color: white;

  ${({ theme }) => theme.mediab.m580`
     color: ${theme.blogColors.gray};
  `}
`;

const StyledLink = styled(Link)`
  text-decoration: none;
`;

interface Props {
  // topArticle: ThenArg<typeof API.loadArticles>;
  topArticle: any;
  isFetching: boolean;
}

const TopArticleBanner: React.SFC<Props> = ({ topArticle, isFetching }) => {
  if (isFetching) {
    return <Loader />;
  } else if (!topArticle) {
    return null;
  }

  const {
    image,
    name,
    author,
    last_update,
    created_date,
    url,
    tags,
  } = topArticle;
  const { name: authorName = '', url: authorUrl = '', author_id = '' } =
    author || {};

  return (
    <StyledLink to={`/${url}`}>
      <DesktopWrapper
        src={getImagePath(image, { width: 1032, height: 580 }, true)}
      >
        <ArticleTags topArticle={true} tags={tags} />
        <StyledArticleTitle>{name}</StyledArticleTitle>
        <StyledArticleAuthorDateWrapper>
          <StyledArticleAuthorDateTextLink
            to={getAuthorUrl(author_id, authorUrl)}
          >
            {authorName}
          </StyledArticleAuthorDateTextLink>
          {authorName !== '' && <StyledDotDelimeter />}
          <StyledArticleAuthorDateText>
            {formatDate(created_date, 'DD.MM.YYYY')}
          </StyledArticleAuthorDateText>
        </StyledArticleAuthorDateWrapper>
      </DesktopWrapper>
      <MobileWrapper>
        <StyledArticleTitle>{name}</StyledArticleTitle>
        <StyledArticleAuthorDateWrapper>
          <StyledArticleAuthorDateText>
            {authorName}
          </StyledArticleAuthorDateText>
          <StyledDotDelimeter />
          <StyledArticleAuthorDateText>
            {formatDate(created_date, 'DD.MM.YYYY')}
          </StyledArticleAuthorDateText>
        </StyledArticleAuthorDateWrapper>
        <ArticleTags mobile={true} tags={tags} />
      </MobileWrapper>
    </StyledLink>
  );
};

export default TopArticleBanner;
