import * as React from 'react';
import styled from 'styled-components';
import { rem } from 'polished';
import { connect } from 'react-redux';
import { __ } from 'react-i18n';
import MetaTags from '../../components/MetaTags/MetaTags';
import { ThenArg } from '../../services/Zasielkovna-API';
import API from '../../services/API';
import {
  topMenuBrandsSelector,
  langSelector,
} from '../../containers/App/selectors';
import { FlexCol } from 'eshop-defaults';
import { getImagePath } from '../../utilities/product';
import { Link } from 'react-router';
import { getCorrectBrandFromTranslations } from '../../utilities';

interface Props {
  lang: string;
  brands: ThenArg<typeof API.loadBrands>['brands'];
}

const Wrapper = styled.div`
  padding: ${rem(24)} ${rem(20)};
  width: 100%;
  max-width: ${rem(975)};
  margin: 0 auto;
`;

const BrandWrapper = styled(FlexCol)``;

const BrandName = styled.p`
  margin: 0;
  margin-bottom: ${rem(16)};
  color: ${({ theme }) => theme.blogColors.primary};
  /* max-width: ${rem(200)}; */
  text-align: center;
`;

const BrandImage = styled.img`
  max-width: ${rem(300)};
  width: 100%;
  height: auto;
  max-height: ${rem(300)};

  @media (max-width: 440px) {
    max-width: initial;
  }
`;

const BrandsGrid = styled.div`
  display: grid;
  grid-gap: ${rem(32)} ${rem(16)};
  grid-template-columns: repeat(auto-fill, ${rem(300)});

  @media (max-width: 990px) {
    grid-template-columns: repeat(auto-fill, 31%);
  }

  @media (max-width: 580px) {
    grid-template-columns: repeat(auto-fill, 48%);
  }

  @media (max-width: 440px) {
    grid-template-columns: repeat(auto-fill, 100%);
  }
`;

const Title = styled.h2`
  font-size: ${rem(26)};
  color: ${({ theme }) => theme.blogColors.primary};
  margin: 0;
  margin-bottom: ${rem(36)};
  line-height: ${rem(32)};
`;

class BrandsListPage extends React.Component<Props> {
  public render() {
    const { brands, lang } = this.props;

    return (
      <Wrapper>
        <MetaTags tags={{ title: 'Značky' }} />
        <Title>{__('Zoznam dostupných značiek')}</Title>
        {brands ? (
          <BrandsGrid>
            {brands.map(brand => {
              const {
                brand_id,
                brand_name,
                brand_image,
                url,
                translations,
              } = brand;
              const translationObj = getCorrectBrandFromTranslations(
                translations,
                lang,
              );

              let correctImage = brand_image;
              let correctName = brand_name;
              let correctUrl = url;

              if (translationObj) {
                correctImage = translationObj.brand_image || brand_image;
                correctName = translationObj.brand_name || brand_name;
                correctUrl = translationObj.url || url;
              }

              return (
                <BrandWrapper key={brand_id}>
                  <BrandName>{correctName}</BrandName>
                  <Link to={`/znacky/${correctUrl}`}>
                    <BrandImage
                      src={getImagePath(correctImage, {
                        width: 300,
                        height: 0,
                      })}
                      alt={`Obrázok ku značke ${correctName}`}
                    />{' '}
                  </Link>
                </BrandWrapper>
              );
            })}
          </BrandsGrid>
        ) : null}
      </Wrapper>
    );
  }
}

const mapStateToProps = state => ({
  brands: topMenuBrandsSelector(state),
  lang: langSelector(state),
});

export default connect(mapStateToProps)(BrandsListPage);
