import * as React from 'react';
import styled from 'styled-components';
import { rem } from 'polished';
import { FlexRow, ButtonCircle } from 'eshop-defaults';

interface Props {
  fbUrl: string;
  igUrl: string;
  ytUrl: string;
  mobile: boolean;
}

const SocialIcons = ({ fbUrl, igUrl, ytUrl, mobile }: Props) => {
  return (
    <SocialIconsRow mobile={mobile}>
      {!!fbUrl && (
        <SocialIcon>
          <a href={fbUrl} rel="noopener noreferrer" target="_blank">
            <StyledButtonCircle
              imageSize="48px"
              color={'#606060'}
              image={'/images/blog_assets/Facebook48px.svg'}
              clickable={true}
            />
          </a>
        </SocialIcon>
      )}
      {!!igUrl && (
        <SocialIcon>
          <a href={igUrl} rel="noopener noreferrer" target="_blank">
            <StyledButtonCircle
              imageSize="48px"
              color={'#606060'}
              image={'/images/blog_assets/Instagram48px.svg'}
              clickable={true}
            />
          </a>
        </SocialIcon>
      )}
      {!!ytUrl && (
        <SocialIcon>
          <a href={ytUrl} rel="noopener noreferrer" target="_blank">
            <StyledButtonCircle
              imageSize="48px"
              color={'#606060'}
              image={'/images/blog_assets/YouTube48px.svg'}
              clickable={true}
            />
          </a>
        </SocialIcon>
      )}
    </SocialIconsRow>
  );
};

const SocialIconsRow = styled(FlexRow)<{ mobile: boolean }>`
  /* flex: 1; */
  justify-content: flex-end;
  z-index: 2;
  display: ${props => (props.mobile ? 'none' : 'flex')};

  @media only screen and (max-width: 900px) {
    justify-content: flex-end;
  }

  ${({ theme }) => theme.mediab.m580`
    display: ${props => (props.mobile ? 'flex' : 'none')};
    justify-content: center;
    padding: ${rem(32)};
  `};
`;

const SocialIcon = styled.div`
  height: auto;
  margin-left: ${rem(10)};

  @media only screen and (max-width: 580px) {
    margin: 0;
    margin-right: ${rem(16)};
    &:last-child {
      margin-right: 0;
    }
  }
`;

const StyledButtonCircle = styled(ButtonCircle)`
  width: ${rem(48)};
  height: ${rem(48)};
`;

export default SocialIcons;
