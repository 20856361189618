import { combineReducers } from 'redux';
import { routerReducer } from 'react-router-redux';
import { generalReducer } from './containers/App/general-reducer';
import { authReducer } from 'react-auth';
import { homeReducer } from './containers/Home/reducer';
import { authorReducer } from './containers/Author/reducer';
import { ebookReducer } from './containers/Ebook/reducer';
import { articleReducer } from './containers/Article/reducer';
import { categoryReducer } from './containers/Category/reducer';
import { searchReducer } from './containers/Search/reducer';
import { productReducer } from './containers/Product/reducer';
import { ebooksReducer } from './containers/EbooksPage/reducer';
import { articlesWithTagReducer } from './containers/TagsPage/reducer';
import { articlesWithBrandReducer } from './containers/BrandsPage/reducer';
import { fastNewsReducer } from './containers/FastNewsPage/reducer';

/*
  DOCS: Blog reducer
  sitemaps: content(articles) links,
  categories: tree of categories(),
  categoryDetail: detail of current category,
  articleDetail: detail of current article,
  ebookDeatil: detail of current ebook,
  productReviewDetail: detail of current product review,
  authorDetail: detail of current author,
  search: search results,
  homepage: homepage data - 
    topArticle, adBanner, articles, 
    mostReadArticles, recommendedArticles, fastNewsArticles, ebooks
  */

const rootReducer = combineReducers({
  routing: routerReducer,
  auth: authReducer,
  general: generalReducer,
  // ---------- BLOG -------------
  // sitemaps: sitemapReducer,
  // categories: categoriesReducer,
  categoryDetail: categoryReducer,
  articleDetail: articleReducer,
  ebookDetail: ebookReducer,
  productDetail: productReducer,
  authorDetail: authorReducer,
  search: searchReducer,
  homepage: homeReducer,
  ebooks: ebooksReducer,
  fastNews: fastNewsReducer,
  articlesWithTag: articlesWithTagReducer,
  articlesWithBrand: articlesWithBrandReducer,
});

export default rootReducer;

export type StateType<ReducerOrMap> = ReducerOrMap extends (
  ...args: any[]
) => any
  ? ReturnType<ReducerOrMap>
  : ReducerOrMap extends object
  ? { [K in keyof ReducerOrMap]: StateType<ReducerOrMap[K]> }
  : never;

export type State = StateType<typeof rootReducer>;
