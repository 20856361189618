import {
  REQUEST_ARTICLE,
  RECEIVE_ARTICLE_SUCCESS,
  RECEIVE_ARTICLE_ERROR,
} from './constants';
const INITIAL_STATE: any = {
  articleDetail: {
    articleData: {},
    isFetching: false,
  },
};

export const articleReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case REQUEST_ARTICLE: {
      return {
        ...state,
        articleDetail: {
          ...state.articleDetail,
          isFetching: true,
        },
      };
    }

    case RECEIVE_ARTICLE_SUCCESS: {
      return {
        ...state,
        articleDetail: {
          ...state.articleDetail,
          isFetching: false,
          articleData: action.payload.article,
        },
      };
    }

    case RECEIVE_ARTICLE_ERROR: {
      return {
        ...state,
        articleDetail: {
          ...state.articleDetail,
          isFetching: false,
          error: action.payload.error,
        },
      };
    }

    default: {
      return state;
    }
  }
};
