import * as React from 'react';
import { __ } from 'react-i18n/lib';
import styled from 'styled-components';
import rem from 'polished/lib/helpers/rem';
import { getImagePath, getFilePath } from '../../utilities/product';

const Box = styled.div`
  display: flex;
  flex-direction: column;
  height: fit-content;
  padding: 0 48px 0 32px;
  border-left: 1px solid #ddd;
  margin-left: 20px;

  @media only screen and (max-width: 1200px) {
    margin: 0;
    padding: 0 48px 0 0;
    border: none;
  }

  @media only screen and (max-width: 800px) {
    padding: 0 0 0 32px;
    border-left: 1px solid #ddd;
    margin-left: 20px;
  }
  @media only screen and (max-width: 480px) {
    padding: 0;
    margin: 0;
    border: none;
  }
`;

const Download = styled.a`
  text-decoration: none;
  width: 240px;
  height: 51px;
  border: 1px solid #dddddd;
  font: 16px/19px Barlow;
  font-weight: 500;
  letter-spacing: 0;
  color: #414141;
  margin-top: 24px;
  display: flex;
  align-items: center;
  justify-content: center;

  &:hover {
    opacity: 0.8;
    cursor: pointer;
  }

  @media only screen and (max-width: 480px) {
    width: calc(100vw - 40px);
    background-size: 24px;
  }
`;
const Icon = styled.img`
  width: ${rem(24)};
  height: ${rem(24)};
  margin-right: 8px;
`;

const Cover = styled.img`
  width: ${rem(240)};
  height: 339px;
  background: #ffffff 0% 0% no-repeat padding-box;
  box-shadow: 1px 3px 15px #dddddd;

  @media only screen and (max-width: 480px) {
    width: ${rem(200)};
    margin: auto;
  }
`;

const EbookCover = ({ imageLink, downloadLink, downloadSize }) => {
  return (
    <Box>
      <Cover src={getImagePath(imageLink, { width: 240, height: 330 })} />
      {downloadLink && (
        <Download download={true} href={getFilePath(downloadLink)}>
          <Icon alt="" src={'/images/blog_assets/download.svg'} />
          <span>
            {__('Stiahnuť')} {downloadSize} MB
          </span>
        </Download>
      )}
    </Box>
  );
};

export default EbookCover;
