import * as React from 'react';
import { __ } from 'react-i18n/lib';
import styled from 'styled-components';
import rem from 'polished/lib/helpers/rem';
import { getImagePath } from '../../utilities/product';

const Box = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;

  @media only screen and (max-width: 1200px) {
    flex-direction: row;
    margin-bottom: 33px;
  }
  @media only screen and (max-width: 800px) {
    flex-direction: column;
    align-items: center;
  }
  @media only screen and (max-width: 480px) {
    flex-direction: row;
    margin-bottom: 33px;
  }
`;

const AuthorName = styled.div`
  text-align: left;
  font: 16px/20px Barlow;
  letter-spacing: 0;
  color: #414141;
  padding: 16px 0 8px;
  font-weight: 500;

  @media only screen and (max-width: 1200px) {
    padding: 0 0 8px 0;
    font-weight: 500;
  }
  @media only screen and (max-width: 480px) {
    padding: 0 0 6px 0;
    font-weight: 500;
  }
  @media only screen and (max-width: 320px) {
    padding: 0 0 4px 0;
  }
`;

const AuthorDate = styled.div`
  text-align: left;
  font: Regular 16px/20px Barlow;
  letter-spacing: 0;
  color: #aaaaaa;
`;

const ThumbPhoto = styled.img`
  width: ${rem(80)};
  height: ${rem(80)};
  border-radius: 50px;
  background-color: grey;
  @media only screen and (max-width: 480px) {
    width: ${rem(60)};
    height: ${rem(60)};
  }
  @media only screen and (max-width: 320px) {
    width: ${rem(40)};
    height: ${rem(40)};
  }
`;

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;

  @media only screen and (max-width: 1200px) {
    padding-left: 16px;
    align-items: flex-start;
  }
  @media only screen and (max-width: 800px) {
    padding: 0 0 0 16px;
    align-items: center;
  }
  @media only screen and (max-width: 480px) {
    padding-left: 16px;
    align-items: flex-start;
  }
  @media only screen and (max-width: 320px) {
    padding-left: 8px;
  }
`;

const EbookAuthorTab = ({ createdAt, authorImage, authorName }) => {
  return (
    <Box>
      {authorImage && authorImage !== '' && (
        <ThumbPhoto
          src={getImagePath(authorImage, { width: 80, height: 80 })}
          alt={`Fotka ${authorName}`}
        />
      )}
      <Wrapper>
        {authorName && authorName !== '' && (
          <AuthorName>{authorName}</AuthorName>
        )}
        <AuthorDate>{createdAt}</AuthorDate>
      </Wrapper>
    </Box>
  );
};

export default EbookAuthorTab;
