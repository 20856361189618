import * as React from 'react';
import styled from 'styled-components';
import { rem } from 'polished';
import { connectSsr } from 'ssr-service';
import { connect } from 'react-redux';
import { __ } from 'react-i18n';
import Pagination from '../../components/Pagination/Pagination';

import MetaTags from '../../components/MetaTags/MetaTags';

import { FlexCol, FlexRowCenterVertical } from 'eshop-defaults';
import { ThenArg } from '../../services/Zasielkovna-API';
import API from '../../services/API';
import ArticlesWithSidebar from '../../components/Home/ArticlesWithSidebar';
import SidebarArticlesWrapper from '../../components/Home/SidebarArticlesWrapper';
import { push } from 'react-router-redux';
import { withRouter, WithRouterProps } from 'react-router';
import {
  recommendedArticlesSelector,
  mostReadArticlesSelector,
  ebooksSelector,
  recommendedArticlesOptionsSelector,
} from '../Home/selectors';
import { langSelector } from '../Category/selectors';
import {
  fastNewsTotalSelector,
  fastNewsIsFetchingSelector,
  fastNewsOffsetSelector,
  fastNewsDataSelector,
} from './selectors';
import {
  loadHomeMostRead,
  loadHomeRecommended,
  loadHomeEbooks,
} from '../Home/actions';
import { loadFastNews } from './actions';
import ArticleList from '../../components/Article/ArticleList';

interface Props {
  offset: number;
  lang: string;
  location: {
    query: {
      offset?: number;
    };
    pathname: string;
  };
  dispatch: (action: any) => void;
  mostRead: {
    isFetching: boolean;
    mostReadArticles: ThenArg<typeof API.loadArticles>;
    filter?: string;
  };
  recommendedOptions: ThenArg<typeof API.loadSitemapSubtree>['sitemap_tree'];
  recommended: {
    isFetching: boolean;
    recommendedArticles: ThenArg<typeof API.loadArticles>;
    filter?: string;
  };
  ebooks: {
    isFetching: boolean;
    ebooksData: ThenArg<typeof API.loadArticles>;
  };
  isFetching: boolean;
  fastNewsTotal: number;
  fastNews: any;
}
// Styled Components

const Wrapper = styled.div`
  padding: ${rem(24)} ${rem(20)};

  ${({ theme }) => theme.mediab.m580`
     padding: 0;
  `}
`;

const Header = styled(FlexCol)`
  width: 100%;
  padding-bottom: ${rem(32)};
  margin-bottom: ${rem(2)};
  border-bottom: ${rem(1)} solid ${({ theme }) => theme.blogColors.border};

  ${({ theme }) => theme.mediab.m580`
     padding: 0;
     border: 0;
  `}
`;

const SearchedTermWrapper = styled(FlexRowCenterVertical)`
  color: ${({ theme }) => theme.blogColors.primary};
  font-size: ${rem(32)};
  font-family: ${({ theme }) => theme.blogFonts.primaryFont};
  font-weight: 500;
  text-transform: uppercase;

  ${({ theme }) => theme.mediab.m580`
     font-size: ${rem(20)};
     margin-bottom: ${rem(16)};
  `}
`;

class FastNewsPage extends React.Component<Props & WithRouterProps> {
  public static async getInitialProps(props: Props) {
    const { dispatch, location } = props;
    const {
      query: { offset },
    } = location;

    await dispatch(loadFastNews(10, offset));
    await dispatch(loadHomeMostRead());
    await dispatch(loadHomeRecommended(null));
    await dispatch(loadHomeEbooks());

    return {};
  }

  public getMainContent() {
    const { offset, fastNews, lang, isFetching, fastNewsTotal } = this.props;

    return (
      <>
        <Header>
          <SearchedTermWrapper>{__('Zoznam bleskoviek')}</SearchedTermWrapper>
        </Header>
        <FlexCol>
          <ArticleList
            lang={lang}
            articles={fastNews}
            isFetching={isFetching}
          />
          <Pagination
            query={this.getPaginationQuery()}
            totalItems={fastNewsTotal}
            limit={10}
            offset={offset}
            onOffsetChange={this.handleOffsetChange}
          />
        </FlexCol>
      </>
    );
  }

  public getSidebarContent() {
    const {
      mostRead,
      recommended,
      recommendedOptions,
      lang,
      ebooks,
    } = this.props;

    const mostReadFilter = [
      { name: __('TÝŽDEŇ'), value: 'week' },
      { name: __('MESIAC'), value: 'month' },
      { name: __('ROK'), value: 'year' },
    ];
    const recommendedFilter =
      recommendedOptions &&
      recommendedOptions.map(o => ({
        name: o.name || '',
        value: o.id ? o.id.toString() : '',
      }));

    return (
      <SidebarArticlesWrapper
        // showing no fast news in sidebar on fast news page
        fastNews={[]}
        mostRead={mostRead}
        recommended={recommended}
        ebooks={ebooks}
        mostReadFilter={mostReadFilter}
        recommendedFilter={recommendedFilter}
        dispatch={this.props.dispatch}
        lang={lang}
      />
    );
  }

  public render() {
    return (
      <Wrapper>
        <MetaTags tags={{ title: `Zoznam bleskoviek` }} />
        <ArticlesWithSidebar
          mainContentChildren={this.getMainContent()}
          sidebarChildren={this.getSidebarContent()}
        />
      </Wrapper>
    );
  }

  public handleOffsetChange = async (newOffset: number) => {
    this.props.dispatch(
      push({
        pathname: this.props.location.pathname,
        search: `?offset=${newOffset}`,
      }),
    );
  };

  public getPaginationQuery = () => {
    return `${this.props.location.pathname}?`;
  };
}

const mapStateToProps = state => ({
  offset: fastNewsOffsetSelector(state),
  mostRead: mostReadArticlesSelector(state),
  recommended: recommendedArticlesSelector(state),
  recommendedOptions: recommendedArticlesOptionsSelector(state),
  ebooks: ebooksSelector(state),
  fastNews: fastNewsDataSelector(state),
  fastNewsTotal: fastNewsTotalSelector(state),
  isFetching: fastNewsIsFetchingSelector(state),
  lang: langSelector(state),
});

export default connect(mapStateToProps)(
  connectSsr({ displayName: 'FastNewsPage' })(withRouter(FastNewsPage)),
);
