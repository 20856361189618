import React, { useState } from 'react';
import styled from 'styled-components';
import {
  // FlexRowCenterVertical,
  FlexCol,
  FlexRowCenter,
  FlexRow,
  FlexRowCenterVertical,
} from 'eshop-defaults';
import { rem } from 'polished';
import { Link } from 'react-router';
import ArticleTags from '../Article/ArticleTags';
import OutsideClick from '../_helpers/Default/OutsideClick';
import { __ } from 'react-i18n';
import MobileNavMenuItem from './MobileNavMenuItem';
import { getImagePath } from '../../utilities/product';

const TagsWrapper = styled.div`
  margin-left: ${rem(16)};
  margin-top: ${rem(24)};
`;

const Wrapper = styled(FlexRow)`
  width: 100%;
  position: absolute;
  min-height: ${rem(560)};
  background-color: ${({ theme }) => theme.blogColors.primary};
  z-index: 95000;
`;

const NavWrapper = styled(FlexCol)`
  width: 100%;
  padding-top: ${rem(12)};
`;

const CloseIconWrapper = styled(FlexRowCenter)`
  padding: ${rem(24)};
  background-color: ${({ theme }) => theme.blogColors.secondary};
  cursor: pointer;
  max-height: ${rem(72)};

  &:hover {
    opacity: 0.8;
  }
`;

const CloseIcon = styled.img`
  width: ${rem(24)};
  height: ${rem(24)};
  max-width: initial;
`;

const SubMenu = styled(FlexCol)``;

const BackWrapper = styled(FlexRowCenterVertical)`
  cursor: pointer;
  padding: ${rem(20)} ${rem(16)};
`;

const BackIcon = styled.img`
  width: ${rem(24)};
  height: ${rem(24)};
  margin-right: ${rem(8)};
`;

const SubMenuInfoWrapper = styled(FlexCol)`
  padding: ${rem(16)};
`;

const SubMenuImage = styled.img`
  width: ${rem(80)};
  height: ${rem(80)};
  margin-bottom: ${rem(24)};
`;

const SubMenuAnnot = styled.div`
  margin: 0;
  margin-bottom: ${rem(24)};
`;

const SubMenuLink = styled(Link)`
  color: white;
  width: 100%;
`;

const SubCategoryTitle = styled.p`
margin: 0;
font-weight: 500;
font-size: ${rem(20)};
color: white;
/* font-size: ${({ theme }) => theme.blogColors} */
`;

const NavItem = styled.div`
  justify-content: space-between;
  display: flex;
  align-items: center;

  padding: ${rem(12)} ${rem(16)};
  font-size: ${rem(20)};
  font-weight: 500;
  color: white;
  cursor: pointer;
  text-decoration: none;

  &:hover a,
  &:focus a {
    opacity: 0.7;
  }
`;

// const SubNavItem = styled(NavItem)`
//   font-weight: 400;
//   font-size: ${rem(18)};
// `;

const StyledLink = styled(Link)`
  text-decoration: none;
  color: white;
  width: 100%;
`;

const RightArrowIcon = styled.img`
  width: ${rem(16)};
  height: ${rem(16)};
`;

const GrayRectangle = styled.div`
  content: '';
  height: 100%;
  width: 72px;
  background-color: aliceblue;
  /* background-color: ${({ theme }) => theme.blogColors.primary}; */
  opacity: 0.5;
`;

function MobileNavMenu({
  navItems,
  toggleResponsiveCategoryMenu,
  brands,
  pathname,
}) {
  const [navChildrenItems, setNavChildrenItems] = useState<any>({
    title: null,
    level: 0,
    children: [],
    tags: [
      { name: 'JBL', id: 1020 },
      { name: 'Bluetooth', id: 1015 },
      { name: 'Reproduktor', id: 1161 },
    ],
  });

  const showCategoryChildren = subCategory => {
    const { title, children, type, annot, img, url } = subCategory;
    setNavChildrenItems({
      title,
      children,
      annot,
      img,
      url,
      tags: navChildrenItems.tags,
      level: navChildrenItems.level + 1,
      prevState: navChildrenItems,
      type,
    });
  };

  const goBackToParent = () => {
    setNavChildrenItems(navChildrenItems.prevState);
  };

  const limitedBrands = brands;

  return (
    <OutsideClick handleFunction={toggleResponsiveCategoryMenu}>
      <Wrapper>
        <NavWrapper>
          {navChildrenItems.level === 0 && (
            <NavItem key={'bleskovky'}>
              <StyledLink
                onClick={toggleResponsiveCategoryMenu}
                to={'/bleskovky'}
              >
                {__('Bleskovky')}
              </StyledLink>
            </NavItem>
          )}
          {navChildrenItems.level === 0 ? (
            navItems &&
            navItems.map(item => (
              <MobileNavMenuItem
                pathname={pathname}
                isParent={true}
                item={item}
                toggleResponsiveCategoryMenu={toggleResponsiveCategoryMenu}
                showCategoryChildren={showCategoryChildren}
              />
            ))
          ) : (
            <SubMenu>
              <BackWrapper onClick={goBackToParent}>
                <BackIcon
                  src="/images/blog_assets/arrow_back_white.svg"
                  alt=""
                />
                <SubCategoryTitle onClick={goBackToParent}>
                  {navChildrenItems.title}
                </SubCategoryTitle>
              </BackWrapper>
              {navChildrenItems.annot && (
                <SubMenuInfoWrapper>
                  <SubMenuImage
                    src={getImagePath(navChildrenItems.img, {
                      width: 80,
                      height: 80,
                    })}
                  />
                  <SubMenuAnnot
                    dangerouslySetInnerHTML={{ __html: navChildrenItems.annot }}
                  />
                  <SubMenuLink
                    onClick={toggleResponsiveCategoryMenu}
                    to={`/${navChildrenItems.url}`}
                  >
                    {__('Viac informácií')}
                  </SubMenuLink>
                </SubMenuInfoWrapper>
              )}
              {!navChildrenItems.annot && navChildrenItems.type === 'withDesc'
                ? navChildrenItems.children.map(item => (
                    <MobileNavMenuItem
                      key={item.brand_id}
                      item={{
                        name: item.brand_name,
                        annot: item.brand_descr,
                        url: `znacky/${item.url}`,
                        img: item.brand_image,
                        type: 'desc',
                      }}
                      toggleResponsiveCategoryMenu={
                        toggleResponsiveCategoryMenu
                      }
                      showCategoryChildren={showCategoryChildren}
                    />
                  ))
                : navChildrenItems.children &&
                  navChildrenItems.children.map(item => (
                    <MobileNavMenuItem
                      key={item.id}
                      item={item}
                      toggleResponsiveCategoryMenu={
                        toggleResponsiveCategoryMenu
                      }
                      showCategoryChildren={showCategoryChildren}
                    />
                  ))}
            </SubMenu>
          )}
          {navChildrenItems.level === 0 && (
            <NavItem key={'ebooky'}>
              <StyledLink onClick={toggleResponsiveCategoryMenu} to={'/ebooky'}>
                {__('Ebooky')}
              </StyledLink>
            </NavItem>
          )}
          {!navChildrenItems.annot &&
            navChildrenItems.level !== 0 &&
            navChildrenItems.type !== 'withDesc' && (
              <TagsWrapper>
                <ArticleTags
                  topArticle={true}
                  isNav={true}
                  tags={navChildrenItems.tags}
                  afterTagClick={toggleResponsiveCategoryMenu}
                />
              </TagsWrapper>
            )}
          {navChildrenItems.level === 0 && (
            <NavItem key={'znacky'}>
              <StyledLink onClick={toggleResponsiveCategoryMenu} to={'/znacky'}>
                {__('Značky')}
              </StyledLink>
              {limitedBrands && limitedBrands.length > 0 && (
                <RightArrowIcon
                  // tslint:disable-next-line:jsx-no-lambda
                  onClick={() =>
                    showCategoryChildren({
                      children: limitedBrands,
                      title: __('Značky'),
                      type: 'withDesc',
                    })
                  }
                  src="/images/blog_assets/right-arrow-white.svg"
                  alt=""
                />
              )}
            </NavItem>
          )}
        </NavWrapper>
        <FlexCol>
          <CloseIconWrapper onClick={toggleResponsiveCategoryMenu}>
            <CloseIcon src="/images/blog_assets/close.svg" alt="" />
          </CloseIconWrapper>
          <GrayRectangle onClick={toggleResponsiveCategoryMenu} />
        </FlexCol>
      </Wrapper>
    </OutsideClick>
  );
}

export default MobileNavMenu;
