import * as React from 'react';
import * as ReactDOM from 'react-dom';
import { LanguageProvider } from 'react-i18n';
import { browserHistory } from 'react-router';
import { Provider } from 'react-redux';
import 'balloon-css/balloon.min.css';
import { syncHistoryWithStore } from 'react-router-redux';
import { ThemeProvider } from 'styled-components';
import './theme/globalStyles';
import configureStore from './configureStore';
import Routes from './routes';
import { getProp } from './utilities/';
import ErrorBoundary from './containers/Errors/ErrorBoundary';
import { loadTranslations, resolveLangFromCookies } from './configureTrans';
import { FullscreenLoading } from 'eshop-defaults';
import { default as API } from './services/API';
import { theme } from './theme/theme';
import { isSSR } from '@bart.sk-ecommerce/react-eshop-redirects/lib/action';
import ReactGA from 'react-ga4';

declare global {
  interface Window {
    __INIT_DATA__: any;
    __REDUX_STATE__: any;
    __ANALYTICS_CONFIG_DATA__: any;
  }
}

API.setBaseUrl(process.env.REACT_APP_API_BASE_URL || '');

const reduxState = getProp(window, '__REDUX_STATE__');
if (!reduxState || reduxState === '{{SSR_INITIAL_STATE}}') {
  window.__REDUX_STATE__ = {};
}
const store = configureStore(window.__REDUX_STATE__);
const historyObj = syncHistoryWithStore(browserHistory, store);

if (!isSSR()) {
  if (
    window &&
    window.__REDUX_STATE__ &&
    window.__REDUX_STATE__.general &&
    window.__ANALYTICS_CONFIG_DATA__ &&
    window.__ANALYTICS_CONFIG_DATA__[
      `Google_analyticsCode-${window.__REDUX_STATE__.general.lang || '-'}-0`
    ]
  ) {
    ReactGA.initialize(
      window.__ANALYTICS_CONFIG_DATA__[
        `Google_analyticsCode-${window.__REDUX_STATE__.general.lang || '-'}-0`
      ],
    );
  }
}

const loadTranslationsData = () => loadTranslations(window.location);
const lang = resolveLangFromCookies('');

if (typeof window !== 'undefined') {
  ReactDOM.render(
    <ThemeProvider theme={theme}>
      <LanguageProvider
        translations={window.__INIT_DATA__.translations}
        loadCatalogues={loadTranslationsData}
        loadingContainer={<FullscreenLoading />}
      >
        <ErrorBoundary>
          <Provider store={store}>
            <Routes history={historyObj} lang={lang} />
          </Provider>
        </ErrorBoundary>
      </LanguageProvider>
    </ThemeProvider>,
    document.getElementById('root'),
  );
}

export { store };
