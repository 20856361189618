import React, { useState } from 'react';
import { __ } from 'react-i18n/lib';
import styled from 'styled-components';
import { rem } from 'polished';
import { FlexCol, FlexRowCenterVertical } from 'eshop-defaults';
import { Link } from 'react-router';
import { PrimaryButton } from '../_helpers/Default/Buttons';
import { PrimaryInput } from '../_helpers/Default/Inputs';
import API from '../../services/API';
import { Loader } from '../_helpers/Loader/Loader';

const Wrapper = styled(FlexRowCenterVertical)`
  justify-content: flex-start;
  padding: ${rem(24)} ${rem(32)};
  margin-bottom: ${rem(48)};
  max-width: ${rem(1032)};
  width: 100%;
  background-color: ${({ theme }) => theme.blogColors.primary};
  height: ${rem(260)};

  @media only screen and (max-width: 1200px) {
    height: initial;
  }

  ${({ theme }) => theme.mediab.m580`
   display: flex;
   margin-bottom: 0;
   height: initial;
   padding: ${rem(32)} ${rem(16)};
   border-bottom: ${rem(1)} solid ${theme.blogColors.darkGray};
`};
`;

const Image = styled.img`
  width: ${rem(300)};
  height: ${rem(285)};
  margin-bottom: ${rem(24)};

  @media only screen and (max-width: 1050px) {
    display: none;
  }

  ${({ theme }) => theme.mediab.m760`
   display: none;
  `};
`;

const Right = styled(FlexCol)`
  width: 70%;
  margin-right: ${rem(10)};

  @media only screen and (max-width: 1050px) {
    width: 100%;
  }

  ${({ theme }) => theme.mediab.m580`
   width: 100%;
`};
`;

const Title = styled.h2`
  margin: 0;
  margin-bottom: ${rem(24)};
  font-family: ${({ theme }) => theme.blogFonts.primaryFont};
  font-size: ${rem(24)};
  width: 100%;
  color: white;
  font-weight: 500;
  line-height: 32px;

  ${({ theme }) => theme.mediab.m580`
  line-height: 28px;
    text-align: center;
   font-size: ${rem(20)};
  `};
`;

const SuccessfulWrapper = styled(FlexRowCenterVertical)``;

const SuccessfulText = styled.p`
  font-size: ${rem(16)};
`;

const SuccessfulImage = styled.img`
  margin-left: ${rem(8)};
`;

const FormWrapper = styled.form`
  display: flex;
  flex-flow: row;
  align-items: center;
  margin-bottom: ${rem(24)};
  width: 100%;

  ${({ theme }) => theme.mediab.m580`
   flex-flow: column;
  `};
`;

const EmailInput = styled(PrimaryInput)`
  margin-right: ${rem(8)};

  ${({ theme }) => theme.mediab.m580`
    margin: 0;
    margin-bottom: ${rem(8)};
    max-width: initial;
`};
`;

const SubscribeButton = styled(PrimaryButton)`
  height: 50px;

  ${({ theme }) => theme.mediab.m580`
   width: 100%;
`};
`;

const BottomText = styled.div`
  font-family: ${({ theme }) => theme.blogFonts.primaryFont};
  font-size: ${rem(16)};
  width: 80%;
  color: ${({ theme }) => theme.blogColors.gray};
  line-height: 24px;

  @media only screen and (max-width: 1050px) {
    width: 100%;
  }

  ${({ theme }) => theme.mediab.m580`
  line-height: 22px;
    width: 100%;
    text-align: center;
  `};
`;

const BottomTextLink = styled(Link)`
  text-decoration: underline;
  color: ${({ theme }) => theme.blogColors.gray} !important;
  line-height: 24px;

  &:hover {
    opacity: 0.8;
  }
`;

const NewsletterForm = () => {
  const [email, setEmail] = useState('');
  const [isFetching, setIsFetching] = useState(false);
  const [isSubscribed, setIsSubscribed] = useState(false);

  const emailIsValid = () => {
    const emailRegex = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return email.length > 0 && emailRegex.test(email.toLowerCase());
  };

  const submitNewsletter = async e => {
    e.preventDefault();
    setIsFetching(true);

    const subscribe = await API.subscribeNewsletter(
      { engine: 'mailchimp' },
      {
        email,
        first_name: '',
        last_name: '',
        source: '',
        status: '',
      },
    );

    setIsFetching(false);
    if (subscribe && email) {
      setIsSubscribed(true);
    }
  };

  return (
    <Wrapper>
      <Right>
        <Title>
          {__('Dostávajte najčerstvejšie informácie zo sveta technológií')}
        </Title>
        {isSubscribed ? (
          <SuccessfulWrapper>
            <SuccessfulText>
              {__('Prihlásenie na odber noviniek bolo úspešné.')}
            </SuccessfulText>
            <SuccessfulImage src="images/done.svg" alt="" />
          </SuccessfulWrapper>
        ) : (
          <>
            <FormWrapper>
              <EmailInput
                // tslint:disable-next-line:jsx-no-lambda
                onChange={e => setEmail(e.target.value)}
                type="email"
                placeholder="E-mailová adresa"
              />
              <SubscribeButton
                disabled={!emailIsValid()}
                type="submit"
                onClick={submitNewsletter}
              >
                {isFetching ? <Loader small={true} /> : __('Odoberať')}
              </SubscribeButton>
            </FormWrapper>
            <BottomText>
              {__('Odoslaním e-mailu súhlasite s podmienkami o ')}
              <BottomTextLink to="/">
                {__('ochrane osobných údajov')}
              </BottomTextLink>
              {'.'}
              {__(' Zo zasielania sa môžete kedykoľvek odhlásiť.')}
            </BottomText>
          </>
        )}
      </Right>
      <Image
        alt="obrazok ku newslettru"
        src="images/blog_assets/newsletter.png"
      />
    </Wrapper>
  );
};

export default NewsletterForm;
