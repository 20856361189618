import { createSelector } from 'reselect';
import { prop } from 'eshop-defaults';

export const ebookDomainSelector = state => state.ebookDetail || {};

export const ebookDataSelector = createSelector(
  ebookDomainSelector,
  substate => prop(substate, 'ebookData', ''),
);

export const ebookIsFetchingSelector = createSelector(
  ebookDomainSelector,
  substate => prop(substate, 'isFetching', false),
);

export const ebookErrorSelector = createSelector(
  ebookDomainSelector,
  substate => prop(substate, 'error'),
);
