import APIClass from '../../services/API';
import {
  REQUEST_AUTHOR,
  RECEIVE_AUTHOR_SUCCESS,
  RECEIVE_AUTHOR_ERROR,
  SET_AUTHOR_FILTERS,
} from './constants';
import {
  RECEIVE_HOME_ARTICLES_ERROR,
  RECEIVE_HOME_ARTICLES_SUCCESS,
  REQUEST_HOME_ARTICLES,
} from '../Home/constants';
import { langSelector } from '../Category/selectors';

const requestAuthor = () => ({
  type: REQUEST_AUTHOR,
});

const receiveAuthorSuccess = author => ({
  type: RECEIVE_AUTHOR_SUCCESS,
  payload: {
    author,
  },
});

const receiveAuthorError = error => ({
  type: RECEIVE_AUTHOR_ERROR,
  payload: {
    error,
  },
});

export const loadAuthor = (authorId, offset = 0) => {
  return async (dispatch, getState, API) => {
    try {
      dispatch(requestAuthor());
      dispatch(setAuthorFilters({ offset }));
      const lang: string = langSelector(getState());

      const author = await APIClass.loadAuthorDetail(
        authorId,
        {
          limit: 10,
          offset,
        },
        { xAcceptLanguage: lang },
      );

      dispatch(receiveAuthorSuccess(author));
    } catch (e) {
      dispatch(receiveAuthorError(e));
    }
  };
};

export const setAuthorFilters = filters => ({
  type: SET_AUTHOR_FILTERS,
  payload: {
    filters,
  },
});

const requestHomeArticles = () => ({
  type: REQUEST_HOME_ARTICLES,
});

const receiveHomeArticlesSuccess = articles => ({
  type: RECEIVE_HOME_ARTICLES_SUCCESS,
  payload: {
    articles,
  },
});

const receiveHomeArticlesError = error => ({
  type: RECEIVE_HOME_ARTICLES_ERROR,
  payload: {
    error,
  },
});

export const loadHomeArticles = () => {
  return async (dispatch, getState, API) => {
    try {
      dispatch(requestHomeArticles());
      const articles = await APIClass.loadArticles({
        sitemapId: 135,
        onlyActual: '1',
        isPublished: '1',
        tagExcludeId: 1518,
        sort: 'created_date',
        replaceCurrentLangTags: '1',
      });
      dispatch(receiveHomeArticlesSuccess(articles));
    } catch (e) {
      dispatch(receiveHomeArticlesError(e));
    }
  };
};
