import { prop } from 'eshop-defaults/lib';
import { stripHtmlTags } from '.';
import { getImagePath } from './product';

export function isEmptyObj(obj) {
  if (obj) {
    return Object.entries(obj).length === 0 && obj.constructor === Object;
  }
  return true;
}

export function getCmsMetaTags(contentData: any) {
  const content = prop(contentData, 'json_content');
  if (!content) {
    return {};
  }

  let metaTags: any = {};
  if (content && content.meta) {
    metaTags = content.meta;
  }
  if (!metaTags || !metaTags.title || !metaTags.title.length) {
    metaTags.title = content && content.name;
  }
  if (
    !metaTags ||
    !metaTags.og_description ||
    Object.keys(metaTags.og_description).length === 0
  ) {
    metaTags.description =
      content && content.annotation ? stripHtmlTags(content.annotation) : '';
  }

  const ogTitle =
    prop(content, 'meta.og_title') ||
    prop(content, 'meta.title') ||
    prop(content, 'name');
  if (ogTitle) {
    metaTags.og_title = ogTitle;
  }

  const ogDescription =
    prop(content, 'meta.og_description') ||
    prop(content, 'meta.description') ||
    prop(content, 'annotation');
  if (ogDescription) {
    metaTags.og_description = ogDescription ? stripHtmlTags(ogDescription) : '';
  }

  const ogImage =
    prop(content, 'meta.og_image') ||
    prop(contentData, 'photogallery.0.document_path');
  if (ogImage) {
    metaTags.og_image = getImagePath(ogImage, { width: 1000, height: 1000 });
  }

  return metaTags;
}

export function getEbookMetaTags(ebook: any) {
  const content = prop(ebook, 'json_content');
  let metaTags: any = {};
  if (content && content.meta) {
    metaTags = content.meta;
  }

  if (!metaTags || !metaTags.title || !metaTags.title.length) {
    metaTags.title = content && content.name;
  }

  return metaTags;
}

export function getAuthorMetaTags(author: any) {
  const content = prop(author, 'json_content');
  let metaTags: any = {};
  if (content && content.meta) {
    metaTags = content.meta;
  }

  if (!metaTags || !metaTags.title || !metaTags.title.length) {
    metaTags.title = content && content.name;
  }

  return metaTags;
}

export function getProductMetaTags(product) {
  const productPublish = prop(product, 'publish', [])[0];
  let metaTags: any = {};
  if (productPublish && productPublish.content.id !== null) {
    metaTags = prop(productPublish.content.json_content, 'meta');

    if (!metaTags.title.length) {
      metaTags.title = product.name;
    }
  }

  return metaTags;
}

export function getProductMetaTagsFromWebcontent(webContent) {
  let metaTags: any = {};
  metaTags = prop(webContent.json_content, 'meta');

  if (!metaTags || !metaTags.title || !metaTags.title.length) {
    metaTags.title = webContent.name;
  }

  return metaTags;
}

export function getCategoryMetaTags(category) {
  // const content = prop(category, 'content', {});
  const metaTags: any = {};
  // if (content && content.id !== null) {
  //   const tags = prop(content.json_content, 'meta');
  //   if (tags && !isEmptyObj(tags)) {
  //     metaTags = tags;
  //   }
  // }

  if (!metaTags.title || isEmptyObj(metaTags.title)) {
    metaTags.title = category.name;
  }

  return metaTags;
}

export function getFooterArticleMetaTags(article) {
  console.log({ article });
  // const content = prop(category, 'content', {});
  const metaTags: any = {};
  // if (content && content.id !== null) {
  //   const tags = prop(content.json_content, 'meta');
  //   if (tags && !isEmptyObj(tags)) {
  //     metaTags = tags;
  //   }
  // }

  if (article && (!metaTags.title || isEmptyObj(metaTags.title))) {
    metaTags.title = article.name;
  }

  return metaTags;
}
