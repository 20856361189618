import * as React from 'react';
import { __ } from 'react-i18n';
import Footer from '../../components/Footer/Footer';
import { connectSsr } from 'ssr-service';
import API from '../../services/API';
import { connect } from 'react-redux';
import { prop } from 'eshop-defaults';
import { langSelector, contactInfoSelector } from '../App/selectors';

interface Props {
  lang: string;
  sitemap: any;
  phone: string;
  contactInfo: {
    contactEmail: string;
    contactTel: string;
  };
  socialUrls: {
    fbUrl: string;
    igUrl: string;
    ytUrl: string;
  };
}

class FooterContainer extends React.Component<Props> {
  public static async getInitialProps(parentProps) {
    try {
      const treeId = 'PATICKA';
      const [sitemap] = await Promise.all([
        await API.loadSitemapSubtree(
          treeId,
          {},
          {
            xAcceptLanguage: parentProps.lang,
          },
        ),
      ]);

      return {
        sitemap,
        parentProps,
        loaded: true,
      };
    } catch (exp) {
      return {
        isError: true,
      };
    }
  }

  public render() {
    const {
      sitemap,
      contactInfo: { contactEmail: email, contactTel: phone },
      socialUrls,
    } = this.props;

    const sitemapArrays = prop(sitemap, 'sitemap_tree', []);
    const navItems = sitemapArrays.map(item => {
      return { name: item.name, link: item.url };
    });

    return (
      <Footer
        socialUrls={socialUrls}
        phone={phone}
        email={email}
        navItems={navItems}
      />
    );
  }
}

const mapStateToProps = state => {
  return {
    lang: langSelector(state),
    contactInfo: contactInfoSelector(state), // state.general.contactInfo.contactEmail,
  };
};

export default connect(mapStateToProps)(
  connectSsr({ displayName: 'FooterContainer' })(FooterContainer),
);
