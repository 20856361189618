import { createSelector } from 'reselect';
import { prop } from 'eshop-defaults';

export const fastNewsDomainSelector = state => state.fastNews || {};

export const fastNewsDataSelector = createSelector(
  fastNewsDomainSelector,
  substate => prop(substate, 'articles', []),
);

export const fastNewsIsFetchingSelector = createSelector(
  fastNewsDomainSelector,
  substate => prop(substate, 'isFetching', false),
);

export const fastNewsErrorSelector = createSelector(
  fastNewsDomainSelector,
  substate => prop(substate, 'error'),
);

export const fastNewsOffsetSelector = createSelector(
  fastNewsDomainSelector,
  substate => prop(substate, 'offset', 0),
);

export const fastNewsTotalSelector = createSelector(
  fastNewsDomainSelector,
  substate => prop(substate, 'total', 0),
);
