import * as React from 'react';
import { ReactNode } from 'react';
import styled, { css } from 'styled-components';
import { darken, rem } from 'polished';

export const ButtonWrapper = styled.button`
  background: transparent;
  border: none;
  font-family: inherit;
  font-size: 100%;
  line-height: 1.15;
  padding: 0;
  margin: 0;
  vertical-align: middle;
  cursor: pointer;
  /* &:not(:disabled) {
    cursor: pointer;
  } */
  &:disabled {
    cursor: not-allowed;
  }
`;

export const ButtonBlueStyles = css`
  display: block;
  background: ${({ theme }) => theme.color.link};
  border-radius: ${rem(4)};
  color: ${({ theme }) => theme.color.white};
  font-size: ${rem(14)};
  line-height: 1;
  text-align: center;
  padding: ${rem(13)} ${rem(5)};
  margin-top: ${rem(20)};
  ${({ theme }) => theme.transition('background-color')};
  &:hover {
    background: ${({ theme }) => darken(0.1, theme.color.link)};
  }
`;

export const ButtonLoadMoreStyles = css`
  display: block;
  background: ${({ theme }) => theme.color.backgroundDark};
  border-radius: ${rem(4)};
  color: ${({ theme }) => theme.color.white};
  font-family: ${({ theme }) => theme.font.secondary};
  font-size: ${rem(16)};
  font-weight: 700;
  line-height: 1;
  text-transform: uppercase;
  padding: ${rem(22)} ${rem(10)};
  margin: ${rem(40)} auto;
  width: ${rem(290)};
  max-width: 100%;
  cursor: pointer;
  ${({ theme }) => theme.transition('background-color')};
  &:hover {
    background: ${({ theme }) => theme.color.brand};
  }
`;

export const BigBrandButton = css`
  background: ${({ theme }) => theme.color.brand};
  border-radius: ${rem(4)};
  color: ${({ theme }) => theme.color.white};
  font-family: ${({ theme }) => theme.font.secondary};
  font-size: ${rem(20)};
  font-weight: 700;
  text-transform: uppercase;
  padding: ${rem(18)} ${rem(16)} ${rem(19)};
  width: ${rem(290)};
  max-width: 100%;
  ${({ theme }) => theme.transition('background-color')};
  &:hover {
    background: ${({ theme }) => darken(0.1, theme.color.brand)};
  }

  &:focus {
    outline: none;
  }
`;

export interface ButtonIO {
  disabled?: boolean;
  children?: ReactNode;
  className?: string;
  type?: string;
  onClick?: any;
}

class Button extends React.Component<ButtonIO> {
  public render() {
    const { disabled, children, className, onClick } = this.props;

    return (
      <ButtonWrapper
        disabled={disabled}
        className={className}
        onClick={onClick}
      >
        {children}
      </ButtonWrapper>
    );
  }
}

export default Button;
