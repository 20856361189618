import { createSelector } from 'reselect';
import { prop } from 'eshop-defaults';

export const authorDomainSelector = state => state.authorDetail || {};

export const authorDataSelector = createSelector(
  authorDomainSelector,
  substate => prop(substate, 'authorDetail.authorData'),
);

export const authorFetchingSelector = createSelector(
  authorDomainSelector,
  substate => prop(substate, 'authorDetail.isFetching', false),
);

export const authorArticlesOffsetSelector = createSelector(
  authorDomainSelector,
  substate => prop(substate, 'authorDetail.offset', 0),
);

export const authorErrorSelector = createSelector(
  authorDomainSelector,
  substate => prop(substate, 'error'),
);

export const authorArticlesSelector = createSelector(
  authorDomainSelector,
  substate =>
    prop(substate, 'articles', { articlesData: [], isFetching: false }),
);
