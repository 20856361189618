import { createSelector } from 'reselect';
import { prop } from '../../utilities';

export const searchDomainSelector = state => state.search;

export const searchedProductsSelector = createSelector(
  searchDomainSelector,
  substate => prop(substate, 'searchedProducts', null),
);

export const searchParamsSelector = createSelector(
  searchDomainSelector,
  substate => prop(substate, 'searchParams', null),
);

export const searchIsFetchingSelector = createSelector(
  searchDomainSelector,
  substate => prop(substate, 'isFetching', false),
);
