import styled from 'styled-components';
import { rem } from 'polished';

const Input = styled.input`
  padding: ${rem(16)};
  height: ${rem(50)};
  background-color: white;
  font-size: ${rem(16)};
  font-family: ${({ theme }) => theme.blogFonts.primaryFont};
  color: ${({ theme }) => theme.blogColors.primary};
  width: 100%;
  max-width: ${rem(450)};

  &::placeholder {
    color: ${({ theme }) => theme.blogColors.gray};
  }
  
  font-weight: 400;
  /* ${({ theme }) => theme.mediab.m580`
    display: block;
  `} */
`;

export const PrimaryInput = styled(Input)``;
