import * as React from 'react';
import { connect } from 'react-redux';
// import { loadProductDetail } from './../actions';
import styled from 'styled-components';
import { default as API, ThenArg } from '../../services/API';
import {
  langSelector,
  titleSelector,
} from '../../containers/Category/selectors';
import {
  userSelector,
  contactInfoSelector,
} from '../../containers/App/selectors';
import { withRouter, WithRouterProps } from 'react-router';
import { css } from '../../theme/styled-components';
import { FlexRowCenterVertical } from 'eshop-defaults';
import { rem } from 'polished';

const Tabber = styled.div`
  visibility: ${props => (props.mobile ? 'hidden' : '')};
  position: ${props => (props.mobile ? 'absolute' : 'relative')};
  display: ${props => (props.mobile ? 'none' : 'block')};

  @media (max-width: 1240px) {
    width: 75vw;
    margin: auto;
  }
  @media (max-width: 900px) {
    width: 90vw;
    margin: 0;
  }
  @media (max-width: 500px) {
    visibility: ${props => (props.mobile ? 'visible' : 'hidden')};
    position: ${props => (props.mobile ? 'relative' : 'absolute')};
    display: ${props => (props.mobile ? 'block' : 'none')};
    width: 100%;
  }
`;

const TabberNav = styled.nav`
  display: flex;

  @media (max-width: 1240px) {
    width: 75vw;
  }
  @media (max-width: 900px) {
    width: 90vw;
  }
  @media (max-width: 500px) {
    flex-direction: column;
    position: relative;
    top: 0;
    border: none;
  }
`;

const TabberNavItem = styled.div`
  text-align: left;
  width: fit-content;
  font: 20px/32px Barlow;
  font-weight: 500;
  letter-spacing: 0;
  color: #414141;
  opacity: 1;
  margin: 0;
  margin-bottom: ${({ active }) => (active ? '-2px' : '0')};
  cursor: pointer;
  padding: 15px 25px;
  background: ${({ active }) => (active ? '#FFF' : '#F9F9F9')};

  border: 1px solid #ddd;
  border-bottom: ${({ active }) => (active ? '1px solid #FFF' : 'none')};

  @media (max-width: 570px) {
    padding: 15px 0;
    text-align: center;
    width: 33vw;
  }

  @media (max-width: 500px) {
    text-align: left;
    padding-left: 5vw;
    padding-right: ${rem(16)};
    width: 100%;
    background-color: white;
    font-size: 20px;
    font-weight: 500;
    color: #414141;
    border: none;
    border-bottom: solid 1px #dddddd;
    margin-bottom: 0;

    &:first-child {
      border-top: solid 1px #dddddd;
    }
  }
  @media (max-width: 400px) {
    padding: 20px 15px;
  }
`;

const TabberContent = styled.div`
  padding-top: 50px;
  color: #414141;
  font-size: 16px;
  border-top: 1px solid #ddd;
  margin-bottom: ${rem(24)};

  & h3 {
    font: 24px/32px Barlow;
    font-weight: 500;
    margin: 0;
  }

  @media (max-width: 1240px) {
    width: 100%;
    padding: 50px 5vw;
  }
  @media (max-width: 730px) {
    width: 100%;
    margin: auto;
  }
  @media (max-width: 500px) {
    padding: 0;
    margin: 0;
    border: none;

    & h3 {
      font-size: 20px;
      font-weight: 500;
    }

    & p {
      font-size: 16px;
      font-weight: 400;
    }
  }
  ${({ visible }) =>
    visible === 'true' &&
    css`
      @media (max-width: 500px) {
        padding-top: 30px;
      }
    `}
`;

const Icon = styled.img`
  transition: all 0.3s;
  transform: ${props => (props.closed ? 'rotate(270deg)' : 'rotate(90deg)')};
  height: 17px;
  width: 20px;
  position: absolute;
  right: ${rem(16)};
  margin-top: 5px;
`;

export interface ProductProps {
  product: any; // ThenArg<typeof API.loadProduct>;
  connections: ThenArg<typeof API.searchProducts>;
  compatibles: ThenArg<typeof API.searchProducts>;
  dispatch: any;
  isCartProcessing: any;
  isError: boolean;
  lang: string;
  user: any;
  defaultTitle: string;
  setActiveVariant: any;
  setCurrentProductId: any;
  customer: any;
  preferablyPackaging: any[];
  contactInfo: {
    contactEmail: string;
    contactTel: string;
  };
  compatibleProductsByProductId: any;
  children: any;
}

export interface State {
  activeIndex: number;
  visible: object;
}

class ProductItem extends React.Component<
  ProductProps & WithRouterProps,
  State
> {
  public static async getInitialProps(props) {
    // const { product_id, dispatch } = props;
    try {
      // dispatch(loadProductDetail(product_id));
    } catch (exp) {
      console.log(exp);
    }

    return props;
  }
  constructor(props) {
    super(props);
    this.state = {
      activeIndex: 0,
      visible: { 0: true, 1: false, 2: false },
    };
  }

  public handleClick(activeIndex, visible) {
    this.setState({ activeIndex, visible });
  }

  public renderNavs() {
    const { children } = this.props;
    if (children && children.length) {
      return (
        <TabberNav>
          {children &&
            children.map((ch, i) => {
              if (ch && ch.props) {
                return (
                  <TabberNavItem
                    active={this.state.activeIndex === i}
                    onClick={this.handleClick.bind(this, i, this.state.visible)}
                  >
                    {ch.key}
                  </TabberNavItem>
                );
              }
              return null;
            })}
        </TabberNav>
      );
    }
    return null;
  }

  public renderMobileNavs() {
    return (
      <TabberNav>
        {this.props.children &&
          this.props.children.map((ch, i) => {
            if (ch && ch.props) {
              return (
                <TabberNavItem
                  active={this.state.activeIndex === i}
                  onClick={this.handleClick.bind(this, i, {
                    ...this.state.visible,
                    [i]: !this.state.visible[i],
                  })}
                >
                  <FlexRowCenterVertical>
                    <Icon
                      closed={!this.state.visible[i]}
                      src="/images/arrow_dark.svg"
                    />
                    {ch.key}
                  </FlexRowCenterVertical>
                  <TabberContent
                    visible={this.state.visible[i] ? 'true' : 'false'}
                  >
                    {this.renderMobileContent(i)}
                  </TabberContent>
                </TabberNavItem>
              );
            }
            return null;
          })}
      </TabberNav>
    );
  }

  public renderMobileContent(i) {
    if (this.state.visible[i] && this.props.children) {
      return typeof this.props.children[i] === 'object' &&
        this.props.children[i] !== ''
        ? this.props.children[i]
        : null;
    }
    return null;
  }

  public renderContent() {
    if (this.props.children) {
      return this.props.children[this.state.activeIndex];
    }
    return null;
  }

  public render() {
    return (
      <div>
        <Tabber mobile={true}>{this.renderMobileNavs()}</Tabber>
        <Tabber big={true}>
          {this.renderNavs()}
          {<TabberContent>{this.renderContent()}</TabberContent>}
        </Tabber>
      </div>
    );
  }

  public OnResize = () => {
    // todo
  };
}

const mapStateToProps = state => {
  return {
    customer: userSelector(state),
    lang: langSelector(state),
    defaultTitle: titleSelector(state),
    contactInfo: contactInfoSelector(state),
  };
};

export default connect(mapStateToProps)(withRouter(ProductItem));
