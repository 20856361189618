import React from 'react';
import { __ } from 'react-i18n/lib';
import styled from 'styled-components';
import { rem } from 'polished';
import { FlexCol, FlexRowCenterVertical } from 'eshop-defaults';
import { Link } from 'react-router';
import { Loader } from '../_helpers/Loader/Loader';
import LazyLoad from 'react-lazyload';
import { getImagePath, getFilePath } from '../../utilities/product';

const Wrapper = styled(FlexCol)`
  display: ${props => (props.mobile ? 'none' : 'flex')};
  justify-content: center;
  padding: ${rem(24)};
  margin-bottom: ${rem(32)};
  width: ${rem(360)};
  border: ${rem(1)} solid ${({ theme }) => theme.blogColors.border};
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.16);

  ${({ theme }) => theme.mediab.m810`
      display: ${props => (props.mobile ? 'flex' : 'none')};
      padding: 0;
      border: 0;
      box-shadow: none;
      width: 100%;
      margin-bottom: ${rem(48)};
  `}
`;

const TitleWrapper = styled(FlexRowCenterVertical)`
  justify-content: space-between;
  margin-bottom: ${rem(24)};

  ${({ theme }) => theme.mediab.m810`
      margin-bottom: ${rem(16)};
  `}
`;

export const TitleText = styled.h2`
  margin: 0;
  font-family: ${({ theme }) => theme.blogFonts.primaryFont};
  font-size: ${rem(24)};
  color: ${({ theme }) => theme.blogColors.primary};
  font-weight: 700;
  text-transform: uppercase;
  display: flex;
  align-items: center;

  ${({ theme }) => theme.mediab.m810`
      font-size: ${rem(20)};
  `}
`;

export const FastNewsIcon = styled.img`
  width: ${rem(18)};
  height: ${rem(20)};
  margin-right: ${rem(10)};
`;

const EbookItem = styled.div`
  display: flex;
  flex-flow: row;
  align-items: center;
  margin: 0;
  font-family: ${({ theme }) => theme.blogFonts.primaryFont};
  font-size: ${rem(14)};
  color: ${({ theme }) => theme.blogColors.primary};
  font-weight: 500;
  text-decoration: none;
  padding: ${rem(24)} 0;
  border-top: ${rem(1)} solid ${({ theme }) => theme.blogColors.border};
  line-height: 20px;

  &:nth-child(2) {
    /* border-top: 0; */
  }

  &:last-child {
    padding-bottom: 0;
  }

  ${({ theme }) => theme.mediab.m810`
      padding: ${rem(16)} 0;
      &:nth-child(2) {
        border-top: 0;
      }
  `}
`;

const EbookImage = styled.img`
  width: ${rem(80)};
  height: ${rem(113)};
  margin-right: ${rem(16)};
`;

const NameDownloadWrapper = styled(FlexCol)``;

const EbookName = styled(Link)`
  margin-bottom: ${rem(16)};
  font-size: ${rem(16)};
  text-transform: uppercase;
  color: ${({ theme }) => theme.blogColors.primary};
  text-decoration: none;

  &:hover {
    opacity: 0.8;
  }
`;

const DownloadIcon = styled.img`
  width: ${rem(24)};
  height: ${rem(24)};
  margin-right: ${rem(8)};
`;

const DownloadButton = styled.a`
  cursor: pointer;
  display: flex;
  flex-flow: row;
  align-items: center;
  color: ${({ theme }) => theme.blogColors.primary};
  text-decoration: none;
`;

const ShowMoreButton = styled(Link)`
  margin-top: ${rem(16)};
  text-decoration: none;
  display: flex;
  justify-content: center;
  flex-flow: row wrap;
  align-items: center;

  font-size: ${rem(16)};
  font-family: ${({ theme }) => theme.blogFonts.primaryFont};
  color: ${({ theme }) => theme.blogColors.primary};
  font-weight: 400;

  padding: ${rem(16)} ${rem(24)};
  width: 100%;
  height: ${rem(50)};
  cursor: pointer;

  font-weight: 500;
  background-color: white;
  border: ${rem(1)} solid ${({ theme }) => theme.blogColors.border};
`;

interface Props {
  title: any;
  isFetching: boolean;
  // ebooks: ThenArg<typeof API.loadEbooks>;
  ebooks: any;
  mobile?: boolean;
}

const EbookSidebar: React.SFC<Props> = ({
  title,
  isFetching,
  ebooks,
  mobile,
}) => {
  if (!isFetching && (!ebooks || ebooks.length === 0)) {
    return null;
  }

  return (
    <Wrapper mobile={mobile}>
      <TitleWrapper>{title}</TitleWrapper>
      {isFetching ? (
        <Loader />
      ) : (
        ebooks.map((ebook, i) => (
          <EbookItem mobile={mobile} key={ebook.id} to={ebook.url}>
            <LazyLoad height={113}>
              <EbookImage
                src={getImagePath(ebook.image, { width: 80, height: 113 })}
              />
            </LazyLoad>
            <NameDownloadWrapper>
              {ebook && ebook.url && (
                <EbookName to={`/ebook/${ebook.url.replace(/\//g, '%2F')}`}>
                  {ebook.name}
                </EbookName>
              )}
              {ebook.downloadLink && (
                <DownloadButton
                  href={getFilePath(ebook.downloadLink)}
                  download={true}
                >
                  <DownloadIcon src="/images/blog_assets/download.svg" />
                  {__('Stiahnuť ')} {`${ebook.downloadSize || '0'} MB`}
                </DownloadButton>
              )}
            </NameDownloadWrapper>
          </EbookItem>
        ))
      )}
      <ShowMoreButton to="/ebooky">{__('Zobraziť viac')}</ShowMoreButton>
    </Wrapper>
  );
};

export default EbookSidebar;
