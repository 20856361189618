import * as React from 'react';
import styled from 'styled-components';

import 'pure-react-carousel/dist/react-carousel.es.css';
import {
  CarouselProvider,
  Slider,
  Slide,
  ButtonBack,
  ButtonNext,
  Dot,
} from 'pure-react-carousel';
import { getImagePath } from '../../../utilities/product';
import { Link } from 'react-router';
import { rem } from 'polished';

const BtnBack = styled(ButtonBack)`
  background-image: url('/images/blog_assets/back-arrow.svg');
  background-position: center center;
  background-repeat: no-repeat;
  position: absolute;
  height: 30px;
  width: 30px;
  background-color: white;
  z-index: 500;
  right: 90px;
  top: -56px;
  border: none;
  visibility: ${({ type }) =>
    type === 'product-detail' ? 'hidden' : 'visible'};

  &:disabled {
    background-image: url('/images/blog_assets/arrow_back_gray.svg');
    cursor: unset;
  }

  @media only screen and (max-width: 580px) {
    visibility: hidden;
    z-index: -10;
  }

  @media only screen and (max-width: 520px) {
    visibility: hidden;
    z-index: -10;
  }
`;

const BtnNext = styled(ButtonNext)`
  background-image: url('/images/blog_assets/right-arrow.svg');
  background-position: center center;
  background-repeat: no-repeat;
  position: absolute;
  height: 30px;
  width: 30px;
  background-color: white;
  border: none;
  z-index: 500;
  right: 20px;
  top: -56px;
  visibility: ${({ type }) =>
    type === 'product-detail' ? 'hidden' : 'visible'};

  &:disabled {
    background-image: url('/images/blog_assets/right-arrow-gray.svg');
    cursor: unset;
  }

  @media only screen and (max-width: 580px) {
    visibility: hidden;
    z-index: -10;
  }

  @media only screen and (max-width: 520px) {
    visibility: hidden;
    z-index: -10;
  }
`;

const Carousel = styled(CarouselProvider)`
  margin: auto;
  position: relative;
  width: 100%;
  overflow: visible;

  @media only screen and (max-width: 380px) {
    padding-bottom: ${rem(16)};
  }
`;

const DotComponent = styled(Dot)`
  width: 8px;
  height: 8px;
  border: none;
  background-color: #dddddd;
  padding: 0;
  margin: 0 4px;
  border-radius: 50%;

  &:disabled {
    background-color: #ffd607;
  }
`;

const Dots = styled.div`
  width: 100%;
  margin: auto;
  margin-top: -20px;
  text-align: center;
  position: absolute;
  @media only screen and (max-width: 1130px) {
    margin-top: -35px;
  }
  @media only screen and (max-width: 900px) {
    margin-top: 0px;
  }
  @media only screen and (max-width: 620px) {
    margin-top: -10px;
  }
  @media only screen and (max-width: 580px) {
    margin-top: 0px;
  }
  @media only screen and (max-width: 520px) {
    margin-top: -2vw;
  }
  @media only screen and (max-width: 450px) {
    margin-top: 0;
  }
  @media only screen and (max-width: 380px) {
    bottom: ${({ type }) => (type === 'ebook' ? '-220px' : 'initial')};
  }
  @media only screen and (max-width: 340px) {
    bottom: ${({ type }) => (type === 'ebook' ? '-200px' : 'initial')};
  }
`;

const PhotogalleryItem = styled.div`
  margin: auto;
  width: 100%;
  height: 305px;
  line-height: 20px;
  display: flex;

  @media only screen and (max-width: 580px) {
    width: 100%;
    height: 255px;
  }

  @media only screen and (max-width: 420px) {
    width: 100%;
    height: 210px;
  }
  @media only screen and (max-width: 380px) {
    width: 100%;
    height: 210px;
  }
`;

const PhotogalleryCover = styled.img`
  margin: auto;
  width: 100%;
  height: 305px;
  border: 1px solid #dddddd;
  padding: ${rem(16)};

  @media only screen and (max-width: 900px) {
    width: auto;
  }
  @media only screen and (max-width: 580px) {
    width: 100%;
    height: 255px;
  }
  @media only screen and (max-width: 420px) {
    width: 100%;
    height: 210px;
  }
  @media only screen and (max-width: 380px) {
    width: 100%;
    height: 210px;
  }
`;

const EbookItem = styled(Link)`
  text-decoration: none;
  margin: auto;
  width: 210px;
  height: 305px;
  box-shadow: 1px 3px 15px #dddddd;
  margin-right: 32px;
  line-height: 20px;

  @media only screen and (max-width: 900px) {
    width: 180px;
    height: 255px;
  }

  @media only screen and (max-width: 620px) {
    width: 150px;
    height: 210px;
  }
  @media only screen and (max-width: 520px) {
    width: 180px;
    height: 255px;
  }

  @media only screen and (max-width: 420px) {
    width: 150px;
    height: 210px;
  }
  @media only screen and (max-width: 380px) {
    width: 120px;
    height: 170px;
  }
`;

const Cover = styled.img`
  margin: auto;
  width: 210px;
  height: 305px;
  /* border: 1px solid #dddddd; */

  @media only screen and (max-width: 900px) {
    width: 180px;
    height: 255px;
  }
  @media only screen and (max-width: 620px) {
    width: 150px;
    height: 210px;
  }
  @media only screen and (max-width: 520px) {
    width: 180px;
    height: 255px;
  }
  @media only screen and (max-width: 420px) {
    width: 150px;
    height: 210px;
  }
  @media only screen and (max-width: 380px) {
    width: 120px;
    height: 170px;
  }
`;

const ProductItem = styled(Link)`
  text-decoration: none;
  /* margin: auto; */
  width: 228px;
  height: 231px;
  border: 1px solid #dddddd;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  @media only screen and (max-width: 1130px) {
    margin: auto;
  }
  @media only screen and (max-width: 580px) {
    width: 100%;
    height: 230px;
  }
  @media only screen and (max-width: 420px) {
    width: 100%;
    height: 230px;
  }
  @media only screen and (max-width: 380px) {
    width: 100%;
    height: 230px;
  }
`;

const ProductThumb = styled.img`
  margin: auto;
  width: 91px;
  height: 120px;
  max-height: 120px;
  border: 1px solid #dddddd;
`;

const ProductName = styled.p`
  text-transform: uppercase;
  text-align: center;
  margin: 0 0 24px;
  width: 85%;
  color: #414141;
  font-weight: 500;
  line-height: 20px;
`;

const SliderCustom = styled(Slider)`
  height: 320px;

  @media only screen and (max-width: 580px) {
    height: 270px;
  }

  @media only screen and (max-width: 420px) {
    height: 225px;
  }

  @media only screen and (max-width: 380px) {
    overflow: ${({ type }) =>
      type === 'ebook' ? 'visible !important' : 'hidden'};
    height: 220px;
  }
`;

interface State {
  width: number;
  height: number;
  visibleSlides: number;
  type: string;
  slides: Array<{ img: any; name: string }>;
}

export interface Props {
  type: string;
  slides: Array<{ img: any; name: string }>;
}

class SliderComponent extends React.Component<Props, State> {
  constructor(props) {
    super(props);
    this.updateState = this.updateState.bind(this);
    this.state = {
      width: 210,
      height: 305,
      visibleSlides: 3,
      type: '',
      slides: [],
    };
  }

  public renderItem(key: number, img: string, item: any) {
    if (this.props.type === 'photogallery') {
      return (
        <PhotogalleryItem key={key}>
          <PhotogalleryCover
            alt="Obrázok vo fotogalerií"
            src={getImagePath(img, { width: 400, height: 300 })}
          />
        </PhotogalleryItem>
      );
    } else if (this.props.type === 'ebook') {
      return (
        <EbookItem
          title={item.name}
          to={`/ebook/${item.url.replace(/\//g, '%2F')}`}
          key={item.id}
        >
          <Cover
            alt={item.name}
            src={getImagePath(item.img, { width: 215, height: 305 })}
          />
        </EbookItem>
      );
    } else if (this.props.type === 'product') {
      const productId = item.id ? item.id : item.product_id;
      const image = item.image ? item.image : item.picture;

      return (
        <ProductItem to={`/produkt/${productId}/${item.url}`} key={productId}>
          <ProductThumb
            alt={item.name}
            src={getImagePath(image, { width: 90, height: 120 })}
          />
          <ProductName>{item.name}</ProductName>
        </ProductItem>
      );
    } else if (this.props.type === 'article-product') {
      const productId = item.id ? item.id : item.product_id;
      const image = item.image ? item.image : item.picture;

      return (
        <ProductItem to={`/produkt/${productId}/${item.url}`} key={productId}>
          <ProductThumb
            alt={item.name}
            src={getImagePath(image, { width: 90, height: 120 })}
          />
          <ProductName>{item.name}</ProductName>
        </ProductItem>
      );
    } else if (this.props.type === 'product-detail') {
      return (
        <PhotogalleryItem key={key}>
          <PhotogalleryCover
            alt={`Obrázok vo fotogalerií č. ${key}`}
            src={getImagePath(img, { width: 400, height: 300 })}
          />
        </PhotogalleryItem>
      );
    }
    return null;
  }

  public renderDots(items: any[], slide: number) {
    const table: any[] = [];
    for (let i = 0; i < items.length; i += slide) {
      table.push(<DotComponent slide={i} />);
    }
    return table;
  }

  public updateState() {
    const win = window.innerWidth;
    if (
      this.props.type === 'photogallery' ||
      this.props.type === 'product-detail'
    ) {
      if (win < 580 && win >= 420) {
        this.setState({
          width: 400,
          height: 255,
          visibleSlides: 1,
        });
      } else if (win < 420 && win >= 380) {
        this.setState({
          width: 350,
          height: 210,
          visibleSlides: 1,
        });
      } else {
        this.setState({
          width: 300,
          height: 170,
          visibleSlides: 1,
        });
      }
    } else if (
      this.props.type === 'product' ||
      this.props.type === 'article-product'
    ) {
      if (win > 1130) {
        this.setState({
          width: 210,
          height: 255,
          visibleSlides: 4,
        });
      } else if (win <= 1130 && win >= 780) {
        this.setState({
          width: 210,
          height: 205,
          visibleSlides: 3,
        });
      } else if (win < 780 && win >= 680) {
        this.setState({
          width: 200,
          height: 165,
          visibleSlides: 2,
        });
      } else if (win < 680 && win >= 580) {
        this.setState({
          width: 200,
          height: 195,
          visibleSlides: 2,
        });
      } else if (win < 580 && win >= 550) {
        this.setState({
          width: 400,
          height: 205,
          visibleSlides: 1,
        });
      } else if (win < 550 && win >= 480) {
        this.setState({
          width: 400,
          height: 215,
          visibleSlides: 1,
        });
      } else if (win < 480 && win >= 440) {
        this.setState({
          width: 400,
          height: 235,
          visibleSlides: 1,
        });
      } else if (win < 440 && win >= 420) {
        this.setState({
          width: 400,
          height: 250,
          visibleSlides: 1,
        });
      } else if (win < 420 && win >= 380) {
        this.setState({
          width: 350,
          height: 240,
          visibleSlides: 1,
        });
      } else if (win < 420 && win >= 380) {
        this.setState({
          width: 300,
          height: 260,
          visibleSlides: 1,
        });
      } else {
        this.setState({
          width: 300,
          height: 280,
          visibleSlides: 1,
        });
      }
    } else if (this.props.type === 'ebook') {
      if (win > 1130) {
        this.setState({
          width: 210,
          height: 305,
          visibleSlides: 4,
        });
      } else if (win <= 1130 && win >= 900) {
        this.setState({
          width: 210,
          height: 305,
          visibleSlides: 3,
        });
      } else if (win < 900 && win >= 520) {
        this.setState({
          width: 150,
          height: 210,
          visibleSlides: 3,
        });
      } else if (win < 520 && win >= 420) {
        this.setState({
          width: 180,
          height: 255,
          visibleSlides: 2,
        });
      } else if (win < 420 && win >= 380) {
        this.setState({
          width: 150,
          height: 210,
          visibleSlides: 2,
        });
      } else if (win < 420 && win >= 380) {
        this.setState({
          width: 150,
          height: 210,
          visibleSlides: 2,
        });
      } else {
        this.setState({
          width: 120,
          height: 170,
          visibleSlides: 2,
        });
      }
    }
  }

  public componentWillMount(): void {
    this.updateState();
  }

  public componentDidMount() {
    this.setState({
      type: this.props.type,
      slides: this.props.slides,
    });
    window.addEventListener('resize', this.updateState);
  }
  public componentWillUnmount() {
    window.removeEventListener('resize', this.updateState);
  }

  public render() {
    const { slides, type } = this.props;
    const { width, height, visibleSlides } = this.state;

    return (
      <Carousel
        naturalSlideHeight={height}
        naturalSlideWidth={width}
        visibleSlides={visibleSlides}
        step={1}
        totalSlides={slides.length}
      >
        <BtnBack type={type} />
        <SliderCustom type={type}>
          {slides.map((item, index) => {
            return (
              <Slide index={index} key={index}>
                {this.renderItem(index, item.img ? item.img : '', item)}
              </Slide>
            );
          })}
        </SliderCustom>
        <Dots type={type}>{this.renderDots(slides, visibleSlides)}</Dots>
        <BtnNext type={type} />
      </Carousel>
    );
  }
}

export default SliderComponent;
