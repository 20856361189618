import {
  REQUEST_EBOOK,
  RECEIVE_EBOOK_SUCCESS,
  RECEIVE_EBOOK_ERROR,
} from './constants';

const INITIAL_STATE: any = {
  ebookData: {},
  isFetching: false,
};

export const ebookReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case REQUEST_EBOOK: {
      return {
        ...state,
        ...state.ebookDetail,
        isFetching: true,
      };
    }

    case RECEIVE_EBOOK_SUCCESS: {
      return {
        ...state,
        isFetching: false,
        ebookData: action.payload.ebook,
      };
    }

    case RECEIVE_EBOOK_ERROR: {
      return {
        ...state,
        isFetching: false,
        error: action.payload.error,
      };
    }

    default: {
      return state;
    }
  }
};
