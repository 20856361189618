import * as React from 'react';
import { __ } from 'react-i18n';
import { Link, withRouter, WithRouterProps } from 'react-router';
import styled from 'styled-components';
import { rem } from 'polished';
import Button from '../_helpers/forms/Button/Button';
import { IconMenu } from '../_helpers/Icon/Icon';
import { connect } from 'react-redux';
import {
  searchResultsSelector,
  searchIsFetchingSelector,
} from '../../containers/Header/selectors';
import ReactDOM from 'react-dom';
import { FlexRowCenterVertical } from 'eshop-defaults';
import SocialIcons from '../_helpers/SocialIcons/SocialIcons';
import NavMenu from './NavMenu';
import Dimmer from '../Dimmer/Dimmer';
import MobileNavMenu from './MobileNavMenu';
import { ThenArg } from '../../services/Zasielkovna-API';
import API from '../../services/API';
import {
  topMenuItemsSelector,
  topMenuBrandsSelector,
  partialSearchResultsSelector,
  langSelector,
} from '../../containers/App/selectors';
import SearchForm from './SearchForm';
import {
  loadSearchTermResults,
  loadPartialSearchTermResults,
  changeLanguage,
} from '../../containers/App/actions';
import { getRedirectRouteAfterLanguageChange } from '../../utilities';

interface HeaderProps {
  lang: string;
  searchIsFetching: boolean;
  searchResults: any;
  partialSearchResults: {
    isFetching: boolean;
    articles: ThenArg<typeof API.loadArticles>['articles'];
  };
  topMenuItems: ThenArg<typeof API.loadSitemapSubtree>['sitemap_tree'];
  brands: ThenArg<typeof API.loadBrands>['brands'];
  dispatch: (action: any) => void;
  socialUrls: {
    fbUrl: string;
    instagramUrl: string;
    youtubeUrl: string;
  };
}

interface HeaderState {
  searchTerm: string;
  isResponsiveCategoryMenuVisible: boolean;
  isResponsiveSearchFormVisible: boolean;
  isResponsiveSearchFromResultviewerVisible: boolean;
  redirect: boolean;
}

/*****************/

const HeaderWrapper = styled.div`
  background: ${({ theme }) => theme.blogColors.primary};
  color: ${({ theme }) => theme.color.white};

  ${({ theme }) => theme.media(0, 'sm')} {
    width: 100%;
    position: relative;
    position: sticky;
    top: 0;
    left: 0;
    z-index: 95000;
  }

  @media print {
    display: none;
  }
`;

const HeaderContainer = styled.div`
  /* margin-left: ${rem(32)};
  margin-right: ${rem(32)};
  padding-left: 0;
  padding-right: 0; */

  margin-left:0;
  margin-right: 0;
  padding-left: ${rem(32)};
  padding-right: ${rem(32)};

  position: sticky;
  z-index: 400;
  background-color: ${({ theme }) => theme.blogColors.primary};

  &.searchVisible {
    @media only screen and (max-width: 1150px) {
      position: sticky;
      z-index: 400;
      background-color: ${({ theme }) => theme.blogColors.primary};
      margin: 0;
      padding: ${rem(8)} ${rem(16)};
    }
  }

  ${({ theme }) => theme.media(0, 'sm')} {
    position: initial;
    z-index: 200;
    padding: ${rem(8)} 0;
    margin: 0 ${rem(16)};
  }
  ${({ theme }) => theme.media('sm')} {
    padding-top: ${rem(26)};
    padding-bottom: ${rem(26)};
  }
`;

const HeaderRow = styled(FlexRowCenterVertical)`
  justify-content: space-between;
  height: ${rem(48)};
  margin-left: 0;
  margin-right: 0;

  @media only screen and (max-width: 1150px) {
    margin-left: 0px;
  }
`;

const HeaderButton = styled(Button)`
  line-height: 1;
  padding: ${rem(5)} 0 ${rem(1)};

  &:focus {
    outline: none;
  }
`;

const SearchHeaderButton = styled(HeaderButton)`
  margin-right: ${rem(24)};

  @media only screen and (max-width: 360px) {
    margin-right: ${rem(16)};
  }
`;

const HeaderIcon = styled.img`
  fill: ${({ theme }) => theme.color.white};
  width: ${rem(24)};
  height: ${rem(24)};
  margin-bottom: ${rem(2)};
`;

const HeaderLogo = styled.div`
  position: relative;
  padding-right: 0;
  flex: 1;
  padding-left: 0;
  max-width: ${rem(500)};
  height: ${rem(48)};

  @media only screen and (max-width: 1150px) {
    flex: initial;
  }
`;

const HomeLink = styled(Link)`
  display: flex;
  flex-flow: row;
  align-items: center;
  text-decoration: none;
  height: 100%;
  width: fit-content;
`;

const HeaderButtons = styled.div`
  text-align: right;
  padding-top: ${rem(10)};
  display: none;

  @media only screen and (max-width: 1150px) {
    padding-right: 0;
    padding-top: ${rem(0)};
    display: block;
  }

  @media only screen and (max-width: 400px) {
    padding-left: ${rem(0)};
  }
  /* ${({ theme }) => theme.media(0, 'sm')} {
    padding-right: ${rem(10)};
    padding-left: ${rem(86)};
    margin-left: ${rem(-76)};
    display: block;
  } */
  /* ${({ theme }) => theme.media('sm', 1024)} {
    padding-left: ${({ theme }) => rem(305 + theme.grid.padding / 2)};
    margin-left: ${rem(-305)};
    display: none;
  }
  ${({ theme }) => theme.media(1024)} {
    width: ${rem(200)};
    display: none;
  }
  ${({ theme }) => theme.media(1280)} {
    width: ${rem(430)};
    display: none;
  } */
`;

const Logo = styled.img`
  width: ${rem(150)};
  height: ${rem(48)};
  ${({ theme }) => theme.media(0, 'sm')} {
    width: ${rem(100)};
    height: ${rem(32)};
  }
`;

const HeaderLogoText = styled.div`
  color: ${({ theme }) => theme.blogColors.gray};
  font-family: ${({ theme }) => theme.blogFonts.primaryFont};
  font-size: ${({ theme }) => theme.fontSizeXS};
  margin-left: ${rem(25)};
  line-height: 32px;

  @media only screen and (max-width: 1150px) {
    display: none;
  }
`;

const StyledPattern = styled.img`
  width: 500px;
  height: 70px;
  position: absolute;
  top: 0;
  left: 500px;
  z-index: -1;

  @media only screen and (max-width: 1050px) {
    display: none;
  }
`;

const LangSwitchWrapper = styled(FlexRowCenterVertical)`
  z-index: 50;
  flex: 1;
  justify-content: flex-end;
  margin-right: ${rem(32)};

  @media only screen and (max-width: 580px) {
    margin-right: 0;
  }

  @media only screen and (max-width: 400px) {
    margin: 0 ${rem(8)};
  }
`;

const LangSwitch = styled.a`
  margin: 0;
  cursor: pointer;

  background-color: ${({ active }) => (active ? '#FFD607' : 'white')};
  color: #414141;
  padding: ${rem(8)} ${rem(12)};
  border-radius: 16px;
  text-decoration: none;

  &::hover {
    text-decoration: underline;
  }
`;

const LangSwitchDelimeter = styled.div`
  margin: 0 ${rem(8)};
  background-color: ${({ theme }) => theme.blogColors.secondary};
  width: ${rem(2)};

  @media only screen and (max-width: 400px) {
    margin: 0 ${rem(2)};
  }
`;

class Header extends React.Component<
  HeaderProps & WithRouterProps,
  HeaderState
> {
  private responsiveResultViewer;
  constructor(props) {
    super(props);

    this.state = {
      searchTerm: '',
      isResponsiveCategoryMenuVisible: false,
      isResponsiveSearchFormVisible: false,
      isResponsiveSearchFromResultviewerVisible: false,
      redirect: false,
    };

    this.responsiveResultViewer = React.createRef();
  }

  public componentDidMount() {
    window.addEventListener('mousedown', this.handleClickOutside);
  }

  public componentWillUnmount() {
    window.removeEventListener('mousedown', this.handleClickOutside);
  }

  public handleClickOutside = e => {
    if (!this.state.isResponsiveSearchFromResultviewerVisible) {
      return;
    }
    const domNode = ReactDOM.findDOMNode(this.responsiveResultViewer.current);
    if (!domNode || !domNode.contains(e.target)) {
      this.hideResponsiveSearchResultViewer();
    }
  };

  public closeResponsiveCategoryMenu = e => {
    if (!this.state.isResponsiveCategoryMenuVisible) {
      return;
    }
    this.setState({
      isResponsiveCategoryMenuVisible: false,
    });
  };

  public changeLanguage = async newLang => {
    const { dispatch, lang, location, router } = this.props;

    if (newLang !== lang) {
      await dispatch(changeLanguage(newLang));
      const nextRoute = getRedirectRouteAfterLanguageChange(
        location.pathname,
        location.search,
        newLang,
      );
      router.push(nextRoute);
      window.location.reload();
    }
  };

  public render() {
    const {
      socialUrls: { fbUrl, instagramUrl: igUrl, youtubeUrl: ytUrl },
      topMenuItems,
      brands,
      partialSearchResults,
      location: { pathname },
      lang,
    } = this.props;

    const {
      isResponsiveCategoryMenuVisible,
      isResponsiveSearchFormVisible,
    } = this.state;

    const logoSrc =
      lang === 'cz'
        ? '/images/blog_assets/vzdy_cz.svg'
        : '/images/blog_assets/Group1.svg';
    const patternSrc = '/images/blog_assets/pattern.svg';
    const searchIconSrc = isResponsiveSearchFormVisible
      ? '/images/blog_assets/close_white.svg'
      : '/images/blog_assets/search_white.svg';
    const menuIconSrc = '/images/blog_assets/close.svg';

    return (
      <HeaderWrapper>
        {isResponsiveCategoryMenuVisible && (
          <MobileNavMenu
            pathname={pathname}
            navItems={topMenuItems}
            brands={brands}
            toggleResponsiveCategoryMenu={this.toggleResponsiveCategoryMenu}
          />
        )}

        <HeaderContainer
          className={`container ${
            isResponsiveSearchFormVisible ? 'searchVisible' : ''
          }`}
        >
          <HeaderRow className="row">
            <HeaderLogo className="col">
              <HomeLink to="/">
                <Logo src={logoSrc} alt={__('Logo Vždy')} />
                <HeaderLogoText>
                  {__('Technologický a lifestylový magazín')}
                </HeaderLogoText>
              </HomeLink>
            </HeaderLogo>
            <StyledPattern src={patternSrc} />
            <LangSwitchWrapper>
              {/* <LangSwitch
                active={lang === 'sk'}
                onClick={this.changeLanguage.bind(this, 'sk')}
              >
                SK
              </LangSwitch>
              <LangSwitchDelimeter />
              <LangSwitch
                active={lang === 'cz'}
                onClick={this.changeLanguage.bind(this, 'cz')}
              >
                CZ
              </LangSwitch> */}
              <LangSwitch active={lang === 'sk'} href={'https://vzdy.sk'}>
                SK
              </LangSwitch>
              <LangSwitchDelimeter />
              <LangSwitch active={lang === 'cz'} href={'https://vzdy.cz'}>
                CZ
              </LangSwitch>
            </LangSwitchWrapper>
            <SocialIcons
              fbUrl={fbUrl}
              igUrl={igUrl}
              ytUrl={ytUrl}
              mobile={false}
            />
            <HeaderButtons className="col">
              <SearchHeaderButton
                onClick={
                  isResponsiveSearchFormVisible
                    ? null
                    : this.toggleResponsiveSearchForm
                }
              >
                <HeaderIcon src={searchIconSrc} />
              </SearchHeaderButton>
              {isResponsiveCategoryMenuVisible ? (
                <HeaderIcon src={menuIconSrc} />
              ) : (
                <HeaderButton
                  className="header-button--menu-active_"
                  onClick={this.toggleResponsiveCategoryMenu}
                >
                  <IconMenu />
                </HeaderButton>
              )}
            </HeaderButtons>
          </HeaderRow>
          {isResponsiveSearchFormVisible && (
            <SearchForm
              results={partialSearchResults}
              redirectToResultPage={this.redirectToSearchResultsPage}
              loadPartialResults={this.handleSearchTermChange}
              toggleResponsiveSearchForm={this.toggleResponsiveSearchForm}
            />
          )}
        </HeaderContainer>

        <NavMenu
          lang={lang}
          pathname={pathname}
          navItems={topMenuItems}
          brands={brands}
          toggleResponsiveSearchForm={
            isResponsiveSearchFormVisible
              ? null
              : this.toggleResponsiveSearchForm
          }
          isSearchVisible={isResponsiveSearchFormVisible}
        />
        {(isResponsiveCategoryMenuVisible || isResponsiveSearchFormVisible) && (
          <Dimmer height={100} />
        )}
      </HeaderWrapper>
    );
  }

  public redirectToSearchResultsPage = (e, searchTerm) => {
    e.preventDefault();
    this.props.dispatch(loadSearchTermResults(searchTerm));
    this.toggleResponsiveSearchForm();
    // const route = this.props.lang === 'sk' ? 'vyhladavanie' : 'vyhledavani';
    const route = __('routes:vyhladavanie');
    this.props.router.push(`${route}?q=${searchTerm}`);
  };

  public redirectToSearchResultsPage2 = e => {
    e.preventDefault();
    this.props.router.push({
      pathname: __('routes:vyhladavanie'),
      search: `?q=${this.state.searchTerm}`,
    });
  };

  public toggleResponsiveCategoryMenu = () => {
    this.setState({
      isResponsiveCategoryMenuVisible: !this.state
        .isResponsiveCategoryMenuVisible,
    });
  };

  public toggleResponsiveSearchForm = () => {
    // set timeout, so form doesnt reopen if there is click on search/close icon
    setTimeout(() => {
      this.setState({
        isResponsiveSearchFormVisible: !this.state
          .isResponsiveSearchFormVisible,
      });
    }, 1);
  };

  public handleSearchTermChange = e => {
    this.props.dispatch(loadPartialSearchTermResults(e.target.value, 3, 0));
  };

  public handleResponsiveSearchFormFocus = () => {
    this.setState({
      isResponsiveSearchFromResultviewerVisible: true,
    });
  };

  public hideResponsiveSearchResultViewer = () => {
    this.setState({
      isResponsiveSearchFromResultviewerVisible: false,
    });
  };
}

const mapStateToProps = state => {
  return {
    searchResults: searchResultsSelector(state),
    partialSearchResults: partialSearchResultsSelector(state),
    searchIsFetching: searchIsFetchingSelector(state),
    topMenuItems: topMenuItemsSelector(state),
    brands: topMenuBrandsSelector(state),
    lang: langSelector(state),
  };
};

export default connect(mapStateToProps)(withRouter(Header));
