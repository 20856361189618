import {
  REQUEST_AUTHOR,
  RECEIVE_AUTHOR_SUCCESS,
  RECEIVE_AUTHOR_ERROR,
  SET_AUTHOR_FILTERS,
} from './constants';

const INITIAL_STATE: any = {
  authorDetail: {
    authorData: {},
    isFetching: false,
    offset: 0,
  },
};

export const authorReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case REQUEST_AUTHOR: {
      return {
        ...state,
        authorDetail: {
          ...state.authorDetail,
          isFetching: true,
        },
      };
    }

    case RECEIVE_AUTHOR_SUCCESS: {
      return {
        ...state,
        authorDetail: {
          ...state.authorDetail,
          isFetching: false,
          authorData: action.payload.author,
        },
      };
    }

    case RECEIVE_AUTHOR_ERROR: {
      return {
        ...state,
        authorDetail: {
          ...state.authorDetail,
          isFetching: false,
          error: action.payload.error,
        },
      };
    }

    case SET_AUTHOR_FILTERS: {
      return {
        ...state,
        authorDetail: {
          ...state.authorDetail,
          offset: action.payload.filters.offset,
        },
      };
    }

    default: {
      return state;
    }
  }
};
