import {
  REQUEST_FAST_NEWS,
  RECEIVE_FAST_NEWS_SUCCESS,
  RECEIVE_FAST_NEWS_ERROR,
  SET_FAST_NEWS_FILTERS,
} from './constants';

const INITIAL_STATE: any = {
  articles: [],
  isFetching: false,
  offset: 0,
  total: 0,
};

export const fastNewsReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case REQUEST_FAST_NEWS: {
      return {
        ...state,
        isFetching: true,
      };
    }

    case RECEIVE_FAST_NEWS_SUCCESS: {
      return {
        ...state,
        isFetching: false,
        articles: action.payload.articles,
        total: action.payload.total,
      };
    }

    case RECEIVE_FAST_NEWS_ERROR: {
      return {
        ...state,
        isFetching: false,
        error: action.payload.error,
      };
    }

    case SET_FAST_NEWS_FILTERS: {
      return {
        ...state,
        offset: action.payload.filters.offset,
      };
    }

    default: {
      return state;
    }
  }
};
