import * as React from 'react';
import { __ } from 'react-i18n';
import styled, { css } from 'styled-components';
import { rem } from 'polished';
import FooterNav from './FooterNav';
import { FlexRowCenterVertical } from 'eshop-defaults';
import SocialIcons from '../_helpers/SocialIcons/SocialIcons';

const FooterWrapper = styled.footer`
  background: ${({ theme }) => theme.blogColors.primary};
  color: ${({ theme }) => theme.color.white};
  /* margin-top: ${rem(40)}; */
  @media print {
    display: none;
  }
  ${({ theme }) => theme.mediab.m580`
   margin-top: 0;
`};
`;

const FooterBorder = css`
  ${({ theme }) => theme.mediab.m580`
     border-top: ${theme.footer.border};
    margin: 0;
`};
`;

const FooterRowWrapper = styled.div`
  ${({ theme }) => theme.mediab.m580`
     display: flex;
    flex-flow: column;
`};

  ${({ theme }) => theme.media('sm')} {
    ${FooterBorder};
  }
`;

const FooterContainer = styled.div`
  ${({ theme }) => theme.mediab.m580`
     display: flex;
    flex-flow: column;
    width: 100%;
`};
`;

const FooterRow = styled(FlexRowCenterVertical)`
  min-height: ${rem(120)};

  ${({ theme }) => theme.mediab.m580`
      height: initial;
  `};
`;

const FooterMetaCopyCol = styled.div`
  padding: 0;
  margin: 0 ${rem(32)};

  ${({ theme }) => theme.media(0, 'md')} {
    ${FooterBorder};
    text-align: center;
  }
  ${({ theme }) => theme.media('sm')} {
    display: flex;
    justify-content: space-between;
  }

  ${({ theme }) => theme.mediab.m580`
      margin: 0;
  `};
`;

const FooterMetaCopy = styled.p`
  font-size: ${rem(12)};
  line-height: 1.1;
  /* margin: ${rem(20)} 0; */
  margin: 0;
  
  ${({ theme }) => theme.media('sm')} {
    font-size: ${rem(14)};
  }
  ${({ theme }) => theme.mediab.m580`
      display: none;
  `};
`;

const StyledPattern = styled.img`
  width: 500px;
  height: 100px;
`;

interface Props {
  navItems: Array<{
    link: string;
    name: string;
  }>;
  phone: string;
  email: string;
  socialUrls: {
    fbUrl: string;
    igUrl: string;
    ytUrl: string;
  };
}

interface State {
  breakInnerWidth: number;
}

class Footer extends React.Component<Props, State> {
  constructor(props) {
    super(props);

    this.state = {
      breakInnerWidth: window.innerWidth,
    };
  }

  public componentDidMount() {
    window.addEventListener('resize', this.handleResize);
    // (window as any).FB.XFBML.parse();
  }

  public componentWillUnmount() {
    window.removeEventListener('resize', this.handleResize);
  }

  public handleResize = () => {
    const { breakInnerWidth } = this.state;

    if (
      (window.innerWidth >= 420 && breakInnerWidth < 420) ||
      (window.innerWidth < 420 && breakInnerWidth >= 420)
    ) {
      this.setState({
        breakInnerWidth: window.innerWidth,
      });
    }
  };

  public componentDidUpdate(prevProps, prevState) {
    if (prevState.breakInnerWidth !== this.state.breakInnerWidth) {
      setTimeout(() => {
        (window as any).FB.XFBML.parse();
      }, 500);
    }
  }

  public render() {
    const { navItems, socialUrls } = this.props;
    const { fbUrl, igUrl, ytUrl } = socialUrls;
    const patternSrc = '/images/blog_assets/pattern.svg';

    return (
      <FooterWrapper>
        <FooterRowWrapper>
          <SocialIcons
            mobile={true}
            fbUrl={fbUrl}
            igUrl={igUrl}
            ytUrl={ytUrl}
          />
          <FooterContainer className="container ">
            <FooterRow className="row">
              <FooterMetaCopyCol className="col">
                <FooterNav navItems={navItems} />
                <FooterMetaCopy>
                  <StyledPattern src={patternSrc} />
                </FooterMetaCopy>
              </FooterMetaCopyCol>
            </FooterRow>
          </FooterContainer>
        </FooterRowWrapper>
      </FooterWrapper>
    );
  }
}

export default Footer;
