import * as React from 'react';
import { __ } from 'react-i18n/lib';
import styled from 'styled-components';
import API from '../../services/API';
// import { TitlePlaceholder } from '../../theme/globalStyles';
import { rem } from 'polished';
import MetaTags from '../../components/MetaTags/MetaTags';
import { getFooterArticleMetaTags } from '../../utilities/metatags';
import { prop } from '../../utilities';
import { connectSsr } from 'ssr-service';

export const NeedHelpCol = styled.div`
  display: none;
  width: ${({ theme }) => rem(273 + theme.grid.padding / 2)};
  padding-right: 0;
  ${({ theme }) => theme.media(1024)} {
    display: block;
  }
  position: -webkit-sticky; /* Safari */
  position: sticky;
  top: 0;
`;

export const ProductsCol = styled.div`
  ${({ theme }) => theme.media(1024)} {
    /* padding-left: ${({ theme }) => rem(273 + theme.grid.padding / 2 + 15)};
    margin-left: ${({ theme }) => rem(-(273 + theme.grid.padding / 2))}; */
  }

  & > div:not(:last-of-type) {
    margin-bottom: ${rem(15)};
  }
`;

const Wrapper = styled.div`
  padding: ${rem(24)} ${rem(20)};
  width: 100%;
  max-width: ${rem(975)};
  margin: 0 auto;
`;

const Title = styled.h2`
  font-size: ${rem(26)};
  color: ${({ theme }) => theme.blogColors.primary};
  margin: 0;
  margin-bottom: ${rem(36)};
`;

export type SelectedAttribs = SelectedAttrib[];

export interface SelectedAttrib {
  name: number;
  value: number;
}

interface State {
  crumbs: any[];
}

export interface Props {
  sitemap: any;
}

class FooterArticle extends React.Component<Props, State> {
  public static async getInitialProps({ sitemapId }, parentProps) {
    return { sitemap: await API.loadSitemap(sitemapId) };
  }

  public render() {
    const { sitemap } = this.props;
    const content = prop(sitemap, 'content.json_content.body', '');

    return (
      <Wrapper>
        <Title>{prop(sitemap, 'name', '')}</Title>
        <MetaTags tags={getFooterArticleMetaTags(sitemap)} />
        <div
          dangerouslySetInnerHTML={{
            __html: typeof content === 'object' ? '' : content,
          }}
        />
      </Wrapper>
    );
  }
}

export default connectSsr({ displayName: 'FooterArticle' })(FooterArticle);
