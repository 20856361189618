import React from 'react';
import styled from 'styled-components';
import { rem } from 'polished';
import { Link } from 'react-router';

const SubNavItem = styled.div`
  justify-content: space-between;
  display: flex;
  align-items: center;

  padding: ${rem(12)} ${rem(16)};
  font-size: ${({ isParent }) => (isParent ? rem(20) : rem(18))};
  font-weight: ${({ isParent }) => (isParent ? 500 : 400)};
  color: white;
  cursor: pointer;
  text-decoration: none;

  &:hover a,
  &:focus a {
    opacity: 0.7;
  }
`;

const StyledLink = styled(Link)`
  text-decoration: none;
  color: white;
  width: 100%;
  color: ${({ isactive, theme }) =>
    isactive ? `${theme.blogColors.secondary} !important` : 'white'};
`;

const StyledA = styled.a`
  text-decoration: none;
  color: white;
  width: 100%;
  color: ${({ isactive, theme }) =>
    isactive ? `${theme.blogColors.secondary} !important` : 'white'};
`;

const RightArrowIcon = styled.img`
  width: ${rem(16)};
  height: ${rem(16)};
`;

const MobileNavMenuItem = ({
  isParent = false,
  item,
  toggleResponsiveCategoryMenu,
  showCategoryChildren,
  pathname = '',
}) => {
  return (
    <SubNavItem isParent={isParent} key={item.name}>
    {item.link ? 
       (<StyledA
        href={item.link}
        rel="noopener noreferrer"
        target="_blank"
        >
        {item.name}
      </StyledA>)
      :
       (<StyledLink
        isactive={
          pathname === `${item.url}` || pathname === `/${item.url}`
            ? true
            : undefined
        }
        to={`/${item.url}`}
        onClick={toggleResponsiveCategoryMenu}
      >
        {item.name}
      </StyledLink>)
    }
      {((item.children && item.children.length > 0) || item.annot) && (
        <RightArrowIcon
          // tslint:disable-next-line:jsx-no-lambda
          onClick={() =>
            showCategoryChildren(
              item.annot
                ? {
                    annot: item.annot,
                    img: item.img,
                    url: item.url,
                    title: item.name,
                    type: 'desc',
                  }
                : {
                    children: item.children,
                    title: item.name,
                  },
            )
          }
          src="/images/blog_assets/right-arrow-white.svg"
          alt=""
        />
      )}
    </SubNavItem>
  );
};

export default MobileNavMenuItem;
