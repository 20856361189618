import * as React from 'react';
import { __ } from 'react-i18n/lib';
import styled from 'styled-components';
import rem from 'polished/lib/helpers/rem';

const AuthorBox = styled.div`
  background-color: white;
  margin-top: ${rem(48)};
  position: fixed;
  width: 360px;
  height: fit-content;
  padding: ${rem(24)};
  border: solid 1px #dddddd;
  color: #414141;
  font-size: ${rem(16)};
  margin-right: ${rem(48)};
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.16);

  & html:not([data-scroll='100']) {
    top: 20px;
  }
  @media (max-width: 1200px) {
    width: 30vw;
    min-width: 300px;
  }
  @media (max-width: 700px) {
    margin: 0;
    margin-top: ${rem(24)};
    padding: 0;
    width: 95%;
    min-width: 90%;
    border: none;
    box-shadow: none;
    position: relative;
  }
  @media (max-width: 580px) {
    margin: 0;
  }
`;

const AuthorText = styled.div`
  text-transform: uppercase;
  font-weight: 700;
  color: #414141;
  font-size: ${rem(24)};
  border-bottom: solid 1px #dddddd;
  padding-bottom: ${rem(24)};

  @media (max-width: 480px) {
    font-weight: 500;
    width: 100%;
    border: none;
    font-size: ${rem(20)};
  }
`;

const AuthorDesc = styled.div`
  border-bottom: solid 1px #dddddd;
  padding: ${rem(24)} 0;
  line-height: 24px;

  & span {
    font-weight: 600;
    text-transform: uppercase;
  }

  @media (max-width: 480px) {
    padding: 0;
    border: none;
  }
`;

const AuthorCount = styled.div`
  padding-top: ${rem(24)};
  font-weight: 500;
`;

const Icon = styled.img`
  width: ${rem(18)};
  height: ${rem(18)};
  margin: ${rem(-3)} ${rem(10)} 0 0;
`;

const AuthorName = styled.span`
  margin-right: ${rem(6)};
`;

interface Props {
  name?: string;
  annotation?: string;
  count?: number;
  notFoundPage?: boolean;
}

const AuthorTab: React.SFC<Props> = ({ name, annotation, count }) => {
  const articlesCountText =
    count && count < 5
      ? count === 1
        ? __('článok')
        : __('články')
      : __('článkov');
  return (
    <AuthorBox>
      <AuthorText>{__('O AUTOROVI')}</AuthorText>
      <AuthorDesc>
        <AuthorName>{name}</AuthorName>
        <div
          dangerouslySetInnerHTML={{
            __html:
              typeof annotation === 'object' || !annotation ? '' : annotation,
          }}
        />
      </AuthorDesc>
      <AuthorCount>
        {' '}
        <Icon src={'/images/blog_assets/article.svg'} alt="" />
        {count} {articlesCountText}
      </AuthorCount>
    </AuthorBox>
  );
};

export default AuthorTab;
