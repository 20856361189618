import * as React from 'react';
import Link from 'react-router/lib/Link';
import styled from 'styled-components';
import { rem } from 'polished';
import { FlexRowCenterVertical } from 'eshop-defaults';
import { __ } from 'react-i18n';

const FooterNavList = styled(FlexRowCenterVertical)`
  font-size: ${rem(14)};
  padding: 0;
  margin: 0;

  flex-wrap: wrap;

  ${({ theme }) => theme.media(0, 'sm')} {
    display: block;
    padding: ${rem(10)} 0 0;
  }

  @media only screen and (max-width: 580px) {
    display: flex;
    flex-flow: column;
    padding: ${rem(32)} 0 ${rem(16)};
  }
`;

const FooterNavLink = styled(Link)`
  display: inline-block;
  color: ${({ theme }) => theme.color.white};
  font-family: ${({ theme }) => theme.blogFonts.primaryFont};
  font-size: ${rem(20)};
  font-weight: 500;
  text-decoration: none;
  padding: ${rem(5)} 0;
  margin-right: ${rem(32)};
  &:hover {
    text-decoration: underline;
  }

  @media only screen and (max-width: 580px) {
    margin-bottom: ${rem(16)};
    margin-right: 0;
  }
`;

const FooterNavNormalLink = styled.a`
  display: inline-block;
  color: ${({ theme }) => theme.color.white};
  font-family: ${({ theme }) => theme.blogFonts.primaryFont};
  font-size: ${rem(20)};
  font-weight: 500;
  text-decoration: none;
  padding: ${rem(5)} 0;
  margin-right: ${rem(32)};
  &:hover {
    text-decoration: underline;
  }

  @media only screen and (max-width: 580px) {
    margin-bottom: ${rem(16)};
    margin-right: 0;
  }
`;

const FooterRssLogo = styled.img`
  width: ${rem(24)};
  height: ${rem(24)};
  margin-right: ${rem(8)};
`;

interface FooterNavIO {
  navItems: Array<{
    link: string;
    name: string;
  }>;
}

const FooterNav: React.FunctionComponent<FooterNavIO> = ({ navItems }) => {
  return (
    <FooterNavList>
      {navItems &&
        navItems.map((item, i) => {
          return (
            <FooterNavLink key={i} to={`/${item.link}`}>
              {item.name}
            </FooterNavLink>
          );
        })}
      <FooterNavLink key={'sitemap'} to={'/sitemap'}>
        {__('Mapa stránky')}
      </FooterNavLink>
      <FooterNavLink key={'rss'} to={'/rss'}>
        <FooterRssLogo src="/images/blog_assets/rss.svg" />
        {__('RSS')}
      </FooterNavLink>
      {false && (
        <FooterNavNormalLink href={`${process.env.REACT_APP_API_BASE_URL}/rss`}>
          <FooterRssLogo src="/images/blog_assets/rss.svg" />
          {__('RSS')}
        </FooterNavNormalLink>
      )}
    </FooterNavList>
  );
};

export default FooterNav;
