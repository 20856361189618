import styled from 'styled-components';
import { rem } from 'polished';

const Button = styled.button`
  display: flex;
  justify-content: center;
  flex-flow: row wrap;
  align-items: center;

  font-size: ${rem(16)};
  font-family: ${({ theme }) => theme.blogFonts.primaryFont};
  color: ${({ theme }) => theme.blogColors.primary};
  font-weight: 400;
  cursor: pointer;
  min-width: ${rem(100)};
  line-height: 0px;

  &:hover {
    opacity: 0.8;
  }
  /* ${({ theme }) => theme.mediab.m580`
    display: block;
  `} */
`;

export const PrimaryButton = styled(Button)`
  background-color: ${({ theme }) => theme.blogColors.secondary};
  padding: ${rem(16)} ${rem(24)};
  border: 0;
  font-weight: 500;
`;

export const SecondaryButton = styled(Button)`
  margin: 0 auto;
  padding: ${rem(16)} ${rem(24)};
  width: 100%;
  max-width: ${rem(290)};
  height: ${rem(50)};

  font-weight: 500;
  background-color: white;
  border: ${rem(1)} solid ${({ theme }) => theme.blogColors.border};
`;
