import * as React from 'react';
import { __ } from 'react-i18n/lib';
import styled from 'styled-components';
import { rem } from 'polished';
import { FlexRowCenterVertical } from 'eshop-defaults';
// import API from 'src/services/API';
// import { ThenArg } from 'src/services/Zasielkovna-API';
import { Loader } from '../_helpers/Loader/Loader';
import NotFoundPageArticlesItem from './NotFoundPageArticlesItem';

const ArticlesList = styled(FlexRowCenterVertical)`
  justify-content: center;
  margin-bottom: ${({ searchResultsPage }) =>
    searchResultsPage ? rem(24) : rem(48)};
  flex-flow: unset;

  ${({ theme }) => theme.mediab.l1050`
    flex-flow: column;
  `}
`;

const StyledLoader = styled(Loader)`
  margin-top: ${rem(64)};
  width: ${rem(100)};
  height: ${rem(100)};
`;

interface Props {
  // articles?: ThenArg<typeof API.loadArticles>;
  articles?: any;
  isFetching?: boolean;
  notFoundPage?: boolean;
  searchResultsPage?: boolean;
  onClick?: any;
  containsProducts?: boolean;
}

const NotFoundPageArticles: React.SFC<Props> = ({
  articles,
  isFetching,
  searchResultsPage,
  onClick,
  containsProducts = false,
}) => {
  return (
    <ArticlesList searchResultsPage={searchResultsPage}>
      {isFetching ? (
        <StyledLoader small={false} />
      ) : (
        <>
          {articles &&
            articles.map(article => {
              if (containsProducts && article.sf_form === 'Product') {
                return (
                  <NotFoundPageArticlesItem
                    onClick={onClick}
                    searchResultsPage={searchResultsPage}
                    key={article.id}
                    name={article.name}
                    date={article.last_update}
                    url={`produkt/${article.id}/${article.url}`}
                    image={article.image}
                    author={article.author}
                    isProductArticle={true}
                  />
                );
              }

              return (
                <NotFoundPageArticlesItem
                  onClick={onClick}
                  searchResultsPage={searchResultsPage}
                  key={article.id}
                  name={article.name}
                  date={article.last_update}
                  url={article.url}
                  image={article.image}
                  author={article.author}
                />
              );
            })}
        </>
      )}
    </ArticlesList>
  );
};

export default NotFoundPageArticles;
