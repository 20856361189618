const namespace = 'Category';

export const REQUEST_CATEGORIES = `${namespace}/REQUEST_CATEGORIES`;
export const RECEIVE_CATEGORIES_SUCCESS = `${namespace}/RECEIVE_CATEGORIES_SUCCESS`;
export const RECEIVE_CATEGORIES_ERROR = `${namespace}/RECEIVE_CATEGORIES_ERROR`;

export const REQUEST_TOP_PRODUCTS_CATEGORY = `${namespace}/REQUEST_TOP_PRODUCTS_CATEGORY`;
export const RECEIVE_TOP_PRODUCTS_CATEGORY_SUCCESS = `${namespace}/RECEIVE_TOP_PRODUCTS_CATEGORY_SUCCESS`;
export const RECEIVE_TOP_PRODUCTS_CATEGORY_ERROR = `${namespace}/RECEIVE_TOP_PRODUCTS_CATEGORY_ERROR`;

export const REQUEST_CATEGORY_PRODUCTS = `${namespace}/REQUEST_CATEGORY_PRODUCTS`;
export const RECEIVE_CATEGORY_PRODUCTS_SUCCESS = `${namespace}/RECEIVE_CATEGORY_PRODUCTS_SUCCESS`;
export const RECEIVE_CATEGORY_PRODUCTS_ERROR = `${namespace}/RECEIVE_CATEGORY_PRODUCTS_ERROR`;
export const RECEIVE_MORE_CATEGORY_PRODUCTS_SUCCESS = `${namespace}/RECEIVE_MORE_CATEGORY_PRODUCTS_SUCCESS`;

export const SET_CATEGORY_FILTER = `${namespace}/SET_CATEGORY_FILTER`;
export const SET_CATEGORY_FILTERS = `${namespace}/SET_CATEGORY_FILTERS`;
export const SET_CATEGORY_ARTICLES_FILTERS = `${namespace}/SET_CATEGORY_ARTICLES_FILTERS`;

export const REQUEST_CATEGORY_BRANDS = `${namespace}/REQUEST_CATEGORY_BRANDS`;
export const RECEIVE_CATEGORY_BRANDS_SUCCESS = `${namespace}/RECEIVE_CATEGORY_BRANDS_SUCCESS`;
export const RECEIVE_CATEGORY_BRANDS_ERROR = `${namespace}/RECEIVE_CATEGORY_BRANDS_ERROR`;

export const REQUEST_SITEMAP_ARTICLES = `${namespace}/REQUEST_SITEMAP_ARTICLES`;
export const RECEIVE_SITEMAP_ARTICLES_SUCCESS = `${namespace}/RECEIVE_SITEMAP_ARTICLES_SUCCESS`;
export const RECEIVE_SITEMAP_ARTICLES_ERROR = `${namespace}/RECEIVE_SITEMAP_ARTICLES_ERROR`;

export const REQUEST_SITEMAP_DATA = `${namespace}/REQUEST_SITEMAP_DATA`;
export const RECEIVE_SITEMAP_DATA_SUCCESS = `${namespace}/RECEIVE_SITEMAP_DATA_SUCCESS`;
export const RECEIVE_SITEMAP_DATA_ERROR = `${namespace}/RECEIVE_SITEMAP_DATA_ERROR`;
