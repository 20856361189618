import APIClass from '../../services/API';
import {
  REQUEST_HOME_ARTICLES,
  RECEIVE_HOME_ARTICLES_SUCCESS,
  RECEIVE_HOME_ARTICLES_ERROR,
  REQUEST_HOME_MOST_READ,
  RECEIVE_HOME_MOST_READ_SUCCESS,
  RECEIVE_HOME_MOST_READ_ERROR,
  REQUEST_HOME_RECOMMENDED,
  RECEIVE_HOME_RECOMMENDED_SUCCESS,
  RECEIVE_HOME_RECOMMENDED_ERROR,
  REQUEST_HOME_FAST_NEWS,
  RECEIVE_HOME_FAST_NEWS_SUCCESS,
  RECEIVE_HOME_FAST_NEWS_ERROR,
  REQUEST_HOME_TOP_ARTICLE,
  RECEIVE_HOME_TOP_ARTICLE_SUCCESS,
  RECEIVE_HOME_TOP_ARTICLE_ERROR,
  REQUEST_HOME_EBOOKS,
  RECEIVE_HOME_EBOOKS_SUCCESS,
  RECEIVE_HOME_EBOOKS_ERROR,
  SET_HOME_RECOMMENDED_FILTER,
  SET_HOME_MOST_READ_FILTER,
  RECEIVE_HOME_BANNER_SUCCESS,
  SET_HOME_ARTICLES_FILTERS,
  RECEIVE_MORE_HOME_ARTICLES_SUCCESS,
  RECEIVE_HOME_RECOMMENDED_OPTIONS_ERROR,
  RECEIVE_HOME_RECOMMENDED_OPTIONS_SUCCESS,
} from './constants';
import { prop } from '../../utilities';
import { langSelector } from '../App/selectors';
import {
  fastNewsSelector,
  mostReadArticlesSelector,
  ebooksSelector,
} from './selectors';

const requestHomeArticles = () => ({
  type: REQUEST_HOME_ARTICLES,
});

const receiveHomeArticlesSuccess = articles => ({
  type: RECEIVE_HOME_ARTICLES_SUCCESS,
  payload: {
    articles,
  },
});

const receiveHomeArticlesError = error => ({
  type: RECEIVE_HOME_ARTICLES_ERROR,
  payload: {
    error,
  },
});

export const loadHomeArticles = () => {
  return async (dispatch, getState, API) => {
    try {
      dispatch(requestHomeArticles());
      const lang = langSelector(getState());
      const articles = await APIClass.loadArticles(
        {
          // sitemapUniqueId: 'IMPORT',
          sfForm: 'Article',
          onlyActual: '1',
          isPublished: '1',
          tagExcludeId: 1518,
          sort: 'created_date',
          replaceCurrentLangTags: '1',
          excludeSitemapUniqueId: ['JBL_CATEGORY_TEXTS'],
        },
        { xAcceptLanguage: lang },
      );
      dispatch(receiveHomeArticlesSuccess(articles));
    } catch (e) {
      dispatch(receiveHomeArticlesError(e));
    }
  };
};

const receiveMoreHomeArticlesSuccess = articles => ({
  type: RECEIVE_MORE_HOME_ARTICLES_SUCCESS,
  payload: {
    articles,
  },
});

export const loadMoreHomeArticles = () => {
  return async (dispatch, getState, API) => {
    try {
      dispatch(requestHomeArticles());
      const lang = langSelector(getState());
      const articles = await APIClass.loadArticles(
        {
          // sitemapUniqueId: 'IMPORT',
          limit: 10,
          offset: prop(getState(), 'homepage.articles.offset', 0) + 10,
          sfForm: 'Article',
          onlyActual: '1',
          isPublished: '1',
          tagExcludeId: 1518,
          sort: 'created_date',
          replaceCurrentLangTags: '1',
          excludeSitemapUniqueId: ['JBL_CATEGORY_TEXTS'],
        },
        { xAcceptLanguage: lang },
      );
      dispatch(receiveMoreHomeArticlesSuccess(articles.articles));
    } catch (e) {
      console.log({ e });
      dispatch(receiveHomeArticlesError(e));
    }
  };
};

export const setHomeArticlesFilters = filters => ({
  type: SET_HOME_ARTICLES_FILTERS,
  payload: {
    filters,
  },
});

const requestHomeRecommended = () => ({
  type: REQUEST_HOME_RECOMMENDED,
});

const receiveHomeRecommendedSuccess = recommended => ({
  type: RECEIVE_HOME_RECOMMENDED_SUCCESS,
  payload: {
    recommended,
  },
});

const receiveHomeRecommendedError = error => ({
  type: RECEIVE_HOME_RECOMMENDED_ERROR,
  payload: {
    error,
  },
});

const setHomeRecommendedFilter = filter => ({
  type: SET_HOME_RECOMMENDED_FILTER,
  payload: {
    filter,
  },
});

export const loadHomeRecommended = filter => {
  return async (dispatch, getState, API) => {
    try {
      dispatch(requestHomeRecommended());
      if (filter && filter !== getState().homepage.recommended.filter) {
        dispatch(setHomeRecommendedFilter(filter));
      }
      const lang = langSelector(getState());

      let tree =
        getState().recommended &&
        getState().recommended.recommendedArticlesOptions;
      if (!tree) {
        const subtree = await APIClass.loadSitemapSubtree(
          'ODPORUCANE',
          {},
          {
            xAcceptLanguage: lang,
          },
        );
        tree = prop(subtree, 'sitemap_tree', []);
        dispatch(loadHomeRecommendedOptions(tree));
      }
      const firstOptionId = tree && tree[0] && tree[0].id;

      const id = parseInt(filter, 10) || firstOptionId || 0;
      if (id) {
        const articles = await APIClass.loadArticles(
          {
            sitemapId: id,
            limit: 5,
            sfForm: 'Article',
            onlyActual: '1',
            isPublished: '1',
            tagExcludeId: 1518,
            replaceCurrentLangTags: '1',
            excludeSitemapUniqueId: ['JBL_CATEGORY_TEXTS'],
          },
          { xAcceptLanguage: lang },
        );
        dispatch(receiveHomeRecommendedSuccess(articles));
      } else {
        dispatch(receiveHomeRecommendedSuccess([]));
      }
    } catch (e) {
      dispatch(receiveHomeRecommendedError(e));
    }
  };
};

const receiveHomeRecommendedOptionsSuccess = recommended => ({
  type: RECEIVE_HOME_RECOMMENDED_OPTIONS_SUCCESS,
  payload: {
    recommended,
  },
});

const receiveHomeRecommendedOptionsError = error => ({
  type: RECEIVE_HOME_RECOMMENDED_OPTIONS_ERROR,
  payload: {
    error,
  },
});

export const loadHomeRecommendedOptions = options => {
  return async (dispatch, getState, API) => {
    try {
      await dispatch(receiveHomeRecommendedOptionsSuccess(options));
    } catch (e) {
      dispatch(receiveHomeRecommendedOptionsError(e));
    }
  };
};

const requestHomeMostRead = () => ({
  type: REQUEST_HOME_MOST_READ,
});

const receiveHomeMostReadSuccess = mostRead => ({
  type: RECEIVE_HOME_MOST_READ_SUCCESS,
  payload: {
    mostRead,
  },
});

const receiveHomeMostReadError = error => ({
  type: RECEIVE_HOME_MOST_READ_ERROR,
  payload: {
    error,
  },
});

const setHomeMostReadFilter = filter => ({
  type: SET_HOME_MOST_READ_FILTER,
  payload: {
    filter,
  },
});

export const loadHomeMostRead = (filter = null) => {
  return async (dispatch, getState, API) => {
    try {
      dispatch(requestHomeMostRead());
      if (filter && filter !== getState().homepage.fastNews.filter) {
        dispatch(setHomeMostReadFilter(filter));
      }
      const lang = langSelector(getState());
      const data = mostReadArticlesSelector(getState());
      if (!data || !data.mostReadArticles || !data.mostReadArticles.length) {
        const articles = await APIClass.loadMostReadArticles(
          {
            limit: 3,
            type: filter || 'week',
          },
          { xAcceptLanguage: lang },
        );
        dispatch(receiveHomeMostReadSuccess(articles));
      }
    } catch (e) {
      dispatch(receiveHomeMostReadError(e));
    }
  };
};

const requestHomeFastNews = () => ({
  type: REQUEST_HOME_FAST_NEWS,
});

const receiveHomeFastNewsSuccess = fastNews => ({
  type: RECEIVE_HOME_FAST_NEWS_SUCCESS,
  payload: {
    fastNews,
  },
});

const receiveHomeFastNewsError = error => ({
  type: RECEIVE_HOME_FAST_NEWS_ERROR,
  payload: {
    error,
  },
});

export const loadHomeFastNews = () => {
  return async (dispatch, getState, API) => {
    try {
      dispatch(requestHomeFastNews());
      const lang = langSelector(getState());
      const data = fastNewsSelector(getState());
      if (!data || !data.fastNewsArticles || !data.fastNewsArticles.length) {
        const articles = await APIClass.loadArticles(
          {
            sitemapUniqueId: 'BLESKOVKY',
            limit: 3,
            sfForm: 'Article',
            onlyActual: '1',
            isPublished: '1',
            replaceCurrentLangTags: '1',
          },
          { xAcceptLanguage: lang },
        );
        dispatch(receiveHomeFastNewsSuccess(articles));
      }
    } catch (e) {
      dispatch(receiveHomeFastNewsError(e));
    }
  };
};

const requestHomeTopArticle = () => ({
  type: REQUEST_HOME_TOP_ARTICLE,
});

const receiveHomeTopArticlesSuccess = topArticle => ({
  type: RECEIVE_HOME_TOP_ARTICLE_SUCCESS,
  payload: {
    topArticle,
  },
});

const receiveHomeTopArticleError = error => ({
  type: RECEIVE_HOME_TOP_ARTICLE_ERROR,
  payload: {
    error,
  },
});

export const loadHomeTopArticle = () => {
  return async (dispatch, getState, API) => {
    try {
      dispatch(requestHomeTopArticle());
      const lang = langSelector(getState());
      const topArticle = await APIClass.loadArticles(
        {
          isTop: '1',
          sfForm: 'Article',
          onlyActual: '1',
          tagExcludeId: 1518,
          replaceCurrentLangTags: '1',
          excludeSitemapUniqueId: ['JBL_CATEGORY_TEXTS'],
        },
        { xAcceptLanguage: lang },
      );
      dispatch(receiveHomeTopArticlesSuccess(topArticle));
    } catch (e) {
      dispatch(receiveHomeTopArticleError(e));
    }
  };
};

const receiveHomeBannerSuccess = banner => ({
  type: RECEIVE_HOME_BANNER_SUCCESS,
  payload: {
    banner,
  },
});

export const loadHomeBanner = () => {
  return async (dispatch, getState, API) => {
    try {
      const lang = langSelector(getState());
      const banners = await APIClass.loadBanners(
        { position: 'HOMEPAGE' },
        { xAcceptLanguage: lang },
      );
      const banner = prop(banners, 'banners');
      if (banner && banner.length > 0) {
        dispatch(receiveHomeBannerSuccess(banner[0]));
      } else {
        dispatch(receiveHomeBannerSuccess(null));
      }
    } catch (e) {
      console.log(e);
    }
  };
};

const requestHomeEbooks = () => ({
  type: REQUEST_HOME_EBOOKS,
});

const receiveHomeEbooksSuccess = ebooks => ({
  type: RECEIVE_HOME_EBOOKS_SUCCESS,
  payload: {
    ebooks,
  },
});

const receiveHomeEbooksError = error => ({
  type: RECEIVE_HOME_EBOOKS_ERROR,
  payload: {
    error,
  },
});

export const loadHomeEbooks = () => {
  return async (dispatch, getState, API) => {
    try {
      dispatch(requestHomeEbooks());
      const lang = langSelector(getState());
      const data = ebooksSelector(getState());
      if (!data || !data.ebooks || !data.ebooks.length) {
        const ebooks = await APIClass.loadArticles(
          {
            limit: 3,
            sitemapUniqueId: 'EBOOKY',
            onlyActual: '1',
            isPublished: '1',
            tagExcludeId: 1518,
            sfForm: 'Article,Ebook',
            replaceCurrentLangTags: '1',
            excludeSitemapUniqueId: ['JBL_CATEGORY_TEXTS'],
          },
          { xAcceptLanguage: lang },
        );
        dispatch(receiveHomeEbooksSuccess(ebooks));
      }
    } catch (e) {
      dispatch(receiveHomeEbooksError(e));
    }
  };
};
