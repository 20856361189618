import { createSelector } from 'reselect';
import { prop } from 'eshop-defaults';

export const articlesWithBrandDomainSelector = state =>
  state.articlesWithBrand || {};

export const articlesWithBrandSelector = createSelector(
  articlesWithBrandDomainSelector,
  substate => prop(substate, 'articles', []),
);

export const articlesWithBrandIsFetchingSelector = createSelector(
  articlesWithBrandDomainSelector,
  substate => prop(substate, 'isFetching', false),
);

export const articlesWithBrandErrorSelector = createSelector(
  articlesWithBrandDomainSelector,
  substate => prop(substate, 'error'),
);

export const articlesWithBrandOffsetSelector = createSelector(
  articlesWithBrandDomainSelector,
  substate => prop(substate, 'offset', 0),
);

export const articlesWithBrandTotalSelector = createSelector(
  articlesWithBrandDomainSelector,
  substate => prop(substate, 'total', 0),
);
