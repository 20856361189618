import { createSelector } from 'reselect';
import { prop } from 'eshop-defaults';

export const ebooksDomainSelector = state => state.ebooks || {};

export const ebooksDataSelector = createSelector(
  ebooksDomainSelector,
  substate => prop(substate, 'ebooks', []),
);

export const ebooksIsFetchingSelector = createSelector(
  ebooksDomainSelector,
  substate => prop(substate, 'isFetching', false),
);

export const ebooksErrorSelector = createSelector(
  ebooksDomainSelector,
  substate => prop(substate, 'error'),
);

export const ebooksOffsetSelector = createSelector(
  ebooksDomainSelector,
  substate => prop(substate, 'offset', 0),
);

export const ebooksTotalSelector = createSelector(
  ebooksDomainSelector,
  substate => prop(substate, 'total', 0),
);
