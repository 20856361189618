import * as React from 'react';
import { __ } from 'react-i18n/lib';
import styled from 'styled-components';
import { rem } from 'polished';
import { FlexRowCenterVertical, FlexCol } from 'eshop-defaults';
import { Link } from 'react-router';
import { getImagePath } from '../../utilities/product';
import { formatDate } from '../../utilities';

const Text = styled.p`
  margin: 0;
  font-family: ${({ theme }) => theme.blogFonts.primaryFont};
  font-size: ${rem(16)};
  color: ${({ theme }) => theme.blogColors.primary};
  font-weight: 400;
`;

const Wrapper = styled(Link)`
  text-decoration: none;
  display: flex;
  flex-flow: row;
  margin: 0 auto;
  padding: 0;
  width: 100%;
  margin-right: ${rem(15)};

  &:hover {
    opacity: 0.8;
  }

  ${({ theme }) => theme.mediab.l1050`
    margin-bottom: ${rem(10)};
  `}
`;

const Image = styled.img`
  width: ${({ searchResultsPage }) => (searchResultsPage ? rem(40) : rem(80))};
  height: ${({ searchResultsPage }) => (searchResultsPage ? rem(40) : rem(80))};
`;

const RightSideWrapper = styled(FlexCol)`
  margin-left: ${rem(16)};
`;

export const ArticleTitle = styled(Text)`
  font-size: ${rem(16)};
  font-weight: 500;
  margin-bottom: ${({ searchResultsPage }) =>
    searchResultsPage ? rem(8) : rem(16)};
  color: white;
`;

export const ArticleAuthorDateWrapper = styled(FlexRowCenterVertical)`
  margin-bottom: ${({ searchResultsPage }) =>
    searchResultsPage ? rem(8) : rem(16)};
  flex-flow: row wrap;
`;

export const ArticleAuthorDateText = styled(Text)`
  font-size: ${rem(14)};
  color: ${({ theme }) => theme.blogColors.gray};
  white-space: nowrap;
`;

export const DotDelimeter = styled.span`
  margin: 0 ${({ searchResultsPage }) => (searchResultsPage ? rem(8) : rem(16))};
  height: ${rem(3)};
  width: ${rem(3)};
  background-color: ${({ theme }) => theme.blogColors.gray};
  border-radius: 50%;
  display: inline-block;
`;

interface Props {
  name: string;
  date?: any;
  url: string;
  image: string;
  searchResultsPage?: boolean;
  onClick?: any;
  author: any;
  isProductArticle?: boolean;
}

const NotFoundPageArticlesItem: React.SFC<Props> = ({
  name,
  date,
  image,
  url,
  searchResultsPage,
  onClick,
  author,
  isProductArticle = false,
}) => {
  return (
    <Wrapper onClick={onClick} to={`/${url}`}>
      <Image
        searchResultsPage={searchResultsPage}
        alt={name}
        src={
          image
            ? getImagePath(image, { width: 80, height: 80 }, false, false, true)
            : 'https://via.placeholder.com/290x160.png'
        }
      />
      <RightSideWrapper>
        <ArticleTitle searchResultsPage={searchResultsPage}>
          {name}
        </ArticleTitle>
        <ArticleAuthorDateWrapper searchResultsPage={searchResultsPage}>
          {isProductArticle ? (
            <ArticleAuthorDateText>{__('produkt')}</ArticleAuthorDateText>
          ) : (
            <>
              <ArticleAuthorDateText>
                {(author && author.name) || ''}
              </ArticleAuthorDateText>
              {author && author.name && (
                <DotDelimeter searchResultsPage={searchResultsPage} />
              )}
              <ArticleAuthorDateText>
                {' '}
                {formatDate(date, 'DD.MM.YYYY')}
              </ArticleAuthorDateText>
            </>
          )}
        </ArticleAuthorDateWrapper>
      </RightSideWrapper>
    </Wrapper>
  );
};

export default NotFoundPageArticlesItem;
