import React from 'react';
import styled from '../../theme/styled-components';
import { rem } from 'polished';
import { FlexRow, FlexCol } from 'eshop-defaults';
import ToTopButton from '../_helpers/Default/ToTopButton';

export const MainWrapper = styled.div`
  padding: ${rem(30)} 0 0;
  width: 100%;

  ${({ theme }) => theme.mediab.m810`
     padding-top: ${rem(16)};
  `}
`;

const ContentAndSidebarsWrapper = styled(FlexRow)`
  margin-bottom: ${rem(48)};

  ${({ theme }) => theme.mediab.m810`
    flex-flow: column;
  `}
`;

const MainContent = styled(FlexCol)`
  margin-right: ${rem(48)};
  flex: 1;
  ${({ theme }) => theme.mediab.m810`
    margin-right: 0;
  `}
`;

const ArticlesWithSidebar = ({ mainContentChildren, sidebarChildren }) => {
  return (
    <div className="container container--wide">
      <MainWrapper>
        <ContentAndSidebarsWrapper>
          <MainContent>{mainContentChildren}</MainContent>
          <FlexCol>{sidebarChildren}</FlexCol>
        </ContentAndSidebarsWrapper>
        <ToTopButton />
      </MainWrapper>
    </div>
  );
};

export default ArticlesWithSidebar;
