import {
  REQUEST_HOME_ARTICLES,
  RECEIVE_HOME_ARTICLES_SUCCESS,
  RECEIVE_HOME_ARTICLES_ERROR,
  REQUEST_HOME_MOST_READ,
  RECEIVE_HOME_MOST_READ_SUCCESS,
  RECEIVE_HOME_MOST_READ_ERROR,
  REQUEST_HOME_RECOMMENDED,
  RECEIVE_HOME_RECOMMENDED_SUCCESS,
  RECEIVE_HOME_RECOMMENDED_ERROR,
  REQUEST_HOME_FAST_NEWS,
  RECEIVE_HOME_FAST_NEWS_SUCCESS,
  RECEIVE_HOME_FAST_NEWS_ERROR,
  REQUEST_HOME_TOP_ARTICLE,
  RECEIVE_HOME_TOP_ARTICLE_SUCCESS,
  RECEIVE_HOME_TOP_ARTICLE_ERROR,
  REQUEST_HOME_EBOOKS,
  RECEIVE_HOME_EBOOKS_SUCCESS,
  RECEIVE_HOME_EBOOKS_ERROR,
  SET_HOME_RECOMMENDED_FILTER,
  SET_HOME_MOST_READ_FILTER,
  RECEIVE_HOME_BANNER_SUCCESS,
  SET_HOME_ARTICLES_FILTERS,
  RECEIVE_MORE_HOME_ARTICLES_SUCCESS,
  REQUEST_HOME_RECOMMENDED_OPTIONS,
  RECEIVE_HOME_RECOMMENDED_OPTIONS_SUCCESS,
  RECEIVE_HOME_RECOMMENDED_OPTIONS_ERROR,
} from './constants';

const INITIAL_STATE: any = {
  topArticle: {
    topArticleData: [],
    isFetching: false,
  },
  articles: {
    articlesData: [],
    isFetching: false,
    limit: 10,
    offset: 0,
  },
  mostRead: {
    mostReadArticles: [],
    isFetching: false,
    filter: null,
  },
  recommended: {
    recommendedArticles: [],
    isFetching: false,
    filter: null,
  },
  fastNews: {
    fastNewsArticles: [],
    isFetching: false,
    filter: null,
  },
  ebooks: {
    ebooksData: [],
    isFetching: false,
  },
  adBanner: null,
};

export const homeReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case REQUEST_HOME_ARTICLES: {
      return {
        ...state,
        articles: {
          ...state.articles,
          isFetching: true,
        },
      };
    }

    case RECEIVE_HOME_ARTICLES_SUCCESS: {
      return {
        ...state,
        articles: {
          ...state.home,
          isFetching: false,
          articlesData: action.payload.articles,
        },
      };
    }

    case RECEIVE_HOME_ARTICLES_ERROR: {
      return {
        ...state,
        articles: {
          ...state.articles,
          isFetching: false,
          error: action.payload.error,
        },
      };
    }

    case RECEIVE_MORE_HOME_ARTICLES_SUCCESS: {
      return {
        ...state,
        articles: {
          ...state.articles,
          isFetching: false,
          articlesData: {
            ...state.articles.articlesData,
            articles: [
              ...state.articles.articlesData.articles,
              ...action.payload.articles,
            ],
          },
        },
      };
    }

    case SET_HOME_ARTICLES_FILTERS: {
      return {
        ...state,
        articles: {
          ...state.articles,
          offset: action.payload.filters.offset,
        },
      };
    }

    case REQUEST_HOME_MOST_READ: {
      return {
        ...state,
        mostRead: {
          ...state.mostRead,
          isFetching: true,
        },
      };
    }

    case RECEIVE_HOME_MOST_READ_SUCCESS: {
      return {
        ...state,
        mostRead: {
          ...state.mostRead,
          isFetching: false,
          mostReadArticles: action.payload.mostRead,
        },
      };
    }

    case RECEIVE_HOME_MOST_READ_ERROR: {
      return {
        ...state,
        mostRead: {
          ...state.mostRead,
          isFetching: false,
          error: action.payload.error,
        },
      };
    }

    case REQUEST_HOME_RECOMMENDED: {
      return {
        ...state,
        recommended: {
          ...state.recommended,
          isFetching: true,
        },
      };
    }

    case RECEIVE_HOME_RECOMMENDED_SUCCESS: {
      return {
        ...state,
        recommended: {
          ...state.recommended,
          isFetching: false,
          recommendedArticles: action.payload.recommended,
        },
      };
    }

    case RECEIVE_HOME_RECOMMENDED_ERROR: {
      return {
        ...state,
        recommended: {
          ...state.recommended,
          isFetching: false,
          error: action.payload.error,
        },
      };
    }

    case REQUEST_HOME_RECOMMENDED_OPTIONS: {
      return {
        ...state,
        recommended: {
          ...state.recommended,
          optionsIsFetching: true,
        },
      };
    }

    case RECEIVE_HOME_RECOMMENDED_OPTIONS_SUCCESS: {
      return {
        ...state,
        recommended: {
          ...state.recommended,
          optionsIsFetching: false,
          recommendedArticlesOptions: action.payload.recommended,
        },
      };
    }

    case RECEIVE_HOME_RECOMMENDED_OPTIONS_ERROR: {
      return {
        ...state,
        recommended: {
          ...state.recommended,
          optionsIsFetching: false,
          errorOptions: action.payload.error,
        },
      };
    }

    case REQUEST_HOME_FAST_NEWS: {
      return {
        ...state,
        fastNews: {
          ...state.fastNews,
          isFetching: true,
        },
      };
    }

    case RECEIVE_HOME_FAST_NEWS_SUCCESS: {
      return {
        ...state,
        fastNews: {
          ...state.fastNews,
          isFetching: false,
          fastNewsArticles: action.payload.fastNews,
        },
      };
    }

    case RECEIVE_HOME_FAST_NEWS_ERROR: {
      return {
        ...state,
        fastNews: {
          ...state.fastNews,
          isFetching: false,
          error: action.payload.error,
        },
      };
    }

    case REQUEST_HOME_TOP_ARTICLE: {
      return {
        ...state,
        topArticle: {
          ...state.topArticle,
          isFetching: true,
        },
      };
    }

    case RECEIVE_HOME_TOP_ARTICLE_SUCCESS: {
      return {
        ...state,
        topArticle: {
          ...state.topArticle,
          isFetching: false,
          topArticleData: action.payload.topArticle,
        },
      };
    }

    case RECEIVE_HOME_TOP_ARTICLE_ERROR: {
      return {
        ...state,
        topArticle: {
          ...state.topArticle,
          isFetching: false,
          error: action.payload.error,
        },
      };
    }

    case REQUEST_HOME_EBOOKS: {
      return {
        ...state,
        ebooks: {
          ...state.ebooks,
          isFetching: true,
        },
      };
    }

    case RECEIVE_HOME_EBOOKS_SUCCESS: {
      return {
        ...state,
        ebooks: {
          ...state.ebooks,
          isFetching: false,
          ebooksData: action.payload.ebooks.articles,
        },
      };
    }

    case RECEIVE_HOME_EBOOKS_ERROR: {
      return {
        ...state,
        ebooks: {
          ...state.ebooks,
          isFetching: false,
          error: action.payload.error,
        },
      };
    }

    case SET_HOME_RECOMMENDED_FILTER: {
      return {
        ...state,
        recommended: {
          ...state.recommended,
          filter: action.payload.filter,
        },
      };
    }

    case SET_HOME_MOST_READ_FILTER: {
      return {
        ...state,
        mostRead: {
          ...state.mostRead,
          filter: action.payload.filter,
        },
      };
    }

    case RECEIVE_HOME_BANNER_SUCCESS: {
      return {
        ...state,
        adBanner: action.payload.banner,
      };
    }

    default: {
      return state;
    }
  }
};
