import { connect } from 'react-redux';
import * as React from 'react';
import { rem } from 'polished';
import styled from 'styled-components';
import { FlexRow, prop, FlexCol } from 'eshop-defaults/lib';
import AuthorTab from '../../components/Author/AuthorTab';
import MetaTags from '../../components/MetaTags/MetaTags';
import ToTopButton from '../../components/_helpers/Default/ToTopButton';
import { ThenArg } from '../../services/API';
import API from '../../services/API';
import ArticleList from '../../components/Article/ArticleList';
import { langSelector, titleSelector } from '../Category/selectors';
import { connectSsr } from 'ssr-service';
import { loadAuthor } from './actions';
import {
  authorDataSelector,
  authorFetchingSelector,
  authorArticlesOffsetSelector,
} from './selectors';
import Pagination from '../../components/Pagination/Pagination';
import { push } from 'react-router-redux';
import { getAuthorMetaTags } from '../../utilities/metatags';
import { withRouter, WithRouterProps } from 'react-router';

export const MainWrapper = styled.div`
  width: 100%;
  height: auto;

  ${({ theme }) => theme.mediab.m580`
     padding-top: ${rem(16)};
  `}
`;

const ContentAndSidebarsWrapper = styled(FlexRow)`
  margin-bottom: ${rem(48)};

  ${({ theme }) => theme.mediab.m580`
    flex-flow: column;
  `};
  @media (max-width: 700px) {
    flex-direction: column;
  }
`;

const AuthorWrapper = styled.div`
  width: ${rem(360)};
  min-height: ${rem(350)};
  margin-right: ${rem(48)};

  @media (max-width: 1200px) {
    width: 30vw;
    min-width: 300px;
    margin-right: ${rem(38)};
  }
  @media (max-width: 700px) {
    margin: 0;
    padding: 0;
    width: 100%;
    min-width: 100%;
    min-height: auto;
  }
`;

const Wrapper = styled.div`
  width: 100%;
`;

const ArticlesPaginationWrapper = styled(FlexCol)`
  width: 100%;
`;

interface State {
  crumbs: any[];
}

export interface AuthorProps {
  parentProps: {};
  location: any;
  dispatch: any;
  isError: boolean;
  lang: string;
  author: ThenArg<typeof API.loadAuthorDetail>;
  isFetching: boolean;
  offset: number;
  params: {
    author_id: string;
    author_name: string;
  };
}

class Author extends React.Component<AuthorProps & WithRouterProps, State> {
  public static async getInitialProps(
    { params, location, dispatch },
    parentProps,
  ) {
    const { author_id } = params;
    const {
      query: { offset },
    } = location;
    try {
      await dispatch(loadAuthor(author_id, offset));

      return;
    } catch (exp) {
      return {
        isError: true,
      };
    }
  }

  public handleOffsetChange = async (newOffset: number) => {
    this.props.dispatch(
      push({
        pathname: this.props.location.pathname,
        search: `?offset=${newOffset}`,
      }),
    );
  };

  public render() {
    const { author, isFetching, offset, lang } = this.props;

    const authorName = prop(author, 'name', '');
    const authorUrl = prop(author, 'url', '');
    const authorId = prop(author, 'author_id', '');
    const authorAnnot = prop(author, 'json_content.annotation', '');
    const authorArticles = prop(author, 'articles', []).map(article => {
      return {
        ...article,
        author: { name: authorName, url: authorUrl, author_id: authorId },
      };
    });
    const totalArticles = prop(author, 'total_articles', 0);

    const getPaginationQuery = () => {
      return `${this.props.location.pathname}?`;
    };

    return (
      <React.Fragment>
        <MetaTags tags={getAuthorMetaTags(author)} />
        <Wrapper className="container container--wide">
          <MainWrapper>
            <ContentAndSidebarsWrapper>
              <FlexRow>
                <AuthorWrapper>
                  <AuthorTab
                    name={authorName}
                    annotation={authorAnnot}
                    count={totalArticles}
                  />
                </AuthorWrapper>
              </FlexRow>
              <ArticlesPaginationWrapper>
                <ArticleList
                  lang={lang}
                  articles={authorArticles}
                  isFetching={isFetching}
                />
                <Pagination
                  query={getPaginationQuery()}
                  totalItems={totalArticles}
                  limit={10}
                  offset={offset}
                  onOffsetChange={this.handleOffsetChange}
                />
              </ArticlesPaginationWrapper>
            </ContentAndSidebarsWrapper>
            <ToTopButton />
          </MainWrapper>
        </Wrapper>
      </React.Fragment>
    );
  }
}

const mapStateToProps = state => {
  return {
    defaultTitle: titleSelector(state),
    lang: langSelector(state),
    author: authorDataSelector(state),
    isFetching: authorFetchingSelector(state),
    offset: authorArticlesOffsetSelector(state),
  };
};

export default connect(mapStateToProps)(
  connectSsr({ displayName: 'Home' })(withRouter(Author)),
);
