import * as React from 'react';
import styled from 'styled-components';
import { rem } from 'polished';
import { connectSsr } from 'ssr-service';
import { connect } from 'react-redux';
import { __ } from 'react-i18n';
import Pagination from '../../components/Pagination/Pagination';

import MetaTags from '../../components/MetaTags/MetaTags';

import { FlexCol, FlexRowCenterVertical } from 'eshop-defaults';
import { ThenArg } from '../../services/Zasielkovna-API';
import API from '../../services/API';
import ArticlesWithSidebar from '../../components/Home/ArticlesWithSidebar';
import SidebarArticlesWrapper from '../../components/Home/SidebarArticlesWrapper';
import { push } from 'react-router-redux';
import { withRouter, WithRouterProps } from 'react-router';

import { langSelector } from '../Category/selectors';

import {
  loadHomeMostRead,
  loadHomeRecommended,
  loadHomeFastNews,
  loadHomeEbooks,
} from '../Home/actions';
import {
  mostReadArticlesSelector,
  recommendedArticlesSelector,
  ebooksSelector,
  fastNewsSelector,
  recommendedArticlesOptionsSelector,
} from '../Home/selectors';
import {
  articlesWithTagOffsetSelector,
  articlesWithTagSelector,
  articlesWithTagTotalSelector,
  articlesWithTagIsFetchingSelector,
} from './selectors';
import ArticleList from '../../components/Article/ArticleList';
import { loadArticlesWithTag } from './actions';

interface Props {
  offset: number;
  lang: string;
  params: { tag_id: string; tag_name: string };
  location: {
    query: {
      offset?: number;
    };
    pathname: string;
  };
  dispatch: (action: any) => void;
  ebooks: {
    ebooksData: ThenArg<typeof API.loadArticles>;
    isFetching: boolean;
  };
  mostRead: {
    isFetching: boolean;
    mostReadArticles: ThenArg<typeof API.loadArticles>;
    filter?: string;
  };
  recommended: {
    isFetching: boolean;
    recommendedArticles: ThenArg<typeof API.loadArticles>;
    filter?: string;
  };
  recommendedOptions: ThenArg<typeof API.loadSitemapSubtree>['sitemap_tree'];
  fastNews: {
    isFetching: boolean;
    fastNewsArticles: ThenArg<typeof API.loadArticles>;
  };
  articles: any;
  isFetching: boolean;
  articlesTotal: number;
}
// Styled Components

const Wrapper = styled.div`
  padding: ${rem(24)} ${rem(20)};
`;

const Header = styled(FlexCol)`
  width: 100%;
  padding-bottom: ${rem(32)};
  margin-bottom: ${rem(2)};
  border-bottom: ${rem(1)} solid ${({ theme }) => theme.blogColors.border};
  line-height: ${rem(32)};

  ${({ theme }) => theme.mediab.m580`
     padding: 0;
     border: 0;
  `}
`;

const SearchedTermWrapper = styled(FlexRowCenterVertical)`
  color: ${({ theme }) => theme.blogColors.primary};
  font-size: ${rem(32)};
  font-family: ${({ theme }) => theme.blogFonts.primaryFont};
  font-weight: 500;
  text-transform: uppercase;

  ${({ theme }) => theme.mediab.m580`
     font-size: ${rem(20)};
  `}
`;

class TagsPage extends React.Component<Props & WithRouterProps> {
  public static async getInitialProps(props) {
    const { dispatch, location, params } = props;
    const {
      query: { offset },
    } = location;
    const { tag_id } = params;

    try {
      await Promise.all[
        (await dispatch(loadArticlesWithTag(tag_id, 10, offset)),
        await dispatch(loadHomeMostRead()),
        await dispatch(loadHomeRecommended(null)),
        await dispatch(loadHomeFastNews()),
        await dispatch(loadHomeEbooks()))
      ];

      return {};
    } catch (exp) {
      return {
        isError: true,
      };
    }
  }

  public getMainContent() {
    const {
      offset,
      articles,
      lang,
      isFetching,
      articlesTotal,
      params,
    } = this.props;
    const { tag_name } = params;

    return (
      <>
        <Header>
          <SearchedTermWrapper>{`${__(
            'Zoznam článkov podľa tagu',
          )} - ${tag_name}`}</SearchedTermWrapper>
        </Header>
        <FlexCol>
          <ArticleList
            lang={lang}
            articles={articles}
            isFetching={isFetching}
          />
          <Pagination
            query={this.getPaginationQuery()}
            totalItems={articlesTotal}
            limit={10}
            offset={offset}
            onOffsetChange={this.handleOffsetChange}
          />
        </FlexCol>
      </>
    );
  }

  public getSidebarContent() {
    const {
      fastNews,
      mostRead,
      recommended,
      recommendedOptions,
      lang,
      ebooks,
    } = this.props;

    const mostReadFilter = [
      { name: __('TÝŽDEŇ'), value: 'week' },
      { name: __('MESIAC'), value: 'month' },
      { name: __('ROK'), value: 'year' },
    ];

    const recommendedOptionsRemapped =
      recommendedOptions &&
      recommendedOptions.map(o => ({
        name: o.name || '',
        value: o.id ? o.id.toString() : '',
      }));

    return (
      <SidebarArticlesWrapper
        fastNews={fastNews}
        mostRead={mostRead}
        recommended={recommended}
        ebooks={ebooks}
        mostReadFilter={mostReadFilter}
        recommendedFilter={recommendedOptionsRemapped}
        dispatch={this.props.dispatch}
        lang={lang}
      />
    );
  }

  public render() {
    const { params } = this.props;
    const { tag_name } = params;

    return (
      <Wrapper>
        <MetaTags tags={{ title: `Zoznam článkov podľa tagu - ${tag_name}` }} />
        <ArticlesWithSidebar
          mainContentChildren={this.getMainContent()}
          sidebarChildren={this.getSidebarContent()}
        />
      </Wrapper>
    );
  }

  public handleOffsetChange = async (newOffset: number) => {
    this.props.dispatch(
      push({
        pathname: this.props.location.pathname,
        search: `?offset=${newOffset}`,
      }),
    );
  };

  public getPaginationQuery = () => {
    return `${this.props.location.pathname}?`;
  };
}

const mapStateToProps = state => ({
  mostRead: mostReadArticlesSelector(state),
  recommended: recommendedArticlesSelector(state),
  recommendedOptions: recommendedArticlesOptionsSelector(state),
  fastNews: fastNewsSelector(state),
  ebooks: ebooksSelector(state),
  offset: articlesWithTagOffsetSelector(state),
  articles: articlesWithTagSelector(state),
  articlesTotal: articlesWithTagTotalSelector(state),
  isFetching: articlesWithTagIsFetchingSelector(state),
  lang: langSelector(state),
});

export default connect(mapStateToProps)(
  connectSsr({ displayName: 'TagsPage' })(withRouter(TagsPage)),
);
