import {
  REQUEST_ARTICLES_WITH_BRAND,
  RECEIVE_ARTICLES_WITH_BRAND_SUCCESS,
  RECEIVE_ARTICLES_WITH_BRAND_ERROR,
  SET_ARTICLES_WITH_BRAND_FILTERS,
} from './constants';
import { langSelector } from '../Category/selectors';
import APIClass from '../../services/API';

const requestArticlesWithBrand = () => ({
  type: REQUEST_ARTICLES_WITH_BRAND,
});

const receiveArticlesWithBrandSuccess = (articles, total) => ({
  type: RECEIVE_ARTICLES_WITH_BRAND_SUCCESS,
  payload: {
    articles,
    total,
  },
});

const receiveArticlesWithBrandError = error => ({
  type: RECEIVE_ARTICLES_WITH_BRAND_ERROR,
  payload: {
    error,
  },
});

export const setArticlesWithBrandFilters = filters => ({
  type: SET_ARTICLES_WITH_BRAND_FILTERS,
  payload: {
    filters,
  },
});

export const loadArticlesWithBrand = (
  brandName,
  limit: number = 10,
  offset: number = 0,
) => {
  return async (dispatch, getState, API) => {
    try {
      dispatch(requestArticlesWithBrand());
      dispatch(setArticlesWithBrandFilters({ offset }));

      const lang = langSelector(getState());
      const ebooks = await APIClass.loadArticles(
        {
          limit,
          offset,
          sfForm: 'Article',
          brandName,
          onlyActual: '1',
          isPublished: '1',
          tagExcludeId: 1518,
          sort: 'created_date',
          replaceCurrentLangTags: '1',
        },
        { xAcceptLanguage: lang },
      );
      dispatch(receiveArticlesWithBrandSuccess(ebooks.articles, ebooks.total));
    } catch (e) {
      dispatch(receiveArticlesWithBrandError(e));
    }
  };
};
