import * as React from 'react';
import { __ } from 'react-i18n/lib';
import styled from 'styled-components';
import API, { ThenArg } from '../../services/API';
import { connect } from 'react-redux';
// import { TitlePlaceholder } from '../../theme/globalStyles';
import Pagination from '../../components/Pagination/Pagination';
import { rem } from 'polished';
import {
  tokenSelector,
  titleSelector,
  categoryIsFetching,
  categoryArticles,
  categoryArticlesOffsetSelector,
  categoryDataSelector,
} from './selectors';
import { langSelector } from '../App/selectors';
import MetaTags from '../../components/MetaTags/MetaTags';
import ArticleList from '../../components/Article/ArticleList';
import { loadSitemapArticles, loadSitemapData } from './actions';
import {
  mostReadArticlesSelector,
  recommendedArticlesSelector,
  fastNewsSelector,
  ebooksSelector,
  recommendedArticlesOptionsSelector,
} from '../Home/selectors';
import ArticlesWithSidebar from '../../components/Home/ArticlesWithSidebar';
import SidebarArticlesWrapper from '../../components/Home/SidebarArticlesWrapper';
import {
  loadHomeRecommended,
  loadHomeMostRead,
  loadHomeFastNews,
  loadHomeEbooks,
} from '../Home/actions';
import { FlexCol } from 'eshop-defaults';
import { push } from 'react-router-redux';
import { getCategoryMetaTags } from '../../utilities/metatags';
import { prop } from '../../utilities';
import { withRouter, WithRouterProps } from 'react-router';

// const CategoryEmptyPlaceholder = styled.p`
//   ${TitlePlaceholder}
// `;

export const NeedHelpCol = styled.div`
  display: none;
  width: ${({ theme }) => rem(273 + theme.grid.padding / 2)};
  padding-right: 0;
  ${({ theme }) => theme.media(1024)} {
    display: block;
  }
  position: -webkit-sticky; /* Safari */
  position: sticky;
  top: 0;
`;

export const ProductsCol = styled.div`
  ${({ theme }) => theme.media(1024)} {
    /* padding-left: ${({ theme }) => rem(273 + theme.grid.padding / 2 + 15)};
    margin-left: ${({ theme }) => rem(-(273 + theme.grid.padding / 2))}; */
  }

  & > div:not(:last-of-type) {
    margin-bottom: ${rem(15)};
  }
`;

const PaginationWrapper = styled.div`
  margin-bottom: ${rem(48)};
`;

export type SelectedAttribs = SelectedAttrib[];

export interface SelectedAttrib {
  name: number;
  value: number;
}

interface State {
  crumbs: any[];
}

export interface CategoryProps {
  location: any;
  category: any;
  parentProps: any;
  loading: boolean;
  dispatch: any;
  lang: string;
  defaultTitle: string;
  token: string;
  isLoading: boolean;
  limit: number;
  sort: string;
  sortDir: string;
  isFetching: boolean;
  recommendedOptions: ThenArg<typeof API.loadSitemapSubtree>['sitemap_tree'];
  data: ThenArg<typeof API.loadSitemap>;
  // articles: ThenArg<typeof API.loadArticles>;
  articles: any;
  offset: number;
  ebooks: {
    ebooksData: ThenArg<typeof API.loadArticles>;
    isFetching: boolean;
  };
  mostRead: {
    isFetching: boolean;
    mostReadArticles: ThenArg<typeof API.loadArticles>;
    filter?: string;
  };
  recommended: {
    isFetching: boolean;
    recommendedArticles: ThenArg<typeof API.loadArticles>;
    filter?: string;
  };
  fastNews: {
    isFetching: boolean;
    fastNewsArticles: ThenArg<typeof API.loadArticles>;
  };
}

class CMSCategory extends React.Component<
  CategoryProps & WithRouterProps,
  State
> {
  public static async getInitialProps({ sitemapId }, parentProps) {
    const { dispatch, location } = parentProps;
    const {
      query: { offset },
    } = location;

    try {
      await Promise.all[
        (await dispatch(loadSitemapArticles(sitemapId, offset)),
        await dispatch(loadHomeMostRead()),
        await dispatch(loadHomeRecommended(null)),
        await dispatch(loadHomeFastNews()),
        await dispatch(loadHomeEbooks()),
        await dispatch(loadSitemapData(sitemapId)))
      ];

      return {};
    } catch (exp) {
      return {
        isError: true,
      };
    }
  }

  public getMainContent() {
    const { articles, isFetching, offset, lang } = this.props;

    return (
      <FlexCol>
        <ArticleList
          lang={lang}
          articles={articles.articles}
          isFetching={isFetching}
        />
        <PaginationWrapper>
          <Pagination
            query={this.getPaginationQuery()}
            totalItems={articles.total}
            limit={10}
            offset={offset}
            onOffsetChange={this.handleOffsetChange}
          />
        </PaginationWrapper>
      </FlexCol>
    );
  }

  public getPaginationQuery = () => {
    return `${this.props.location.pathname}?`;
  };

  public getSidebarContent() {
    const {
      fastNews,
      mostRead,
      recommended,
      recommendedOptions,
      ebooks,
      lang,
    } = this.props;

    const mostReadFilter = [
      { name: __('TÝŽDEŇ'), value: 'week' },
      { name: __('MESIAC'), value: 'month' },
      { name: __('ROK'), value: 'year' },
    ];

    const recommendedFilter =
      recommendedOptions &&
      recommendedOptions.map(o => ({
        name: o.name || '',
        value: o.id ? o.id.toString() : '',
      }));

    return (
      <SidebarArticlesWrapper
        fastNews={fastNews}
        mostRead={mostRead}
        recommended={recommended}
        ebooks={ebooks}
        mostReadFilter={mostReadFilter}
        recommendedFilter={recommendedFilter}
        dispatch={this.props.dispatch}
        lang={lang}
      />
    );
  }

  public render() {
    return (
      <>
        <MetaTags tags={getCategoryMetaTags(prop(this.props, 'data'))} />
        <ArticlesWithSidebar
          mainContentChildren={this.getMainContent()}
          sidebarChildren={this.getSidebarContent()}
        />
      </>
    );
  }

  public handleOffsetChange = async (newOffset: number) => {
    this.props.dispatch(
      push({
        pathname: this.props.location.pathname,
        search: `?offset=${newOffset}`,
      }),
    );
  };
}

const mapStateToProps = state => {
  return {
    defaultTitle: titleSelector(state),
    lang: langSelector(state),
    token: tokenSelector(state),
    isFetching: categoryIsFetching(state),
    articles: categoryArticles(state),
    offset: categoryArticlesOffsetSelector(state),
    mostRead: mostReadArticlesSelector(state),
    recommended: recommendedArticlesSelector(state),
    recommendedOptions: recommendedArticlesOptionsSelector(state),
    fastNews: fastNewsSelector(state),
    ebooks: ebooksSelector(state),
    data: categoryDataSelector(state),
  };
};

export default connect(mapStateToProps)(withRouter(CMSCategory));
