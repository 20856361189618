import * as React from 'react';
import styled, { css } from 'styled-components';
import { rem } from 'polished';

const IconStyles = css`
  vertical-align: middle;
  width: ${rem(24)};
  height: ${rem(24)};
`;

export const IconWrapper = styled.svg`
  ${IconStyles};
  fill: ${({ theme }) => theme.color.text};
`;

export const IconPath = styled.path``;

const icons = {
  arrow:
    'M511,850.7l70.7-70.7L363.2,561.5h488.3v-100H364.2L581.7,244L511,173.3L172.5,512.5L511,850.7z',
  at:
    'M512,85.3c-235.6,0-426.7,191-426.7,426.7s191,426.7,426.7,426.7h213.3v-85.3H512C324.4,851,173,699.6,170.7,512C173,324.4,324.4,173,512,170.7C699.6,173,851,324.4,853.3,512v61c-0.9,35.5-28.6,64.5-64,67c-35.4-2.5-63.1-31.5-64-67v-61c0-117.8-95.5-213.4-213.3-213.4s-213.4,95.5-213.4,213.3c0,117.8,95.5,213.4,213.3,213.4c56.7,0,111.1-22.5,151.1-62.7c29.9,39.6,76.7,62.8,126.3,62.7c82.8-0.3,149.6-67.6,149.4-150.4c0-0.6,0-1.3,0-1.9v-61C938.7,276.4,747.6,85.3,512,85.3z M512,640c-70.7,0-128-57.3-128-128s57.3-128,128-128s128,57.3,128,128S582.7,640,512,640z',
  card:
    'M930.9,116.4H93.1C41.7,116.5,0.2,158.1,0,209.5v605.1c0.2,51.3,41.7,92.9,93.1,93.1h837.8c51.3-0.2,92.9-41.7,93.1-93.1V209.5C1023.8,158.1,982.3,116.5,930.9,116.4z M930.9,814.5H93.1V535.3h837.8V814.5z M93.1,349.1V209.5h837.8v139.6H93.1z',
  cart:
    'M835.6,268.8h-92.4C740.1,144.1,636.7,45.3,512,48c-124.7-2.7-228.1,96.1-231.1,220.8h-92.4c-49.9-0.9-91.2,38.6-92.4,88.5v530.3c1.3,49.9,42.6,89.3,92.4,88.4h647.1c49.9,0.9,91.2-38.5,92.4-88.4V357.4C926.8,307.4,885.5,267.9,835.6,268.8z M512,136.4c74.8-1.7,136.9,57.5,138.7,132.4c0,0,0,0,0,0H373.3C375.1,194,437.1,134.7,512,136.4C512,136.4,512,136.4,512,136.4z M835.6,887.6H188.4V357.4h647.1V887.6z M512,534.1c-74.8,1.7-136.9-57.5-138.7-132.4c0-0.1,0-0.1,0-0.2h-92.4c2.9,124.8,106.3,223.7,231.1,221c124.7,2.7,228.1-96.1,231.1-220.8h-92.4C648.9,476.5,586.9,535.8,512,534.1C512,534.1,512,534.1,512,534.1z',
  clock:
    'M512,85.3c-0.1,0-0.3,0-0.4,0C275.9,85.6,85.1,276.8,85.3,512.4s191.5,426.5,427.1,426.2c235.5-0.2,426.2-191.2,426.2-426.7C938.7,276.4,747.6,85.3,512,85.3z M512,853.3c-188.5,0-341.3-152.8-341.3-341.3S323.5,170.7,512,170.7S853.3,323.5,853.3,512S700.5,853.3,512,853.3z M533.3,298.7h-64v256l224,134.4l32-52.5l-192-113.9V298.7z',
  description:
    'M128,554.7h768v-84.9l-768-0.4V554.7z M128,768h512v-85.3H128V768z M128,256v85.8l768-0.4V256H128z',
  done:
    'M1950,7484a17,17,0,1,1,17,17A16.985,16.985,0,0,1,1950,7484Zm2,0a15,15,0,1,0,15-15A14.979,14.979,0,0,0,1952,7484Zm9.3-.3,1.4-1.4,3.3,3.3,5.8-5.8,1.4,1.4-7.2,7.2Z',
  edit:
    'M599.9,384.9l39.3,39.3L252.6,810.7h-39.3v-39.3L599.9,384.9 M753.5,128c-11.2,0-21.9,4.5-29.9,12.4l-78.1,78.1l160,160l78.1-78.1c16.5-16.6,16.5-43.5,0-60.2l-99.8-99.8C775.8,132.3,764.9,127.8,753.5,128z M599.9,264.1L128,736v160h160l471.9-471.9L599.9,264.1z',
  file:
    'M614.4,0H204.8c-56.4,0.3-101.9,46-101.9,102.4l-0.5,819.2c0,56.4,45.5,102.1,101.9,102.4h614.9c56.5-0.2,102.2-45.9,102.4-102.4V307.2L614.4,0z M204.8,921.6V102.4h358.4v256h256v563.2H204.8z',
  fileSm:
    'M597.3,85.3H256c-47,0.2-84.9,38.3-84.9,85.3l-0.4,682.7c0,47,37.9,85.1,84.9,85.3H768c47.1-0.2,85.2-38.3,85.3-85.3v-512L597.3,85.3z M256,853.3V170.7h298.7V384H768v469.3H256z',
  fileOrderSm:
    'M341.3,768h341.3v-85.3H341.3V768z M341.3,597.3h341.3V512H341.3V597.3z M597.3,85.3H256c-47.1,0.1-85.2,38.3-85.3,85.3v682.7c0,47,37.9,85.1,84.9,85.3H768c47.1-0.1,85.2-38.3,85.3-85.3v-512L597.3,85.3z M768,853.3H256V170.7h298.7V384H768V853.3z',
  gift:
    'M260,306.1c-50.8,1.2-101.8-1-152.7,0.2C66.6,304.8,28.9,327.4,11,364C-4.3,398.1,1.4,465.9,1,508.3c-2.1,24.5-0.7,49.2,4.1,73.3c8,18.9,26.6,31.2,47.2,31.2c0.2,72.1,0.5,144.5,0,216.3c-0.5,77.6-9.8,137,43.6,174.2c41.2,28.7,140.4,17.8,198.1,18.2h541.6c36.9,0,66.6,0.7,92.7-18.4c53.2-38.6,44.5-93.2,43.8-171.8c-0.5-72.8-0.2-145.9-0.2-219c19.8,0,37.9-11.5,46.3-29.5c5.5-23.8,7.1-48.3,4.8-72.6c0.2-40.3,5.3-112.6-9.1-144.9c-17.2-36.7-54.2-59.9-94.7-59.4c-51.5-0.5-103.8,1-155.3,0c29.5-21.3,45.8-25.2,72.6-57.7c52.4-66.2,41.2-162.2-25-214.6c-18.6-14.7-40.4-24.9-63.7-29.8C711-3.1,672.9,2.6,639.8,20c-69.7,39-104,137.8-119.1,214.2c-2.6,13.9-5.3,46.9-8.9,57.3c-7.9-20.8-24.9-322-233.9-288c-69.7,12.5-121.3,72-123.7,142.8c-3.4,86.2,54.6,128.9,105.7,159.8L260,306.1z M624.1,245.7c6.3-37.6,19.5-73.7,39-106.5c58-91.8,159.6,9.3,67.8,67.8C697.9,226.3,661.7,239.4,624.1,245.7L624.1,245.7z M400.7,245.7c-37.7-6.4-73.8-19.6-106.8-38.8c-15.2-8.5-27.3-21.7-34.3-37.6c-9.2-26.6,5-55.5,31.6-64.7c10.7-3.7,22.4-3.7,33.1,0C366,118,394.5,204.7,400.7,245.7L400.7,245.7z M410.3,919.6c-3.6-18.4-2.6-297.6,0.5-307.1l203.2,0.5c3.1,10.3,3.8,285.6,1,307.9L410.3,919.6L410.3,919.6z M103.5,509.8l0.7-101.4l203.7,0.5v101.9L103.5,509.8L103.5,509.8z M716.8,510l0.5-101.4l203.9,0.7v101.6L716.8,510L716.8,510z M410.3,510l0.5-101.6l203.9,0.5l0.2,101.8L410.3,510L410.3,510z M156.3,614.7l150.5,0.2l0.2,303.5H156.7L156.3,614.7L156.3,614.7z M716.8,628l0.2-14.4c34.7-2.4,113.8-0.7,153.4-1c0,38.8,1.9,286.8-1.2,306.7l-152.2-0.5L716.8,628z',
  giftSm:
    'M896,320H780.8c12.6-19,19.3-41.2,19.2-64c-0.2-70.6-57.4-127.8-128-128c-64.3,0.5-124.3,32.9-160,86.4c-35.7-53.5-95.6-85.9-160-86.4c-70.7,0.2-127.8,57.4-128,128c-0.1,22.7,6.6,45,19.2,64H128v256h64v320h640V576h64L896,320L896,320z M672,192c35.3,0,64,28.6,64,64s-28.6,64-64,64H544C544.2,249.4,601.4,192.2,672,192z M288,256c0.1-35.3,28.7-63.9,64-64c70.6,0.2,127.8,57.4,128,128H352C316.7,319.9,288.1,291.3,288,256z M192,384h224v128H192V384z M256,832V576h160v256L256,832L256,832z M480,832V384h64v448L480,832L480,832z M768,832H608V576h160V832z M832,512H608V384h224V512z',
  heart:
    'M512,981.2l-29.9-25.6c-12.8-8.5-268.7-243.1-349.8-328.4C47.1,537.6,0.1,448,0.1,367c-5-174,132.1-319.2,306.1-324.2c0.3,0,0.7,0,1,0c76,0.3,149.1,29.2,204.7,81c55.6-51.8,128.7-80.8,204.7-81c174.1,4.4,311.6,149.1,307.1,323.2c0,0.3,0,0.7,0,1c0,85.3-46.9,174.9-132.2,264.5c-81,85.3-341.2,315.7-349.8,328.4L512,981.2z',
  heartO:
    'M512,981.2l-29.9-25.6c-12.8-8.5-268.7-243.1-349.8-328.4C47.1,537.6,0.1,448,0.1,367c-5-174,132.1-319.2,306.1-324.2c0.3,0,0.7,0,1,0c76,0.3,149.1,29.2,204.7,81c55.6-51.8,128.7-80.8,204.7-81c174.1,4.4,311.6,149.1,307.1,323.2c0,0.3,0,0.7,0,1c0,85.3-46.9,174.9-132.2,264.5c-81,85.3-341.2,315.7-349.8,328.4L512,981.2z M307.3,128.1C180.4,132.4,81.1,238.8,85.4,365.6c0,0.4,0,0.9,0,1.3c0,59.7,38.4,132.2,106.6,204.7c64,68.2,247.4,234.6,319.9,298.6c72.5-64,255.9-230.3,319.9-298.6c68.2-76.8,106.6-145,106.6-204.7c5.1-126.8-93.6-233.8-220.5-238.8c-0.4,0-0.9,0-1.3,0c-67,0.4-130.1,32-170.6,85.3L512,256.1l-34.1-42.7C437.9,159.3,374.5,127.6,307.3,128.1z',
  help:
    'M512,85.3c-235.6,0-426.7,191-426.7,426.7s191,426.7,426.7,426.7s426.7-191,426.7-426.7S747.6,85.3,512,85.3z M512,853.3c-188.5,0-341.3-152.8-341.3-341.3S323.5,170.7,512,170.7S853.3,323.5,853.3,512C853.1,700.4,700.4,853.1,512,853.3z M512,256c-94.3,0-170.7,76.4-170.7,170.7h85.3c0-47.1,38.2-85.3,85.3-85.3s85.3,38.2,85.3,85.3c0,85.3-128,74.7-128,213.3h85.3c0-96,128-106.7,128-213.3C682.7,332.4,606.3,256,512,256z M469.3,768h85.3v-85.3h-85.3V768z',
  home:
    'M430.1,819.2V584.3h163.8v234.9h204.8V506h122.9L512,153.6L102.4,506h122.9v313.2H430.1z',
  homeO:
    'M512,264.1l213.3,192v333.2H640v-256H384v256h-85.3V456.1L512,264.1 M512,149.3l-426.7,384h128v341.3h256v-256h85.3v256h256V533.3h128L512,149.3z',
  info:
    'M512,0C229.2,0,0,229.2,0,512s229.2,512,512,512s512-229.2,512-512S794.8,0,512,0z M618.6,793.5c-26.3,10.4-47.3,18.3-63,23.8c-17.7,5.8-36.1,8.5-54.7,8.2c-26.8,1.7-53.3-6.6-74.4-23.3c-17.3-14.7-27-36.5-26.5-59.2c0-9.6,0.7-19.1,2-28.6c1.3-9.7,3.4-20.6,6.4-32.9l33-116.5c2.9-11.2,5.4-21.9,7.3-31.7c1.9-9,2.9-18.2,2.9-27.4c1.2-11.2-2.1-22.4-9.2-31.1c-10.3-7-22.8-10.1-35.3-8.7c-8.9,0-17.7,1.3-26.3,3.9c-8.9,2.7-16.6,5.2-22.9,7.3l8.7-35.9c21.6-8.8,42.3-16.3,62-22.6c18.1-5.9,37-9,56-9.1c26.4-1.6,52.4,6.5,73.1,23c17,15,26.5,36.9,25.7,59.6c0,4.9-0.6,13.8-1.8,26.8c-1,11.9-3.2,23.7-6.6,35.2l-32.6,116.1c-2.7,9.3-5,20-7.3,31.9c-1.8,8.9-2.8,18-3.1,27.1c-1.4,11.5,2.4,23.1,10.4,31.5c10.7,6.6,23.3,9.6,35.8,8.4c9.2-0.2,18.3-1.6,27.1-4.2c7.5-1.9,14.8-4.3,21.9-7.3L618.6,793.5z M612.7,321.8c-14.9,14.1-34.8,21.8-55.3,21.4c-20.6,0.4-40.4-7.3-55.4-21.4c-14.7-13-23.1-31.6-23-51.2c-0.1-19.8,8.3-38.7,23.1-51.9c15-14.2,34.9-22,55.5-21.6c20.6-0.4,40.4,7.3,55.3,21.6c28.6,25.9,30.8,70.1,4.9,98.7c-1.6,1.7-3.2,3.4-4.9,4.9L612.7,321.8z',
  infoO:
    'M469.3,384h85.3v-85.3h-85.3V384z M469.3,725.3h85.3v-256h-85.3V725.3z M512,85.3c-235.6,0-426.7,191-426.7,426.7s191,426.7,426.7,426.7s426.7-191,426.7-426.7S747.6,85.3,512,85.3z M512,853.3c-188.5,0-341.3-152.8-341.3-341.3S323.5,170.7,512,170.7S853.3,323.5,853.3,512C853.1,700.4,700.4,853.1,512,853.3z',
  location:
    'M512,85.3c-164.9,0-298.7,133.7-298.7,298.7c0,224,298.7,554.7,298.7,554.7S810.7,608,810.7,384C810.7,219.1,676.9,85.3,512,85.3z M298.7,384c0-117.8,95.5-213.3,213.3-213.3S725.3,266.2,725.3,384c0,122.9-122.9,306.8-213.3,421.5C423.3,691.6,298.7,505.6,298.7,384z M512,277.3c-58.9,0-106.7,47.8-106.7,106.7c0,58.9,47.8,106.7,106.7,106.7S618.7,442.9,618.7,384C618.7,325.1,570.9,277.3,512,277.3z',
  locationAlt:
    'M512,96c-123.6,0.2-223.8,100.4-224,224c3,41.1,14.2,81.3,33,118c19.9,44.5,42.3,87.9,67,130c48.7,84,98,154,98,154l26,38l26-38c0,0,49.2-70,98-154c24.7-42.1,47.1-85.5,67-130c18.8-36.8,30-76.9,33-118C735.8,196.4,635.6,96.2,512,96z M512,160c88.1-0.3,159.7,70.9,160,159c0,0.3,0,0.6,0,1c-3.8,32-12.9,63.1-27,92c-19.5,42.5-41.1,83.8-65,124c-34.2,59.2-51.7,84.8-68,109c-16.3-24.3-33.8-49.9-68-109c-23.8-40.2-45.5-81.5-65-124c-14.1-28.9-23.2-60-27-92c-0.3-88.1,70.9-159.7,159-160C511.4,160,511.7,160,512,160z M512,256c-35.3,0-64,28.7-64,64s28.7,64,64,64s64-28.7,64-64S547.3,256,512,256z M349,622c-123.9,22.5-221,71.7-221,146c0,105,193.1,160,384,160s384-55,384-160c0-74.2-97.1-123.5-221-146l-39,59c120.9,17.5,196,57.9,196,87c0,38.4-127.6,96-320,96s-320-57.6-320-96c0-29.1,75.1-69.5,196-87L349,622z',
  lock:
    'M512,725.3c47.1,0,85.3-38.2,85.3-85.3s-38.2-85.3-85.3-85.3s-85.3,38.2-85.3,85.3C426.8,687.1,464.9,725.2,512,725.3z M725.3,341.3V265c1.8-112.1-81.2-207.6-192.4-221.4C415.4,32.2,311,118.2,299.7,235.6c-0.7,6.8-1,13.6-1,20.4H384c-1.2-48.8,26.1-93.8,70-115.2c63.9-31.5,141.2-5.3,172.7,58.5c8.7,17.6,13.3,37,13.3,56.7v85.3H170.7v597.3h682.7V341.3H725.3z M768,853.3H256V426.7h512V853.3z',
  mail:
    'M853.3,170.7H170.7c-47,0.2-84.9,38.4-84.9,85.3l-0.4,512c0.1,47.1,38.3,85.2,85.3,85.3h682.7c47.1-0.1,85.2-38.3,85.3-85.3V256C938.5,208.9,900.4,170.8,853.3,170.7z M853.3,768H170.7V341.3L512,554.7l341.3-213.3V768z M512,469.3L170.7,256h682.7L512,469.3z',
  onStock:
    'M802,3.4c-21.4,1.9-38.9,17.8-42.9,38.8c-2.6,14.4-1.2,223.4-1,245.2v244.5c1.4,27.6,1,55.3-1.2,82.9c-112.5,0.7-203.3,92-203.5,204.5c-101.4,2.4-204.9,0-306.6,0.2c-82,0.2-69.3-2.9-112.7,40.5l-75.7,76.7l71.3,71c20.8-17.3,46.5-46,66.8-66.4c19.2-19.2,11.5-20.4,48.2-19.9c22,0.2,45.1,0.5,67.1,0.2c90.1-1,181.5-0.7,271.8,0c13.4,25.3,32.6,47.1,56.1,63.5c91.1,66.1,218.5,45.7,284.6-45.4c32-44.1,44.9-99.1,36.1-152.8c-10.2-60-46.5-112.4-99.2-142.9L861,103.4l101.2,0.2c0.2-0.5,0.7-2.2,1-1.7c0.4-0.6,0.6-1.2,0.7-1.9c2.7-32.9,2.3-66-1.2-98.8C948.7,0.1,806.3-1.6,802,3.5V3.4z M351.4,513.4l150.1-0.7c3.5,34,3.9,68.3,1.4,102.4c-51.2,3.1-102.5,2.7-153.7-1c-1.7-17.3-0.2-33.8-0.5-51.1C348.5,553.2,344.3,517.8,351.4,513.4L351.4,513.4z M297.9,257.1c0.7-49.6-0.7-99.7,0-149.3c0.5-36.4-10.6-94.7,40.5-105c21.1-4.3,249.8-1.2,292-1.2c61.6-0.2,76.2,10.8,76.7,72.6c0.2,48.7-0.5,97.6,0,146.2c1,97.3,0,195.1,0,292.4l-102.1-1V359.2l-306.8,1V666l153.7,0.2l-1.2,102.1c-32.1,0-197.8,2.2-215.5-1.4c-37.4-7.7-39.1-43.9-39.1-69.5V371.6c-0.2-31.9-5.7-74.5,12.5-96.1c20.6-24.9,51.8-17.8,89.4-18.2L297.9,257.1z M400.4,256.9l1-153.2h203.5l0.2,153.2H400.4z M735.2,720c54.9-12.8,109.8,21.4,122.6,76.3c0.1,0.5,0.2,1.1,0.4,1.6C885.5,929,691.4,972.6,659,844c-13.6-54.5,19.5-109.7,74-123.4c0.7-0.2,1.4-0.3,2.1-0.5L735.2,720z',
  orders:
    'M832,149.3l-64-64l-64,64l-64-64l-64,64l-64-64l-64,64l-64-64l-64,64l-64-64l-64,64l-64-64v853.3l64-64l64,64l64-64l64,64l64-64l64,64l64-64l64,64l64-64l64,64l64-64l64,64V85.3L832,149.3z M810.7,814.5H213.3v-605h597.3V814.5z M256,640h512v85.3H256V640z M256,469.3h512v85.3H256V469.3z M256,298.7h512V384H256V298.7z',
  package:
    'M512,42.7L85.3,277.3v473.6L512,981.3l426.7-234.7V277.3L512,42.7z M832,315.7L512,490.7L192,315.7l320-174.9L832,315.7z M170.7,401.1l298.7,162.1v298.7L170.7,699.7V401.1z M554.7,861.9V563.2l298.7-162.1v298.7L554.7,861.9z',
  phone:
    'M810.7,512H896c0-212.1-171.9-384-384-384v85.3C676.9,213.3,810.7,347.1,810.7,512z M640,512h85.3c0-117.8-95.5-213.3-213.3-213.3V384C582.7,384,640,441.3,640,512z M563.6,741.1c-121.1-61.6-219.6-160.1-281.2-281.2L390.4,352l-26-224H129.3c-23.6,399.9,281.5,743.2,681.4,766.7c28.4,1.7,56.9,1.7,85.3,0V659.6l-224.9-26C671.1,633.6,563.6,741.1,563.6,741.1z',
  printer:
    'M810.7,341.3H768V128H256v213.3h-42.7c-70.7,0-128,57.3-128,128v256H256V896h512V725.3h170.7v-256C938.7,398.6,881.4,341.3,810.7,341.3z M341.3,213.3h341.3v128H341.3V213.3z M682.7,725.3v85.3H341.3V640h341.3V725.3z M853.3,640H768v-85.3H256V640h-85.3V469.3c0-23.6,19.1-42.7,42.7-42.7h597.3c23.6,0,42.7,19.1,42.7,42.7V640z M768,448c-23.6,0-42.7,19.1-42.7,42.7s19.1,42.7,42.7,42.7s42.7-19.1,42.7-42.7S791.6,448,768,448z',
  remove:
    'M661.3,170.7L618.7,128H405.3l-42.7,42.7H213.3V256h597.3v-85.3H661.3z M256,810.7c0.1,47.1,38.3,85.2,85.3,85.3h341.3c47.1-0.1,85.2-38.3,85.3-85.3v-512H256V810.7z M341.3,384h341.4v426.7H341.3V384z',
  search:
    'M679,609h-36.9l-13.1-12.6c109.2-127.2,94.6-318.9-32.6-428.1S277.5,73.7,168.3,201S73.7,519.9,201,629.1c113.7,97.6,281.7,97.6,395.5,0l12.6,13.1v36.8l233.2,232.7l69.5-69.5L679,609z M399.2,609c-115.9,0-209.9-94-209.9-209.9c0-115.9,94-209.9,209.9-209.9c115.9,0,209.9,94,209.9,209.9l0,0c0.1,115.8-93.6,209.7-209.3,209.9C399.5,609,399.3,609,399.2,609L399.2,609z',
  shipping:
    'M0,160v64h608v480H411c-17-67.9-85.8-109.3-153.7-92.3c-45.5,11.3-80.9,46.8-92.3,92.3h-37V544H64v224h101c17,67.9,85.8,109.3,153.7,92.3c45.5-11.3,80.9-46.8,92.3-92.3h266c17,67.9,85.8,109.3,153.7,92.3c45.5-11.3,80.9-46.8,92.3-92.3h101V507l-2-5l-64-192l-7-22H672V160H0z M32,288v64h288v-64H32z M672,352h233l55,164v188h-37c-17-67.9-85.8-109.2-153.8-92.2c-45.4,11.4-80.9,46.8-92.2,92.2h-5V352z M64,416v64h192v-64H64z M288,672c35.3,0,64,28.7,64,64s-28.7,64-64,64s-64-28.7-64-64c-0.3-35.1,27.9-63.7,63-64C287.3,672,287.7,672,288,672z M800,672c35.3,0,64,28.7,64,64s-28.7,64-64,64s-64-28.7-64-64c-0.3-35.1,27.9-63.7,63-64C799.3,672,799.7,672,800,672z',
  star:
    'M512,34.9l162.9,325.8l349.1,46.5L768,640l46.5,349.1L512,826.2L186.2,989.1L256,640L0,407.3l349.1-46.5L512,34.9z',
  statusSuccess:
    'M512,85.3c-235.6,0-426.7,191-426.7,426.7s191,426.7,426.7,426.7s426.7-191,426.7-426.7S747.6,85.3,512,85.3zM512,853.3c-188.5,0-341.3-152.8-341.3-341.3S323.5,170.7,512,170.7S853.3,323.5,853.3,512C853.1,700.4,700.4,853.1,512,853.3zM707.8,323.4L426.7,604.6L316.2,494.5L256,554.7l170.7,170.7L768,384L707.8,323.4z',
  statusError:
    'M622.5,341.3L512,451.8L401.5,341.3l-60.2,60.2L451.8,512L341.3,622.5l60.2,60.2L512,572.2l110.5,110.5l60.2-60.2L572.2,512l110.5-110.5L622.5,341.3z M512.8,85.3c-0.3,0-0.5,0-0.8,0c-235.6,0-426.7,191-426.7,426.7s191,426.7,426.7,426.7s426.7-191,426.7-426.7C938.9,276.6,748.2,85.5,512.8,85.3z M512,853.3c-188.5,0-341.3-152.8-341.3-341.3S323.5,170.7,512,170.7S853.3,323.5,853.3,512C853.1,700.4,700.4,853.1,512,853.3z',
  store:
    'M111.9,424.5l0.2,509.5c-22.3,2.4-66.2-3.1-83.8,3.7C6,947.2-4.5,972.9,4.9,995.2c0,0.1,0,0.1,0.1,0.2c10.3,24.6,30.3,26.2,62.9,26l445.7,0.7c117.9-0.2,238.6,2.4,356-0.2c29.3-0.7,58.3-0.2,87.5-0.4c30.1,0,51.9-3.1,62.7-26.2c9.6-22.1-0.4-47.7-22.5-57.4c-0.2-0.1-0.4-0.2-0.6-0.3c-17.2-6.7-63.6-1.5-86-3.5V424.3c67.1-25.3,111.9-71,112.8-146.9c0.4-43.3,0-86.8,0-130.4c0.2-154.2,12.3-146-115.4-145.8H57.6C-14.1,1.1,1.5,50,1.3,141c0,44-0.2,88.2,0,132.4c-0.9,35.3,10,70,31.2,98.3C52.5,397.5,80.3,416,111.9,424.5L111.9,424.5z M322.5,120.6V86.1c42.9-0.2,104.4-1.3,145.4,0.7v95c0,33.4,5.3,111.9-7,132.6c-23.5,39.1-100.7,40.2-127.5,4.8c-15.6-20.7-10.8-39.4-10.8-68.2L322.5,120.6L322.5,120.6z M555.1,104.3V85.9c37.4,0,114.1-1.3,147.1,0.4v117.9c0,32.3,5.9,92.4-9.9,114.1c-12.7,17.5-33.4,27.3-55,26.2c-29.7,0-46,0-65.3-19.8c-15.8-16.3-16.9-29.5-16.9-57.2L555.1,104.3L555.1,104.3z M789.1,96.2l0.2-10.3h147.6v128.4c0,39.8,5.1,86.4-17.6,110.8c-19.8,21.3-34.3,15.8-66.6,18.9c-22.4,1.3-43.7-9.6-55.7-28.6c-13-18.7-7.9-53.7-7.9-78.7L789.1,96.2L789.1,96.2z M85.9,108.3V85.9h149.3v138.5c0,38,5.5,78.3-16.7,99.6c-12.9,14.7-32.3,21.8-51.7,19.1c-31.7-4.2-35.8,5.1-60.1-15.4C92.4,314,84.8,294.8,86,275L85.9,108.3L85.9,108.3z M199.1,427c29.3-6.7,56.3-21.1,78.3-41.6c5.9,2.8,5.5,4,11,9c3.3,2.9,6.9,5.5,10.6,7.9c26.4,18.6,58.1,28,90.4,26.7c44.9,4.4,89.5-11.3,121.8-42.9l21.1,16.9c25.5,17.6,55.9,26.7,86.8,26c96.1,0,102.7-31.9,126.9-43.5c21,21.7,48.2,36.3,77.9,41.8v506.9l-156.3,0.7V706.7c0-72.8,12.5-128-52.6-128H446.1c-29.3,0-60.3-5.1-77.4,11.9c-17.8,17.6-13.6,36.3-13.6,65.3v278.6c-25.7,0.2-139.6,1.5-156.1-0.7C198.7,766.3,197.2,593.9,199.1,427L199.1,427z M441.9,688.4v-22.9h138.8v269.2h-139L441.9,688.4z',
  thumbUp:
    'M534,144l-10,9L307,368H160v512h539c45.3-0.1,84.4-31.7,94-76l69-320c10.7-52.3-23.1-103.4-75.5-114.1c-6.1-1.2-12.3-1.9-18.6-1.9H584l6-24c7.9-5.3,14.7-12.1,20-20c19.9-27.6,30.4-60.9,30-95c-2.6-48.9-44-86.8-93-85H534z M557,211c13.5,2.6,19,8.1,19,18c0.9,20.7-5.1,41.1-17,58c-3.6,5.6-8.4,10.4-14,14l-11,6l-4,13l-19,72l-10,39.9h267c17.3-0.3,31.6,13.5,31.9,30.8c0,2.8-0.3,5.5-0.9,8.2l-68,320c-3.4,14.8-16.8,25.3-32,25H352V413L557,211z M224,432h64v384h-64V432z',
  user:
    'M512,251.7c49.5,0,89.6,40.1,89.6,89.6s-40.1,89.6-89.6,89.6s-89.6-40.1-89.6-89.6S462.5,251.7,512,251.7C512,251.7,512,251.7,512,251.7 M512,635.7c126.7,0,260.3,62.3,260.3,89.6v46.9H251.7v-46.9C251.7,698,385.3,635.7,512,635.7 M512,170.7c-94.3,0-170.7,76.4-170.7,170.7S417.7,512,512,512s170.7-76.4,170.7-170.7S606.3,170.7,512,170.7z M512,554.7c-113.9,0-341.3,57.2-341.3,170.7v128h682.7v-128C853.3,611.8,625.9,554.7,512,554.7z',
  userPlus:
    'M657.3,828l0.5-96.9h-97.6l0.2,95.6l-98.3,0.5c-1.4,25-1.6,50.2-0.5,75.2c-0.2,11-3.2,17.1,2,23.6l95.7,0.2l0.9,96.7l97.4,0.7l0.2-97.6l96.5,0.7l0,0c0.2-0.7,0.7-2,0.9-1.8l1.4-47.3c2-16.3,1.3-32.9-2-49L657.3,828z M877.9,719.7c-5.9-12-12.7-23.5-20.4-34.5c-72.7-106.1-214.9-150-345.9-148.8c-114.1,0.9-249,33.8-321.5,118.2c-39.5,41.3-63.5,95-67.9,152c-5,66.8-0.9,147.9-0.9,216.5l0,0.1l292.9,0.7v-97.7l-195,0.2c-0.5-101.5-13.7-172.4,79.6-236.6c125.5-86.5,391.9-73.9,475.3,46.8c29.3,42.5,29.7,67.2,30.4,127.3c0.5,53.2,0,106.8,0,160h97.4C901.7,925.2,915.7,799.8,877.9,719.7z M539.5,486c133.8-15.4,229.7-136.3,214.3-270.1C738.5,82.2,617.6-13.8,483.8,1.6c-0.2,0-0.4,0-0.6,0.1l0-0.1C436.1,6.5,391.6,25.4,355.4,56c-11.1,9.4-21.4,19.7-30.9,30.9c-43.3,51.2-63.3,118.2-55.1,184.8C284.9,405.4,405.8,501.4,539.5,486z M486.4,99.3c0.6-0.1,1.3-0.2,1.9-0.3l0,0.1c79.7-12.7,154.7,41.6,167.4,121.4c12.7,79.7-41.6,154.7-121.4,167.4c-79.3,12.6-153.9-41-167.2-120.2C353.6,188.2,407,112.8,486.4,99.3z',
  userPoints:
    'M853.3,277.7h-93c23.7-66.4-10.9-139.5-77.3-163.2c-55.5-19.8-117.3,0.9-149.7,50.1L512,193.2l-21.3-29c-38.6-59-117.8-75.6-176.8-37c-49.6,32.4-70.4,94.7-50.1,150.4h-93c-46.8-0.1-84.8,37.7-84.9,84.5c0,0.3,0,0.6,0,0.9l-0.4,469.3c-0.2,47,37.8,85.2,84.7,85.3c0.2,0,0.4,0,0.6,0h682.7c47,0.2,85.2-37.8,85.3-84.7c0-0.2,0-0.4,0-0.6V363c0.2-47-37.8-85.2-84.7-85.3C853.7,277.7,853.5,277.7,853.3,277.7z M640,192.3c23.6,0,42.7,19.1,42.7,42.7s-19.1,42.7-42.7,42.7s-42.7-19.1-42.7-42.7S616.4,192.3,640,192.3z M384,192.3c23.6,0,42.7,19.1,42.7,42.7s-19.1,42.7-42.7,42.7c-23.6,0-42.7-19.1-42.7-42.7S360.4,192.3,384,192.3z M853.3,832.3H170.7V747h682.7V832.3z M853.3,619H170.7V363h216.7l-88.7,120.7l69.1,49.9L512,337.4l144.2,196.3l69.1-49.9L636.6,363h216.7V619z',
  userSquare:
    'M777.5,246.5v531h-531v-531H777.5 M777.5,170.7h-531c-41.9,0-75.9,34-75.9,75.9v531c0,41.9,34,75.9,75.9,75.9h531c41.8-0.1,75.7-34,75.9-75.9v-531C853.2,204.7,819.3,170.8,777.5,170.7z M512,512c-62.8,0-113.8-50.9-113.8-113.8S449.2,284.4,512,284.4s113.8,50.9,113.8,113.8C625.6,461,574.8,511.8,512,512z M512,360.3c-20.9,0-37.9,17-37.9,37.9s17,37.9,37.9,37.9s37.9-17,37.9-37.9S532.9,360.3,512,360.3z M739.6,739.6H284.4v-58c0-94.8,150.6-135.8,227.6-135.8s227.6,41,227.6,135.8V739.6z M372.1,663.7h279.9c-41.8-27-90.2-41.7-139.9-42.5C462.3,622,413.8,636.7,372.1,663.7z',
};

interface IconPropsIO {
  icon: string;
  className?: string;
}

class Icon extends React.Component<IconPropsIO> {
  public render() {
    const { icon, className } = this.props;

    return (
      <IconWrapper className={className} viewBox="0 0 1024 1024">
        <IconPath d={icons[icon]} />
      </IconWrapper>
    );
  }
}

const IconSpanWrapper = styled.span`
  ${IconStyles};
  display: inline-block;
`;

export const IconCloseWrapper = styled(IconSpanWrapper)`
  position: relative;
  &:before,
  &:after {
    display: block;
    content: '';
    background: ${({ theme }) => theme.color.text};
    border-radius: ${rem(1)};
    width: ${rem(10)};
    height: ${rem(2)};
    position: absolute;
  }
  &:before {
    transform: translate3d(${rem(8)}, ${rem(11)}, 0) rotate(45deg);
  }
  &:after {
    transform: translate3d(${rem(8)}, ${rem(11)}, 0) rotate(-45deg);
  }
`;

export interface IconCloseIO {
  className?: string;
}

export class IconClose extends React.Component<IconCloseIO> {
  public render() {
    const { className } = this.props;
    return <IconCloseWrapper className={className} />;
  }
}

export const IconCaretWrapper = styled(IconSpanWrapper)`
  position: relative;
  &:before {
    display: block;
    content: '';
    border: ${rem(4)} solid transparent;
    border-top-color: ${({ theme }) => theme.color.text};
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate3d(-50%, -25%, 0);
  }
`;

export class IconCaret extends React.Component<{}> {
  public render() {
    return <IconCaretWrapper />;
  }
}

export const IconChevronWrapper = styled(IconSpanWrapper)`
  width: ${rem(16)};
  height: ${rem(16)};
  margin-bottom: ${rem(-1)};
  vertical-align: baseline;
  position: relative;
  &:before,
  &:after {
    display: inline-block;
    content: '';
    background: ${({ theme }) => theme.color.text};
    border-radius: ${rem(1)};
    width: ${rem(2)};
    height: ${rem(8)};
    position: absolute;
    top: 0;
    left: 0;
    ${({ theme }) => theme.transition('background-color')};
  }
  &:before {
    transform: translate3d(${rem(5)}, ${rem(5)}, 0) rotate(-55deg);
  }
  &:after {
    transform: translate3d(${rem(10)}, ${rem(5)}, 0) rotate(55deg);
  }
`;

export class IconChevron extends React.Component<{}> {
  public render() {
    return <IconChevronWrapper />;
  }
}

const IconMenuWrapper = styled(IconSpanWrapper)`
  display: inline-block;
  background: ${({ theme }) => theme.color.white};
  width: ${rem(24)};
  height: ${rem(3)};
  margin: ${rem(13)} ${rem(5)} ${rem(14)} ${rem(3)};
  vertical-align: baseline;
  position: relative;
  ${({ theme }) => theme.transition('background-color')};
  &:before,
  &:after {
    display: inline-block;
    content: '';
    background: ${({ theme }) => theme.color.white};
    width: ${rem(24)};
    height: ${rem(3)};
    position: absolute;
    top: 0;
    left: 0;
    ${({ theme }) => theme.transition('transform')};
  }
  &:before {
    transform: translate3d(0, ${rem(-9)}, 0);
  }
  &:after {
    transform: translate3d(0, ${rem(9)}, 0);
  }
  .header-button--menu-active & {
    background: transparent;
    &:before {
      transform: rotate(-45deg) translate3d(0, 0, 0);
    }
    &:after {
      transform: rotate(45deg) translate3d(0, 0, 0);
    }
  }
`;

export class IconMenu extends React.Component<{}> {
  public render() {
    return <IconMenuWrapper />;
  }
}

const IconStatusWrapper = styled(Icon)`
  &.success {
    fill: ${({ theme }) => theme.color.success};
  }
  &.error {
    fill: ${({ theme }) => theme.color.error};
  }
`;

interface IconStatusIO {
  success?: boolean;
}

export class IconStatus extends React.Component<IconStatusIO> {
  public render() {
    const { success } = this.props;
    return (
      <IconStatusWrapper
        icon={success ? 'statusSuccess' : 'statusError'}
        className={success ? 'success' : 'error'}
      />
    );
  }
}

export default Icon;
