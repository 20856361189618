import * as React from 'react';
import styled, { css } from 'styled-components';
import { rem } from 'polished';
import { FlexCol, FlexRow, prop } from 'eshop-defaults';
import MobilePageSelect from './MobilePageSelect';
import { Link } from 'react-router';

// Interfaces

interface PagiantionProps {
  totalItems: number;
  offset: number;
  limit: number;
  onOffsetChange: (newOffset: number) => void;
  query?: string;
}

interface PaginationState {
  totalPages: number;
}

/*****************/

const PaginationWrapper = styled.div`
  font-size: ${rem(14)};
  line-height: 1;
  text-align: center;
  margin: 0;

  ${({ theme }) => theme.media(500)} {
    margin: ${rem(40)} ${rem(-5)} 0;
  }
`;

const PaginationItemStyles = css`
  display: inline-block;
  border-radius: ${rem(4)};
  text-align: center;
  padding: ${rem(16)} ${rem(10)};
  margin-right: ${rem(5)};
  margin-left: ${rem(5)};
`;

const PaginationItem = styled(Link)<{ active?: boolean }>`
  ${PaginationItemStyles};
  text-decoration: none;
  background: ${({ theme }) => theme.color.gray97};
  color: ${({ theme }) => theme.color.text};
  width: ${rem(40)};
  cursor: pointer;
  ${({ theme }) => theme.transitions('background-color, color')};
  color: ${props =>
    props.active
      ? ({ theme }) => theme.color.white
      : ({ theme }) => theme.blogColors.primary};
  background-color: ${props =>
    props.active ? ({ theme }) => theme.blogColors.primary : 'white'};
  border: ${rem(1)} solid ${({ theme }) => theme.blogColors.border};
  margin: 0;
  border-radius: 0;

  &:hover {
    background: ${({ theme }) => theme.color.backgroundDark};
    color: ${({ theme }) => theme.color.white};
  }
`;

const PaginationSpacer = styled.span`
  ${PaginationItemStyles};
  width: ${rem(19)};
  padding-right: ${rem(20)};
`;

const DesktopWrapper = styled.div`
  display: none;
  ${({ theme }) => theme.media(500)} {
    display: block;
  }
`;

const MobileWrapper = styled(FlexCol)`
  display: flex;
  ${({ theme }) => theme.media(500)} {
    display: none;
  }
`;

const ArrowsWrapper = styled(FlexRow)`
  margin-top: ${rem(10)};
  justify-content: center;
  align-items: center;
`;

const PaginationPrevIcon = styled.img`
  width: ${rem(24)};
  height: ${rem(24)};
  cursor: pointer;
  margin-right: ${rem(32)};
`;

const PaginationNextIcon = styled.img`
  width: ${rem(24)};
  height: ${rem(24)};
  cursor: pointer;
  margin-left: ${rem(32)};
`;

const PaginationArrowWrapper = styled(Link)``;

class Pagination extends React.Component<PagiantionProps, PaginationState> {
  constructor(props) {
    super(props);

    this.state = {
      totalPages: Math.ceil(props.totalItems / props.limit),
    };
  }

  public componentDidUpdate(prevProps) {
    if (this.props.totalItems !== prevProps.totalItems) {
      this.setState({
        totalPages: this.calcTotalPages(),
      });
    }
  }

  public render() {
    const { totalPages } = this.state;

    const pageNumbers = this.getPaginationButtons();

    return (
      <PaginationWrapper>
        {totalPages > 1 && (
          <React.Fragment>
            <DesktopWrapper>
              {this.countCurrentPage() !== 1 && (
                <PaginationArrowWrapper
                  to={`${this.props.query}&offset=${this.getOffset(
                    this.countCurrentPage() - 1,
                  )}`}
                >
                  <PaginationPrevIcon
                    alt=""
                    // onClick={this.handlePrevButtonClick}
                    src="/images/blog_assets/back-arrow.svg"
                  />
                </PaginationArrowWrapper>
              )}
              {pageNumbers}
              {this.countCurrentPage() !== this.calcTotalPages() && (
                <PaginationArrowWrapper
                  to={`${this.props.query}&offset=${this.getOffset(
                    this.countCurrentPage() + 1,
                  )}`}
                >
                  <PaginationNextIcon
                    alt=""
                    // onClick={this.handleNextButtonClick}
                    src="/images/blog_assets/right-arrow.svg"
                  />
                </PaginationArrowWrapper>
              )}
            </DesktopWrapper>
            <MobileWrapper>
              <ArrowsWrapper>
                {this.countCurrentPage() !== 1 && (
                  <PaginationArrowWrapper
                    to={`${this.props.query}&offset=${this.getOffset(
                      this.countCurrentPage() - 1,
                    )}`}
                  >
                    <PaginationPrevIcon
                      alt=""
                      // onClick={this.handlePrevButtonClick}
                      src="/images/blog_assets/back-arrow.svg"
                    />
                  </PaginationArrowWrapper>
                )}
                <MobilePageSelect
                  value={this.countCurrentPage()}
                  options={this.getNumbersInSelect()}
                  onChange={this.handleSelectChange}
                />
                {this.countCurrentPage() !== this.calcTotalPages() && (
                  <PaginationArrowWrapper
                    to={`${this.props.query}&offset=${this.getOffset(
                      this.countCurrentPage() + 1,
                    )}`}
                  >
                    <PaginationNextIcon
                      alt=""
                      // onClick={this.handleNextButtonClick}
                      src="/images/blog_assets/right-arrow.svg"
                    />
                  </PaginationArrowWrapper>
                )}
              </ArrowsWrapper>
            </MobileWrapper>
          </React.Fragment>
        )}
      </PaginationWrapper>
    );
  }

  public getNumbersInSelect = () => {
    const { totalPages } = this.state;
    const paginationNumbers: number[] = [];
    for (let i = 0; i < totalPages; i++) {
      paginationNumbers.push(i + 1);
    }
    return paginationNumbers;
  };

  public getPaginationButtons = (): JSX.Element[] => {
    const paginationButtons: JSX.Element[] = [];
    const currentPage = this.countCurrentPage();
    const { totalPages } = this.state;

    if (totalPages <= 7) {
      return this.createPageNumbersAll(currentPage);
    }

    if (currentPage > 2) {
      paginationButtons.push(
        <PaginationItem
          to={`${this.props.query}&offset=${this.getOffset(1)}`}
          // onClick={this.handleButtonClick}
        >
          1
        </PaginationItem>,
      );
    }

    if (currentPage > 3) {
      paginationButtons.push(
        <PaginationSpacer onClick={null}>&hellip;</PaginationSpacer>,
      );
    }

    if (currentPage < totalPages) {
      if (currentPage === totalPages) {
        paginationButtons.push(
          <PaginationItem
            to={`${this.props.query}&offset=${this.getOffset(currentPage - 2)}`}
            // onClick={this.handleButtonClick}
          >
            {currentPage - 2}
          </PaginationItem>,
        );
      }

      if (currentPage !== 1) {
        paginationButtons.push(
          <PaginationItem
            to={`${this.props.query}&offset=${this.getOffset(currentPage - 1)}`}
            //  onClick={this.handleButtonClick}
          >
            {currentPage - 1}
          </PaginationItem>,
        );
      }

      paginationButtons.push(
        <PaginationItem
          to={`${this.props.query}&offset=${this.getOffset(currentPage)}`}
          active={true}
          // onClick={this.handleButtonClick}
        >
          {currentPage}
        </PaginationItem>,
      );

      if (currentPage < totalPages - 1) {
        paginationButtons.push(
          <PaginationItem
            to={`${this.props.query}&offset=${this.getOffset(currentPage + 1)}`}
            // onClick={this.handleButtonClick}
          >
            {currentPage + 1}
          </PaginationItem>,
        );
      }

      if (currentPage === 1) {
        paginationButtons.push(
          <PaginationItem
            to={`${this.props.query}&offset=${this.getOffset(currentPage + 2)}`}
            //  onClick={this.handleButtonClick}
          >
            {currentPage + 2}
          </PaginationItem>,
        );
      }
    }

    if (currentPage < totalPages - 2) {
      paginationButtons.push(
        <PaginationSpacer
        // onClick={this.handleButtonClick}
        >
          &hellip;
        </PaginationSpacer>,
      );
    }

    if (currentPage === totalPages) {
      paginationButtons.push(
        <PaginationItem
          to={`${this.props.query}&offset=${this.getOffset(totalPages - 2)}`}
          // onClick={this.handleButtonClick}
        >
          {totalPages - 2}
        </PaginationItem>,
      );
    }

    if (currentPage === totalPages) {
      paginationButtons.push(
        <PaginationItem
          to={`${this.props.query}&offset=${this.getOffset(totalPages - 1)}`}
          // onClick={this.handleButtonClick}
        >
          {totalPages - 1}
        </PaginationItem>,
      );
    }

    paginationButtons.push(
      <PaginationItem
        to={`${this.props.query}&offset=${this.getOffset(totalPages)}`}
        active={currentPage === totalPages}
        // onClick={this.handleButtonClick}
      >
        {totalPages}
      </PaginationItem>,
    );

    return paginationButtons;
  };

  public createPageNumbersAll(currentPage) {
    const { totalPages } = this.state;
    const paginationButtons: JSX.Element[] = [];

    for (let i = 1; i <= totalPages; i++) {
      paginationButtons.push(
        <PaginationItem
          to={`${this.props.query}&offset=${this.getOffset(i)}`}
          active={currentPage === i}
          // onClick={this.handleButtonClick}
        >
          {i}
        </PaginationItem>,
      );
    }

    return paginationButtons;
  }

  public calcTotalPages = () => {
    return Math.ceil(this.props.totalItems / this.props.limit);
  };

  public countCurrentPage = () => {
    return Math.ceil(this.props.offset / this.props.limit + 1);
  };

  public handleSelectChange = e => {
    const offset = prop(e, 'target.dataset.value');
    const diff = parseInt(offset, 10) - this.countCurrentPage();
    this.props.onOffsetChange(
      parseInt(this.props.offset.toString(), 10) + diff * this.props.limit,
    );
  };

  public handleButtonClick = e => {
    const diff = parseInt(e.target.text, 10) - this.countCurrentPage();
    this.props.onOffsetChange(
      parseInt(this.props.offset.toString(), 10) + diff * this.props.limit,
    );
  };

  public getOffset = page => {
    const diff = parseInt(page, 10) - this.countCurrentPage();

    return parseInt(this.props.offset.toString(), 10) + diff * this.props.limit;
  };

  public handleNextButtonClick = () => {
    const currentPage = this.countCurrentPage();
    if (currentPage === this.calcTotalPages()) {
      return;
    }
    this.props.onOffsetChange(
      parseInt(this.props.offset.toString(), 10) + this.props.limit,
    );
  };

  public handlePrevButtonClick = () => {
    const currentPage = this.countCurrentPage();
    if (currentPage === 1) {
      return;
    }

    this.props.onOffsetChange(
      parseInt(this.props.offset.toString(), 10) - this.props.limit,
    );
  };
}

export default Pagination;
