import React from 'react';
import { __ } from 'react-i18n';
import styled from '../../theme/styled-components';
import { rem } from 'polished';
import { connect } from 'react-redux';
// import API, { ThenArg } from '../../services/API';
// import { Loader } from '../_helpers/Loader/Loader';
import { connectSsr } from 'ssr-service';
import { loadSitemap } from '../../containers/App/actions';
import { Loader } from '../_helpers/Loader/Loader';

const Wrapper = styled.div`
  padding: ${rem(46)} ${rem(30)};
  color: #414141;
`;

const Title = styled.h1`
  color: #414141;
  margin-bottom: ${rem(24)};
`;

interface Props {
  content: any;
  lang: string;
}

class Sitemap extends React.Component<Props> {
  public static async getInitialProps(props) {
    const { dispatch, lang } = props;

    await dispatch(loadSitemap(lang));
    return {};
  }

  public createSitemapContent = () => {
    const { content } = this.props;

    return { __html: content || '' };
  };

  public render() {
    return (
      <Wrapper>
        <Title>{__('Mapa stránky')}</Title>
        {this.props.content ? (
          <div dangerouslySetInnerHTML={this.createSitemapContent()} />
        ) : (
          <Loader />
        )}
      </Wrapper>
    );
  }
}

const mapStateToProps = state => ({
  content: state.general.sitemap,
  lang: state.general.lang,
});

export default connect(mapStateToProps)(
  connectSsr({ displayName: 'Sitemap' })(Sitemap),
);
