import * as actionTypes from './constants';
import APIClass from '../../services/API';
import { langSelector } from '../Category/selectors';
import { prop } from 'eshop-defaults';

const requestProductDetail = () => ({
  type: actionTypes.REQUEST_PRODUCT_DETAIL,
});

const receiveProductDetailSuccess = productDetail => ({
  type: actionTypes.RECEIVE_PRODUCT_DETAIL_SUCCESS,
  payload: {
    productDetail,
  },
});

const receiveProductDetailError = error => ({
  type: actionTypes.RECEIVE_PRODUCT_DETAIL_ERROR,
  payload: {
    error,
  },
});

export const loadProductDetail = id => {
  return async (dispatch, getState, API) => {
    try {
      dispatch(requestProductDetail());
      const lang = langSelector(getState());
      const productDetail: any = await APIClass.loadProduct(
        id,
        { withContents: '1' },
        { xAcceptLanguage: lang },
      );
      const content =
        productDetail.publish &&
        productDetail.publish.length &&
        productDetail.publish[0].content
          ? productDetail.publish[0].content
          : null;
      if (!content) {
        throw new Error('Product content not found');
      }

      const webContentConnections = productDetail.web_content_connections
        .map(webContentConnection => {
          const originalWebConnection =
            webContentConnection.data && webContentConnection.data.length
              ? webContentConnection.data[0]
              : null;
          if (originalWebConnection) {
            originalWebConnection.product_id = webContentConnection.product_id;
            originalWebConnection.json_content = JSON.parse(
              originalWebConnection.json_content,
            );
            originalWebConnection.image =
              originalWebConnection.photogallery &&
              originalWebConnection.photogallery.length
                ? 'media/' + originalWebConnection.photogallery[0].document_path
                : null;
          }
          return originalWebConnection;
        })
        .filter(o => o);

      dispatch(
        receiveProductDetailArticlesSuccess(
          webContentConnections,
          webContentConnections.length,
        ),
      );
      dispatch(
        receiveProductDetailSuccess({
          ...content,
          photogallery: productDetail.photogallery,
        }),
      );
    } catch (e) {
      dispatch(receiveProductDetailError(e));
    }
  };
};

const requestProductDetailArticles = () => ({
  type: actionTypes.REQUEST_PRODUCT_DETAIL_ARTICLES,
});

const receiveProductDetailArticlesSuccess = (articles, total) => ({
  type: actionTypes.RECEIVE_PRODUCT_DETAIL_ARTICLES_SUCCESS,
  payload: {
    articles,
    total,
  },
});

const receiveProductDetailArticlesError = error => ({
  type: actionTypes.RECEIVE_PRODUCT_DETAIL_ARTICLES_ERROR,
  payload: {
    error,
  },
});

export const loadProductDetailArticles = (id, offset) => {
  return async (dispatch, getState, API) => {
    try {
      dispatch(requestProductDetailArticles());
      dispatch(setProductDetailArticlesFilters({ offset }));

      const lang = langSelector(getState());
      const productDetailArticles = await APIClass.loadContentConnections(
        id,
        { offset, connectionType: 'READTOO' },
        { xAcceptLanguage: lang },
      );
      const web_content_connections =
        productDetailArticles.web_content_connections;
      const total = prop(productDetailArticles, 'total', 0);
      console.log({ productDetailArticles });

      dispatch(
        receiveProductDetailArticlesSuccess(web_content_connections, total),
      );
    } catch (e) {
      dispatch(receiveProductDetailArticlesError(e));
    }
  };
};

export const setProductDetailArticlesFilters = filters => ({
  type: actionTypes.SET_PRODUCT_DETAIL_ARTICLES_FILTERS,
  payload: {
    filters,
  },
});
