import {
  REQUEST_EBOOKS,
  RECEIVE_EBOOKS_SUCCESS,
  RECEIVE_EBOOKS_ERROR,
  SET_EBOOKS_FILTERS,
} from './constants';
import { langSelector } from '../Category/selectors';

const requestEbooks = () => ({
  type: REQUEST_EBOOKS,
});

const receiveEbooksSuccess = (ebooks, total) => ({
  type: RECEIVE_EBOOKS_SUCCESS,
  payload: {
    ebooks,
    total,
  },
});

const receiveEbooksError = error => ({
  type: RECEIVE_EBOOKS_ERROR,
  payload: {
    error,
  },
});

export const setEbooksFilters = filters => ({
  type: SET_EBOOKS_FILTERS,
  payload: {
    filters,
  },
});

export const loadEbooks = (limit: number = 10, offset: number = 0) => {
  return async (dispatch, getState, API) => {
    try {
      dispatch(requestEbooks());
      dispatch(setEbooksFilters({ offset }));

      const lang = langSelector(getState());
      const ebooks = await API.loadArticles(
        {
          limit,
          offset,
          sitemapUniqueId: 'EBOOKY',
          onlyActual: '1',
          isPublished: '1',
          tagExcludeId: 1518,
          sfForm: 'Article,Ebook',
          sort: 'created_date',
          replaceCurrentLangTags: 1,
        },
        { xAcceptLanguage: lang },
      );
      dispatch(receiveEbooksSuccess(ebooks.articles, ebooks.total));
    } catch (e) {
      dispatch(receiveEbooksError(e));
    }
  };
};
