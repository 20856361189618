import {
  REQUEST_ARTICLES_WITH_BRAND,
  RECEIVE_ARTICLES_WITH_BRAND_SUCCESS,
  RECEIVE_ARTICLES_WITH_BRAND_ERROR,
  SET_ARTICLES_WITH_BRAND_FILTERS,
} from './constants';

const INITIAL_STATE: any = {
  articles: [],
  isFetching: false,
  offset: 0,
  total: 0,
};

export const articlesWithBrandReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case REQUEST_ARTICLES_WITH_BRAND: {
      return {
        ...state,
        isFetching: true,
      };
    }

    case RECEIVE_ARTICLES_WITH_BRAND_SUCCESS: {
      return {
        ...state,
        isFetching: false,
        articles: action.payload.articles,
        total: action.payload.total,
      };
    }

    case RECEIVE_ARTICLES_WITH_BRAND_ERROR: {
      return {
        ...state,
        isFetching: false,
        error: action.payload.error,
      };
    }

    case SET_ARTICLES_WITH_BRAND_FILTERS: {
      return {
        ...state,
        offset: action.payload.filters.offset,
      };
    }

    default: {
      return state;
    }
  }
};
