import React, { useState } from 'react';
import styled from 'styled-components';
import { rem } from 'polished';
import { Icon, FlexRowCenterVertical } from 'eshop-defaults/lib';
import OutsideClick from '../_helpers/Default/OutsideClick';

// Interfaces

interface SelectProps {
  options: number[];
  value: number;
  required?: boolean;
  onBlur?: (e) => void;
  onChange: (e) => void;
}

const Wrapper = styled.div`
  margin-left: auto;
  display: inline-block;
  position: relative;
  border: ${rem(1)} solid ${({ theme }) => theme.blogColors.border};
  padding: ${rem(16)};
`;

const StyledText = styled.p`
  margin: 0;
  color: ${({ theme }) => theme.blogColors.primary};
  font-family: ${({ theme }) => theme.blogFonts.primaryFont};
  font-size: ${rem(16)};
  font-weight: 500;
  text-transform: uppercase;
`;
const SelectValue = styled(StyledText)`
  cursor: pointer;
`;

const StyledOption = styled(FlexRowCenterVertical)`
  cursor: pointer;
  padding: ${rem(16)} ${rem(24)};
  color: ${({ theme }) => theme.blogColors.primary};
  font-family: ${({ theme }) => theme.blogFonts.primaryFont};
  font-size: ${rem(16)};
  font-weight: 500;
  text-transform: uppercase;

  &:hover {
    background-color: #f9f9f9;
  }
`;

const OptionsWrapper = styled.div`
  margin-top: ${rem(16)};
  background: ${({ theme }) => theme.color.white};
  border: ${rem(1)} solid ${({ theme }) => theme.blogColors.border};
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.16);

  position: absolute;
  flex-flow: column;
  display: flex;
  width: 150px;

  font-family: ${({ theme }) => theme.blogFonts.primaryFont};
  font-size: ${rem(16)};
  font-weight: 500;
  text-transform: uppercase;

  background-color: white;
  z-index: 10;

  max-height: ${rem(170)};
  overflow: auto;

  &:focus {
    outline: none;
  }

  -moz-appearance: none;
  -webkit-appearance: none;

  &:hover {
    cursor: pointer;
  }

  ${({ theme }) => theme.mediab.l1620`
  left: 0;
  `}
`;

const StyledIcon = styled(Icon)`
  width: ${rem(8)};
  height: ${rem(16)};
  margin-left: ${rem(5)};
`;

const MobilePageSelect: React.SFC<SelectProps> = ({
  options,
  value,
  onChange,
}) => {
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);

  const currentOptionName = options.filter(o => o === value)[0];

  const handleChange = e => {
    setIsDropdownOpen(false);
    onChange(e);
  };

  return (
    // tslint:disable-next-line:jsx-no-lambda
    <OutsideClick handleFunction={() => setIsDropdownOpen(false)}>
      <Wrapper>
        {/* tslint:disable-next-line:jsx-no-lambda */}
        <SelectValue onClick={() => setIsDropdownOpen(!isDropdownOpen)}>
          {currentOptionName}{' '}
          <StyledIcon
            src={
              isDropdownOpen
                ? '/images/blog_assets/arrow_top.svg'
                : '/images/arrow_triangle.svg'
            }
          />
        </SelectValue>
        {isDropdownOpen && (
          <OptionsWrapper>
            {options &&
              options.map(option => (
                <StyledOption
                  onClick={handleChange}
                  key={option}
                  data-value={option}
                >
                  {option}
                </StyledOption>
              ))}
          </OptionsWrapper>
        )}
      </Wrapper>
    </OutsideClick>
  );
};

export default MobilePageSelect;
