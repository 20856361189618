import React from 'react';
import { connect } from 'react-redux';
import { __ } from 'react-i18n';
import styled from '../../theme/styled-components';
import { rem } from 'polished';
import { FlexCol } from 'eshop-defaults';
import { getRssFeedUrl } from '../../utilities/article';
import {
  topMenuItemsSelector,
  topMenuItemsIsFetchingSelector,
  langSelector,
} from '../../containers/App/selectors';
// import API, { ThenArg } from '../../services/API';
import { Loader } from '../_helpers/Loader/Loader';
import MetaTags from '../MetaTags/MetaTags';

const Wrapper = styled.div`
  padding: ${rem(46)} ${rem(30)};
`;

const Title = styled.h2`
  font-size: ${rem(22)};
  color: ${({ theme }) => theme.blogColors.primary};

  margin: 0;
  margin-bottom: ${rem(36)};
`;

const FeedsList = styled(FlexCol)``;

const Feed = styled.a`
  width: fit-content;
  text-decoration: none;
  color: ${({ theme }) => theme.blogColors.link};
  margin-bottom: ${rem(8)};
  margin-left: ${({ marginLeft }) => rem(marginLeft)};

  &:hover {
    text-decoration: underline;
  }
`;

const FeedWrapper = styled(FlexCol)``;

interface Props {
  // topMenuItems: ThenArg<typeof API.loadSitemapSubtree>['sitemap_tree'];
  topMenuItems: any;
  isFetching: boolean;
  lang: string;
}

class RSS extends React.Component<Props> {
  public renderItemChildren = (item, lvl) => {
    const { lang } = this.props;
    return item.children.map(child => (
      <>
        <Feed
          marginLeft={16 * lvl}
          key={child.id}
          href={getRssFeedUrl(child.id, lang)}
        >
          {child.name}
        </Feed>
        {child.children &&
          child.children.length > 0 &&
          this.renderItemChildren(child, lvl + 1)}
      </>
    ));
  };

  public render() {
    const { topMenuItems, isFetching, lang } = this.props;

    return (
      <Wrapper>
        <MetaTags tags={{ title: 'RSS' }} />
        <Title>{__('Dostupné RSS feedy')}</Title>
        {isFetching ? (
          <Loader />
        ) : (
          <FeedsList>
            <Feed href={getRssFeedUrl(0, lang)}>{__('Všetky články')}</Feed>
            {topMenuItems &&
              topMenuItems.map(item => (
                <FeedWrapper>
                  <Feed key={item.id} href={getRssFeedUrl(item.id, lang)}>
                    {item.name}
                  </Feed>
                  {this.renderItemChildren(item, 1)}
                </FeedWrapper>
              ))}
          </FeedsList>
        )}
      </Wrapper>
    );
  }
}

const mapStateToProps = state => {
  return {
    topMenuItems: topMenuItemsSelector(state),
    isFetching: topMenuItemsIsFetchingSelector(state),
    lang: langSelector(state),
  };
};

export default connect(mapStateToProps)(RSS);
